@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
/* =============== Globals ============== */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.4;
    color: #4e4851;
    background: #f2f2f2;
}
.form-control:disabled {
    background-color:#e9e9e9  !important;
    opacity: 1;
    color: #4e4851 !important;
}
.form-check-input:checked {
    background-color: #070044;
    border-color: #070044;
}
/* typography css */
ul {
    padding-left: 0;
}
.form-check-input:focus,
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: none;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-5 {
    flex: 0 0 50%;
}

.flex-6 {
    flex: 6;
}

p {
    margin-bottom: 0;
}
.text-left{
    text-align: left !important;
}
.btn-block {
    width: 100%;
}
.box-shadow-none {
    box-shadow: none !important;
}
a {
    text-decoration: none;
    color: #4e4851;
}

a:hover {
    color: inherit;
}

.lead-text {
    font-size: 14px;
}

.small-text {
    font-size: 12px;
}

h3 {
    font-size: 16px;
    font-weight: 700;
}

.white-bg {
    background-color: #fff;
}

.purple-text {
    color: #070044;
}

.gray-text {
    color: #4e4851;
}
.black-text{
    color: #000;
}
.white-text {
    color: #ffffff;
}
.success-text{
    color: #2EC077 ;
}
.no-wrap{
    white-space: nowrap;
}
.font-light{
    font-weight: 400;
}
.bold {
    font-weight: 600;
}

.bolder {
    font-weight: 700;
}
.uppercase{
    text-transform: uppercase;
}
hr.dotted {
    border-top: 1px dotted #000;
}

.container {
    position: relative;
    width: 100%;
}

.white-background {
    background-color: #ffffff;
}

.gray-background {
    background-color: #4e4851;
}

.purple-background {
    background-color: #070044;
}

.red-background {
    background-color: #e93b59;
}

.h2-style h2 {
    padding-bottom: 5px;
    position: relative;
}
.border-radius-6{
    border-radius: 6px;
}
.h2-style h2:before {
    width: 28px;
    height: 5px;
    display: block;
    content: '';
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #070044;
}

.h2-style h2:after {
    width: 100px;
    height: 1px;
    display: block;
    content: '';
    position: relative;
    margin-top: 15px;
    left: 50%;
    margin-left: -50px;
    background-color: #070044;
}

.site-button {
    padding: 10px 50px;
    display: block;
    font-weight: 500;
    text-decoration: none;
    transition: 0.5s;
    font-size: 20px;
    border-radius: 6px;
    border: 0;
    transform: translateY(0px);
    transition: all 0.1s ease-in-out;
}
.text-capitalize{
    text-transform: capitalize;
}
.btn {
    font-size: 13px;
    padding: 6px 10px;
    border-radius: 6px;
}
.btn-sm{
    font-size: 12px;
    padding: 4px 8px;
}
.btn-xs{
    font-size: 12px;
    padding: 3px 7px;
}
.btn-gray{
    background: #E7E7E7;
    border: 1px solid #C1C1C1;
    color: #000000;
    font-weight: 600;
}
.btn-gray:hover{
    background: #070044;
    border: 1px solid #070044;
    color: #ffffff;
}
.btn:hover{
    transform: translateY(-2px);
    transition: all 0.2s ease-in-out;
}
.btn:focus {
    box-shadow: none;
}

.btn-primary {
    background-color: #070044 !important;
    border-color: #070044 !important;
    position: relative;
    transform: translateY(0px);
    transition: all 0.1s ease-in-out;
}
.btn-white{
    color: #4E4851;
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding: 4px 7px;
    white-space: nowrap;
    background-color: #fff;
}
.btn-white:hover{
    background-color: #070044 !important;
    border-color: #070044 !important;
    color: #fff !important;
}
.btn-secondary {
    background-color: #4e4851 !important;
    border-color: #4e4851 !important;
    transform: translateY(0px);
    transition: all 0.2s ease-in-out;
}
/* .btn-secondary:hover,
.btn-secondary.active {
    background-color: #fff !important;
    border-color: #4e4851 !important;
    color: #4e4851 !important;
}
.btn-delete:hover {
    color: #e93b59;
    border: 1px solid #e93b59;
    background: transparent;
} */
.btn-delete {
    color: #fff !important;
    border: 1px solid #e93b59 !important;
    background: #e93b59 !important;
}
.btn-delete:hover {
    color: #fff !important;
    border: 1px solid #e93b59 !important;
    background: #e93b59 !important;
}
.btn-icon {
    font-size: 11px;
    width: 24px;
    height: 23px;
    border-radius: 6px;
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 4px 3px;
    cursor: pointer;
    border: 0;
    line-height: 15px;
}
.btn-icon-md {
    padding: 7px 8px;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 14px;
}
.btn-icon:hover {
    color: #fff;
}
.form-control:focus {
    border-color: #b5b5b5;
    outline: 0;
    box-shadow: none;
}
.nowrap{
    white-space: nowrap;
}
.text-right{
    text-align: right !important;
}
.danger-text{
    color: #e93b59;
}
.w-200{
    width: 200px;
}
.w-300{
    width: 400px !important;
}
/* =============== Sidebar start ================ */
.navigation .logo-icon {
    display: none;
}

.navigation.show .logo-icon {
    display: block;
}

.navigation.show .logo {
    display: none;
}

img.logo {
    width: 145px;
    margin: 15px 0 31px 0px;
}

img.logo-icon {
    width: 30px;
    margin: 7px 0 31px 10px;
}
/*
.toggle svg {
    color: #070044;
} */

.nav-main-title {
    padding: 0 13px;
    margin: 10px 0 8px 0;
    color: #e8d6ef;
}

.navigation.show .nav-main-title {
    display: none;
}

.navigation.show .logo a {
    display: none;
}

/* .navigation.show .toggle {
    margin-left: 0;
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    height: 38px;
} */

.navigation {
    position: fixed;
    width: 225px;
    height: 100%;
    background: #070044;
    transition: 0.3s;
    overflow: hidden;
}

.navigation.show {
    width: 50px;
}
.navigation ul li a.active {
    background-color: #f2f2f2;
}
.navigation ul li i {
    color: #ffffff;
}

.navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: white;
}
/* for apple */
.navigation a {
    text-align: center;
    width: 100%;
}
/* for apple */

.navigation ul li.active a {
    color: #000;
}

.navigation ul li a .icon {
    position: relative;
    display: flex;
    min-width: 50px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    z-index: 9;
}
.navigation.show ul li a .icon
{
    height: 38px;
}

.navigation ul li a .title {
    position: relative;
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: start;
    white-space: nowrap;
    font-size: 14px;
    color: #fff;
}
.navigation.show ul li a .title
{
    display: none;
}
.navigation ul li a.active .title{
    color: #4e4851;
}
.navigation ul li a.active i {
    color: #4e4851;
}
.navigation ul li svg,.navigation ul li svg path {
    fill: #fff;
}
.navigation ul li a.active svg,.navigation ul li a.active svg path {
    fill: #4e4851;
}
/* --------- curve outside ---------- */
.navigation ul li a.active::before {
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px #f2f2f2;
    pointer-events: none;
}

.navigation ul li a.active::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px #f2f2f2;
    pointer-events: none;
}

/* ====================Sidebar end========================= */

/* ====================Topbar========================= */
.topbar .dropdown-toggle::after{
    display: none;
}
.topbar .nav-link{
    padding: 0;
}
.topbar .user_img{
    background-color: #4e4851;
    width: 30px;
    height: 30px;

}
.top_toggle.show {
    width: calc(100% - 225px);
    left: 225px;
}
.topbar .user .dropdown-item {
    font-size: 14px;
}
.topbar.show {
    width: calc(100% - 50px);
    left: 50px;
}

.topbar {
    width: calc(100% - 225px);
    left: 225px;
    box-shadow: 5px -1px 5px #6b6a6c;
    background: #fff;
    transition: 0.3s;
    position: fixed;
    top: 0;
    z-index: 99;
    padding: 6px 10px;
}

.toggle-bar {
    font-size: 15px;
    cursor: pointer;
}

.search {
    position: relative;
    width: 320px;
    margin: 0 10px;
}

.search label {
    position: relative;
    width: 100%;
}

.search label input {
    width: 100%;
    height: 27px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 30px;
    outline: none;
    border: 0;
    background: #f9f9f9;
    box-shadow: 0 0 1px 2px #e5e5e5;
    font-size: 13px;
}

.search label svg {
    position: absolute;
    font-size: 13px;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: #cdc5c5;
}

.user {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 3px;
}

.user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.top-icons i {
    color: #4e4851;
    padding: 0 6px;
}

.icon-button__badge {
    position: absolute;
    top: -5px;
    right: 0;
    width: 12px;
    height: 12px;
    background: #070044;
    color: #ffffff;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}


/* ===================== Topbar end ===================== */

/* ===================== Main ===================== */
.CircularProgressbar.gray .CircularProgressbar-path {
    stroke: #4e4851;
}
.CircularProgressbar.purple .CircularProgressbar-path {
    stroke: #070044;
}
.CircularProgressbar.small {
    width: 35px;
}
.CircularProgressbar.medium {
    width: 50px;
}
.CircularProgressbar.large {
    width: 67px;
}
.main {
    position: absolute;
    width: calc(100% - 225px);
    left: 225px;
    transition: 0.3s;
    top: 42px;
    padding: 10px;
    /* height: calc(100vh - 42px); */
}

.main.show {
    width: calc(100% - 400px);
    left: 50px;
}

.main.show.toggle{
    width: calc(100% - 50px);
    left: 50px;
}
.main.show.toggle .accordion-custom .tab-content {
    flex: 7 1;
}
.card {
    /* box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.17); */
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    border: 0;
}

.card-white {
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 18px;
}

.count_up h3 {
    font-size: 18px;
    font-weight: 700;
    color: #4e4851;
}
.more-info .count_up h3 {
    position: relative;
}
.card-layout-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 13px;
}

.accordion-custom {
    border-radius: 6px;
    box-shadow: 0 -4px 3px -3px #e1dddd;

    /* padding-left: 5px; */
}

.accordion-custom .tab-content .count_up.active{
    border-color:#070044 ;
}
.accordion-custom .tab-content .count_up.active svg{
    fill: #070044;
}
.accordion-custom .tab-content .count_up.active i{
    color: #070044;
}
/* .accordion-custom .tab-content .count_up.active h3,
.accordion-custom .tab-content .count_up.active i{
    color: #fff;
}
.accordion-custom .tab-content .count_up.active svg{
    fill: #ffffff;
} */
.main.show .accordion-custom select.form-control,
.main.show .accordion-custom input{
    width: 125px;
}
.accordion-custom .nav-pills {
    flex: 1;
    position: relative;
    box-shadow: 0px 3px 3px -3px #bfb8b8;
    border-radius: 6px 0 0 6px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 218px);
}

.accordion-custom .tab-content {
    flex: 6 1;
    align-self: stretch;
}

.accordion-custom .nav-pills p {
    font-size: 14px;
    font-weight: 700;
}

.accordion-button:not(.collapsed) {
    color: inherit !important;
}

select.form-control {
    /* Arrow */
    appearance: none;
    background-image: url(../assets/images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: 16px;
    padding-right: 21px;
}

.accordion-custom select.form-control {
    background-color: transparent;
    border: 0;
    width: 150px;
    font-size: 13px;
    height: 29px;
    padding: 3px 7px;

    border: 1px solid #ced4da;
}

.accordion-custom input {
    width: 160px;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 13px;
}
.accordion-custom .pagination-list{
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.accordion-custom .tab-pane {
    padding: 14px 10px 10px 10px;
    border-radius: 0 6px 6px 0px;
    height: 100%;
}

.accordion-custom .nav-pills .nav-link.active:before {
    content: '';
    position: absolute;
    right: 0px;
    top: -50px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 30%;
    box-shadow: 21px 22px 0 10px #ffffff;
    z-index: -1;
    pointer-events: none;
}

.accordion-custom .nav-pills .nav-link.active:after {
    content: '';
    position: absolute;
    right: -1px;
    bottom: -50px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 30%;
    box-shadow: 28px -39px 0 10px #ffffff;
    z-index: -1;
    pointer-events: none;
}

.accordion-custom .nav-pills .nav-link {
    background: #ebebeb;
    border-bottom: 1px solid #cbcbcb;
    border-radius: 6px 0 0 6px;
    box-shadow: -3px 4px 5px -1px #cdc9c9;
    box-shadow: inset 1px 1px 7px -2px rgba(0, 0, 0, .25);
    color: #000;
    min-height: 60px;
    padding: 7px 12px;
    text-align: left;
    transition: none !important;
    width: 100%;
}
.accordion-custom .nav-pills .nav-link.active {
    border-bottom: 0;
}
.accordion-custom .nav-pills .nav-link.active.occupied-ward {
    box-shadow: inset 4px 0 2px -2px #f44336;
}
.accordion-custom .nav-pills .nav-link.active.vacant-ward {
    box-shadow: inset 4px 0 2px -2px #009688;
}
.accordion-custom .nav-pills .nav-link.occupied-ward {
    box-shadow: inset 4px 0 2px -2px #f44336;
}
.accordion-custom .nav-pills .nav-link.vacant-ward {
    box-shadow: inset 4px 0 2px -2px #009688;
}
.accordion-custom .nav-pills .nav-link.active:last-child:after {
    display: none;
}
.accordion-custom .nav-pills .nav-link#wardA.active:before {
    display: none;
}

.accordion-custom .nav-pills .nav-link.active-before {
    border-bottom-right-radius: 16px;
    padding-bottom: 31px;
}

/* .accordion-custom .nav-pills .nav-link#A {
    z-index: 9;
    padding-top: 8px;
}

.accordion-custom .nav-pills .nav-link#B {
    z-index: 8;
    margin-top: -19px;
}

.accordion-custom .nav-pills .nav-link#C {
    z-index: 7;
    margin-top: -19px;
}

.accordion-custom .nav-pills .nav-link#D {
    z-index: 6;
    margin-top: -19px;
} */

/* .accordion-custom .nav-pills .nav-link#A,
.accordion-custom .tab-pane#wardATab {
    background-color: #ebebeb;
}

.accordion-custom .nav-pills .nav-link#B,
.accordion-custom .tab-pane#wardBTab {
    background-color: #ebebeb;
}

.accordion-custom .nav-pills .nav-link#C,
.accordion-custom .tab-pane#wardCTab {
    background-color: #ebebeb;
}

.accordion-custom .nav-pills .nav-link#D,
.accordion-custom .tab-pane#wardDTab {
    background-color: #ebebeb;
} */

.nav-link.active .tab_pills_detail {
    display: none !important;
}

.accordion-custom .nav-pills .nav-link.active,
.accordion-custom .tab-pane.active {
    background-color: #ffffff !important;
    position: relative;
}

.accordion-custom .nav-pills .nav-link.active {
    box-shadow: inset 2px 0 5px -2px rgb(0 0 0 / 25%);
    padding-top: 0;
    z-index: 9 !important;
}

.purple_box:hover {
    background-color: #e3caed;
}

.purple_box:hover .user_img {
    background-color: #ffffff;
    color: #ddd;
}

.orange_box:hover {
    background-color: #ffefe6;
}

.orange_box:hover .user_img {
    background-color: #ffffff;
    color: #ddd;
}

.purple_box .card_header {
    background-color: #070044;
}

.orange_box .card_header {
    background-color: #ff9c69;
}

.purple_box svg {
    color: #070044;
    font-size: 18px;
}

.orange_box svg {
    color: #ff9c69;
    font-size: 18px;
}

.user_img {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #9a95bb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.small_box {
    border-radius: 6px;
    max-width: 164px;
    width: 100%;
    margin-bottom: 7px;
    background: #fff;
    min-height: 118px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    border:1px solid #f2f2f2;
    
}
.small_box:hover {
    transform: scale(1.05);
    transition: all 0.1s ease-in-out;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.17);
}
.small_box.active {
    border: 1px solid #d2b4dd;
}
.small_box.active .card_header {
    background: #e3d2e9;
    box-shadow: 0px 1px 4px 0px #efe3f3;
}
.vacant_room {
    width: 86%;
    text-align: center;
    background: #efefef;
    margin: 0 auto;
    border: 1px dashed #b5b0b0;
    margin-top: 15px;
    padding: 10px;
    border-radius: 6px;
}
.large-icon svg {
    width: 34px;
    font-size: 20px;
}

.card.occupied .card_header i {
    font-size: 8px;
    color: #df0404;
    border: 1px solid #4e4851;
    border-radius: 50%;
    margin-bottom: 1px;
}

.card.reserved .card_header i {
    font-size: 8px;
    color: #e5d01b;
    border: 1px solid #4e4851;
    border-radius: 50%;
    margin-bottom: 1px;
}

.card.vacant .card_header i {
    font-size: 8px;
    color: #4caf50;
    border: 1px solid #4e4851;
    border-radius: 50%;
    margin-bottom: 1px;
}

.small_box svg {
    font-size: 16px;
}

.tab_pills_detail svg {
    color: #000;
    font-size: 18px;
}

.small_box .card_header {
    background: #f3f3f3;
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 1px 2px 0px #d3d3d3;
    padding: 4px 4px !important;
}
.small_box .card_header > p{
    font-weight: 600;
}
.accordion-custom .tab-content {
    border-radius: 0 6px 6px 6px;
    box-shadow: 0px 1px 3px 2px #ebe6e6;
    height: calc(100vh - 55px - 48px );
    overflow: auto;
    background-color: #fff;
    /* position: sticky;
    top: 0; */
}
.accordion-custom.tab-horizontal .tab-content{
    height: calc(100vh - 156px);
}
.accordion-custom .tab_pills_detail .count_up h3 {
    font-size: 15px;
    font-weight: 500;
    color: #4e4851;
    margin-bottom: 0;
}
.accordion-item {
    color: #4e4851;
}
.small_card_group {
    z-index: 9;
    position: relative;
    margin-bottom: 0 !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, 160px);
    grid-gap: 1rem;
    justify-content: space-between;
    align-content: flex-start;
}

.middle_content.show {
    width: calc(100% - 265px);
}

.middle_content.show .accordion-custom input {
    width: 165px;
}

.middle_content.show .accordion-custom select.form-control {
    width: 129px;
}

.accordion-item {
    background-color: transparent;
    border: 0;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.collapsible-panel {
    font-size: 13px;
    box-shadow: 0 0 2px 1px #ddd;
    -webkit-box-shadow: 0 0 2px 1px #ddd;
    /* -webkit-appearance: none; */
    border-radius: 6px !important;
    padding: 0;
    background-color: #fff;
}
.info-group .count_up:last-child{
    margin-right: 0;
}
.info-group .count_up,
.prev {
    border: 1px solid #e1e1e1;
    padding: 3px;
    position: relative;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 14px;
}
.table-header-icon-box {
    height: 28px !important;
    width: 28px !important;
    margin-bottom: 14px !important;
    margin-right: 15px !important;
}
.bed-header-icon-mb-0
{
    margin-bottom: 0 !important;
}
.bed-header-icon-me-0
{
    margin-right: 0 !important;
}
.bed-form-control
{
        width: 110px !important;
}
.header-icon-size i{
    font-size: 14px !important;
}
.table-header-icon-box .count
{
    bottom: 18px !important;
    min-height: 21px !important;
    min-width: 21px !important;
}
.count-graph
{
    margin-right: .5rem !important;
}
.info-group .count_up svg,
.info-group .count_up i {
    font-size: 16px;
    color: #4e4851;
    fill: #4e4851;
}
.count_up p.count,
.prev p.count {
    aspect-ratio: 1;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    bottom: 25px;
    color: #070044;
    display: grid;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    min-height: 22px;
    min-width: 22px;
    padding: 2px;
    place-items: center;
    position: absolute;
    right: -14px;
}

.info-group {
    display: flex;
    align-items: center;
}

.pending-list .count_up {
    cursor: pointer;
    padding: 8px !important;
    height: 61px;
}
/* .main.show  .pending-list .count_up{
    margin-bottom: 10px;
} */
.pending-list li:last-child{
    margin-right: 0 !important;
}
.pending-list .count_up:not(.purple-btn) svg,
.pending-list .count_up:not(.purple-btn) i {
    position: absolute;
    right: 8px;
    top: 0;
}
.pending-list .count_up:hover {
    background-color: #ebebeb;
}
.collapsible-panel-right .info-group {
    flex-wrap: wrap;
}

.column-count-custom {
    column-count: 2;
}

.column-count-custom p {
    color: #4e4851;
}

.info-group .count_up,
.prev {
    -webkit-transition: all 0.3s;
    -webkit-transform-origin: 50% 100%;
    transition: all 0.3s;
    transform-origin: 50% 100%;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
}

.info-group .count_up:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.info-group .count_up:hover + .count_up,
.info-group .prev {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.collapsible-panel-left {
    padding: 14px 25px 14px 14px;
    flex: 0 0 50%;
    padding-bottom: 8px;
}

.collapsible-panel-right {
    padding: 14px 8px 14px 12px;
    border-left: 1px solid #cfcfcf;
    width: 100%;
    padding-bottom: 8px;
}

.middle_content.show .collapsible-panel-left {
    flex: 0 0 57%;
}

.middle_content.show .info-group {
    margin-right: 15px;
    column-gap: 16px;
}

.middle_content.show .info-group .count_up {
    width: 30px;
    height: 30px;
}

.middle_content.show .info-group .count_up svg {
    font-size: 15px;
    width: 25px;
}

.middle_content.show .info-group .admission-icon {
    width: 8px !important;
}

.middle_content.show .collapsible-panel-right,
.middle_content.show .collapsible-panel-left {
    padding: 7px 11px;
}

.see-more {
    position: absolute;
    width: 104px;
    background: #4e4851;
    padding: 2px;
    border-radius: 33px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px;
    z-index: 9;
}

.collapsible-panel h3 {
    font-size: 13px;
    font-weight: 700;
}

.upicon:after {
    content: '\f102';
    font-family: FontAwesome;
}

.downicon:after {
    content: '\f103';
    font-family: FontAwesome;
}

.tabular-data p {
    width: 120px;
    line-height: 1.3;
    font-weight: 500;
}
.new-tabular-data {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}
.tabular-data label {
    line-height: 1.3;
}
.tabular-data {
    display: flex;
}

#moreless {
    cursor: pointer;
}

.count_up.purple-btn {
    border: 1px solid #070044;
}

.count_up.purple-btn i {
    color: #070044;
}
.info-group .count_up {
    cursor: pointer;
}

/* ========================Main End=================== */

/* ====================Right Sidebar===================== */
.right_content {
    top: -25px;
    position: relative;
    box-shadow: 0 2px 3px 1px #d3cfcf;
    padding: 10px;
    height: calc(100vh - 42px);
    width: 350px;
    position: fixed;
    background: #fff;
    top: 43px;
    right: -350px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9;
}
.right_content .pagination-list {
    left: 0;
    padding: 12px;
    box-shadow: -2px -1px 4px 2px #d5d5d5;
    width: 100%;
    z-index: 99;
    background: #fff;
    padding: 10px;
}
.right_content .pagination-list ul {
    margin-bottom: 0;
}
/* .main.show .right_content.show {
  width: 320px;
} */
.main.show .middle_content.show {
    width: calc(100% - 350px);
}

.right_content.show {
    right: 0;
}

.middle_content,
.right_content {
    transition: all 0.2s ease-in-out;
}

.close_right_content {
    position: absolute;
    right: 12px;
    font-size: 15px;
    background: #070044;
    color: #fff !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}
.add_left_content {
    font-size: 15px;
    background: #070044;
    color: #fff !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}
.add_left_content i{
    font-size: 13px;
}
.close_right_content i{
    font-size: 13px;
    line-height: 20px;
}
/* ===================assign patient rightbar start ================= */
.right_content .accordion-item {
    background: #ebebeb;
    padding: 8px;
    border-radius: 6px !important;
    margin-bottom: 13px;
    /* box-shadow: 0 0 2px 1px #d3cece; */
}
.accordion-list .accordion-item {
    background: transparent;
    border: 1px solid #ddd !important;
    padding: 8px;
    border-radius: 6px !important;
    margin-bottom: 13px;
}

.accordion-list .accordion-button::after {
    background-size: 13px;
    background-position: 100% 50%;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23070044'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
select {
    color: #707880 !important;
}

.right_content .accordion-item .accordion-button , .accordion-list .accordion-item .accordion-button  {
    background-color: transparent;
    box-shadow: none;
    font-weight: 600;
    color: #070044 !important;
    border-radius: 6px !important;
    padding: 0;
    font-size: 13px;
}
.right_content .accordion-body {
    padding: 10px 0;
}

.right_content .accordion-button::after {
    background-size: 100%;
    background-position: 100%;
    width: 14px;
}
input[type='radio'] {
    accent-color: #070044;
}
input[type='checkbox'] {
    accent-color: #070044;
}
.form-control.white {
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    color: #4e4851;
    box-shadow: 0 0 0px 1px #efefef;
    border: 1px solid #ced4da;
    /* height: 33px; */
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #b51700;
    box-shadow: none !important;
}
.form-control.is-invalid{
    border-color: #b51700;
}
.form-control.is-invalid.custom-width-input,
.form-control.is-invalid.small{
background-image: none !important;
}
.datepicker-input-css .MuiInputBase-input{
    height: 33.5px;
    padding: 0 10px !important;
    font-size: 13px;
    color: #4e4851;
   animation: none !important;
}
.datepicker-input-css .form-control.is-invalid{
    padding-right: 0;
    background-position: right 32px bottom 50%;

}
/* .MuiYearCalendar-root{
    width: 300px !important;
    padding-top: 9px !important;
} */
.MuiYearCalendar-root{
width: unset !important;
padding-top: 9px !important;
justify-content: center;
}

.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected {
    background-color: #070044 !important;
}
.MuiPickersYear-root {
    -webkit-flex-basis: 23% !important;
    -ms-flex-preferred-size: 23% !important;
    flex-basis: 23% !important;
}
.MuiTypography-caption.MuiDayCalendar-weekDayLabe{
    width: 33px;
}
.MuiPickersLayout-root  .MuiPickersYear-yearButton {
    font-size: 13px ;
    margin: 3px 0 ;
    height: 30px ;
    width: 59px ;
}
.MuiPickersLayout-root .MuiPickersDay-root{
    height: 33px;
    width: 33px ;
}
/* .datepicker-input-css .form-control.is-invalid .MuiInputBase-adornedEnd{
    border: 1px solid #dc3545;
} */
.MuiPickersLayout-root  .MuiPickersCalendarHeader-labelContainer {
    font-size: 14px ;
    color: #070044 ;
}
.MuiPickersLayout-root .MuiPickersCalendarHeader-root {
    margin-top: 8px;
    padding-left: 15px;
}
.datepicker-input-css .MuiSvgIcon-fontSizeMedium{
    font-size: 18px;
}
.MuiPickersLayout-root  .MuiPickersDay-today:not(.Mui-selected) {
    border: 1px solid rgb(124 76 142) ;
    color: #070044 ;
    line-height: 2 ;
    background-color: #fff ;
    height: 33px;
    width: 33px;
}
.MuiDateCalendar-root {
    width: 273px !important;
    /* max-height: 270px !important; */
    max-height: 290px !important;
}
.MuiPickersLayout-actionBar .MuiButtonBase-root {
    background: #ffffff;
    color: #070044;
    font-size: 12px;
    padding: 1px 6px;
    font-weight: 600;
    min-width: auto;
    border: 0;
    text-transform: capitalize;
}
.MuiPickersLayout-actionBar .MuiButtonBase-root:first-child {
    border: 0;
    color: #4e4851;
    font-weight: 600;
    font-size: 12px;
    margin-right: auto;
}
.MuiPickersMonth-root{
    flex-basis: 21% !important;
}
.MuiPickersMonth-monthButton{
    font-size: 13px !important;
}
.MuiMonthCalendar-root
{
    width: 100% !important;
    align-items: center;
    justify-content: center;
}
.MuiPickersMonth-monthButton.Mui-selected{
    background-color: #070044 !important;
    width: 50px !important;
    height: 29px !important;
}
.MuiPickersMonth-root
{
    margin: 0 2px;
}
.MuiPickersMonth-monthButton{
    width: 50px !important;
    height: 29px !important;
}
.MuiPickersMonth-monthButton:focus,
.MuiPickersMonth-monthButton.Mui-selected:hover{
    background-color: #070044 !important;
    color: #fff !important;
}
.datepicker-input-css .MuiInputLabel-shrink {
    display: none;
}
.datepicker-input-css .MuiInputLabel-root{
    font-size: 13px;
    top: -9px;
    font-family: "Roboto", sans-serif;
}

.datepicker-input-css .MuiOutlinedInput-notchedOutline legend{
    display: none !important;
}
.datepicker-input-css .MuiOutlinedInput-notchedOutline{
    border: 0 !important;
}
.datepicker-input-css .MuiInputBase-adornedEnd{
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.4;
    color: #4e4851;
}
/* New Date Picker CSS */
.MuiDateCalendar-root {
    width: 240px !important;
    /* max-height: 270px !important; */
    max-height: 250px !important;
    padding:5px;
}
.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button
{
    padding: 0 !important;
}
.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button
{
    padding: 0 !important;
}
.css-i5q14k-MuiDayCalendar-header {
    justify-content: space-around !important;
}
.css-flbe84-MuiDayCalendar-weekContainer {
    justify-content: space-around !important;
}
.MuiPickersLayout-root .MuiPickersDay-root {
    height: 25px;
    width: 25px;
}
.MuiPickersLayout-root .MuiPickersDay-today:not(.Mui-selected) {
    height: 25px;
    width: 25px;
}
.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton
{
    padding: 0 !important;
}
/* .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    min-height: 130px !important;
} */
/* .datepicker-input-css input {
    color: #4e4851;
    height: 33px;
    box-shadow: 0px 1px 2px #dcdcdc;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    width: 100%;
    border: 1px solid #ced4da !important;
    outline: none !important;
    padding: 0.375rem 0.75rem;
} */
.cus_active a {
    color: #fff !important;
    background-color: #070044 !important;
}
/* .datepicker-input-css input {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%234e4851" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    background-repeat: no-repeat;
    background-position: 98% 52%;
}  */
input[type="date"]::before {
     color: #707880;
     content: attr(placeholder) ;
     background: #fff;
     position: absolute;
     z-index: 9999;
     left: 10px;
     width: 65%;
}
input[type="date"]::after {
    color: #707880;
    content: attr(value) ;
    background: #fff;
    position: absolute;
    z-index: 9999;
    left: 10px;
    width: 65%;
}
input[type="date"]:focus::before {
    content: "" !important;
}
.input-group-append {
    box-shadow: 0px 1px 2px #dcdcdc;
}

.right_content_btn {
    background: #fff;
    bottom: 0;
    box-shadow: 2px 1px 4px 2px #d5d5d5;
    left: auto;
    margin: 0 auto;
    padding: 7px 24px;
    position: fixed;
    right: 0;
    width: 350px;
    transition: all 0.2s ease-in-out;
    z-index: 9;
}
.my-tooltip {
    background-color: #4e4851 !important;
    padding: 3px 5px 5px 5px !important;
    font-size: 11px !important;
    z-index: 9999;
    width: auto !important;
}
.tooltip-custom-width{
    max-width: 200px !important;
}
.tooltip-custom-width1{
    max-width: 500px !important;
}
.main.show .accordion-custom .tab-content {
    flex: 5 1;
}
.right_content-height {
    padding-bottom: 0;
    height: 69vh;
    overflow: auto;
}
.right_content-height-tab .tab-custom-height {
    padding-bottom: 41px;
    height: calc(100vh - 180px);
    overflow: auto;
    padding-left: 1px;
    padding-right: 1px;
}
.accordion-custom .nav-pills {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.accordion-custom .nav-pills::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
/* .form-check-input:checked[type=radio] + label {
  color: #070044;
  font-weight: 600;
}
.form-check-input:checked[type=radio] + label svg{
  fill: #070044;
}
.form-check-input:checked {
  background-color: #070044;
  border-color: #070044;
}
.form-check-input:focus {
  border-color: #070044;
  outline: 0;
  box-shadow: none;
}
.form-check-input:active {
  filter: none;
}
.form-check-input:not(:checked){
  border-color: #ccc;
}
.form-switch .form-check-input:not(:checked):focus {
 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
} */
.filter{
    flex-wrap: wrap;
}
.switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: #fff;
    line-height: 35px;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ddd;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: #4e4851;
}

/* switch highlighters wrapper (sliding left / right)
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 6px;
    background: #070044;
    height: 100%;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: #fff;
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}

.input-group-text {
    padding: 5px 10px;
    border-radius: 0 6px 6px 0;
    color: #4e4851;
    font-size: 14px;
}

.custom-width-input {
    width: 46px;
    height: 26px;
    padding: 0px;
    text-align: center;
    box-shadow: none !important;
    padding-right: 0 !important;
}
.custom-width-input-app {
    width: 80px;
    height: 26px;
    padding: 0px;
    text-align: center;
    box-shadow: none !important;
    padding-right: 0 !important;
}
.custom-width-input.small {
    width: 30px ;
}
.border-box{
    box-shadow: 0 0 2px 1px #e7e7e7;
    border-radius: 6px;
    padding: 10px 6px;
}
.rate-table th{
    padding: 5px 0px 5px 0;
    font-weight: 500;
    color: #070044;
    font-size: 13px;
}
.rate-table th.padding-left{
    padding-left: 15px;
}
.rate-table td {
    vertical-align: middle;
    padding: 10px 1px;
}
.rate-table tr:last-child td{
    border-bottom: 0;
}
.funder-header span.funder-num {
    width: 20px;
    height: 20px;
    background-color: #dddddd;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 9px;
    padding: 2px;
    line-height: 18px;
}
.funder-name{
    max-width: 170px;
}
.funder-item {
    background: #fff;
    padding: 8px;
    box-shadow: 0px 1px 20px #dcdcdc;
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.gray-box {
    background: #ebebeb;
    border-radius: 6px;
    padding: 10px;
}

.modal-title {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #070044;
    font-weight: 600;
}

.modal-header,
.modal-footer {
    padding: 13px 15px;
}

.modal-footer button {
    font-size: 13px;
    padding: 6px 10px;
    border-radius: 6px;
}

.right_content .accordion-item.white {
    background-color: #fff;
}

/* ==========for tab ========================*/
.custom-tab {
    border-bottom: none;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
}

.custom-tab.nav-tabs .nav-link.active {
    background-color: #070044;
    color: #ffffff;
}
.custom-tab.nav-tabs .nav-link.active .task-count{
    color: #070044;
}
.custom-tab li {
    flex: 1;
    border-right: 1px solid #ffffff;
}

.custom-tab .nav-link {
    padding: 6px 10px;
    color: #fff;
    width: 100%;
    border: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #4e4851;
    border-radius: 0;
}
.custom-tab .nav-link i{
    font-size: 14px;
}
.custom-tab li:last-child .nav-link{
    border-radius: 0 6px 6px 0;
}

@media (max-width: 991px) {
    .navigation.main {
        width: 300px;
        left: 0;
    }

    /* .main {
        width: 100%;
        left: 300px;
    } */

}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .main {
        width: 100%;
        left: 0;
    }

    .navigation.main {
        width: 100%;
        left: 0;
    }
}

/* ===================assign patient rightbar end ================= */

/* ===================occupied panel rightbar start=================== */
.right_content-full-height {
    height: 84vh;
    position: relative;
}

.position-bottom {
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.service-funders hr {
    margin: 5px 0;
}

span.f-circle {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 10px;
}

.nav-pills.ward-room-pills .nav-link.active,
.nav-pills.ward-room-pills .show > .nav-link {
    color: #fff;
    background-color: #070044;
}

.nav-pills.ward-room-pills .nav-link {
    background: #fff;
    border: 0;
    border-radius: 0.25rem;
    color: #4e4851;
    padding: 6px 10px;
    box-shadow: 0 0 2px 1px #ddd;
    margin-right: 8px;
    margin-bottom: 11px;
    padding: 5px 6px;
    height: 28px;
}

.nav-tabs.ward-room-pills {
    padding-bottom: 9px;
    justify-content: center;
    border-bottom: 1px dashed #cbcbcb;
}

.room-list-tile li a {
    color: #4e4851;
    padding: 5px 6px;
    box-shadow: 0 0 2px 1px #ddd;
    border-radius: 0.25rem;
    margin-bottom: 2px;
    display: block;
    height: 28px;
    background: #fff;
    min-width: 29px;
    text-align: center;
}

.room-list-tile {
    display: flex;
    gap: 10px;
    margin-top: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.contract-list li a {
    border-radius: 0.25rem;
    padding: 6px 10px;
    box-shadow: 0 0 2px 1px #ddd;
    display: block;
    margin-bottom: 11px;
}

/* ==================occupied panel rightbar end===================== */

/* =================admission-list start============== */
.user-name {
    width: 27px;
    height: 27px;
    background: #9a95bb;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    line-height: 2.4;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
}
.count_purple {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #070044;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 2;
    margin-right: 10px;
    font-size: 11px;
    font-weight: 500;
    padding: 2px;
    max-width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    min-width: 22px;
    min-height: 22px;
}
.right_content .pagination-list {
    position: fixed;
    bottom: 0;
    left: auto;
    right: 0;
    width: 350px;
}
.pagination-list .page-link {
    color: #4e4851;
    font-weight: 600;
    background-color: #f2f2f2;
    border: 1px solid #d1d1d1;
    padding: 4px 9px !important;
    font-size: 12px;
    cursor: pointer;
}
.pagination-list .page-link i {
    font-size: 11px;
}

.pagination-list .page-item.active .page-link {
    background-color: #070044;
    border-color: #070044;
    color: #fff;
}
.pagination-list .page-link:focus {
    box-shadow: none;
}
.pagination-list .pagination {
    margin-bottom: 0;
}
.search-input i {
    position: absolute;
    right: 10px;
    top: 10px;
}
.disabled {
    pointer-events: none;
}
.search-input .form-control {
    padding-right: 28px;
}
.form-control.white.no-border-select{
    border: 0;
    box-shadow: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    padding: 0 0 8px 0;
}
/* =================admission-list end================ */
.overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}
.modal-body {
    padding: 20px 15px 0;
    max-height: 420px;
    overflow: auto;
}
.graph-modal-body{
    padding: 0px 15px 0;
    max-height: 420px;
    overflow: auto;
    padding-bottom: 5px;
}
.modal {
    background: rgba(255, 255, 255, 1.0);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: auto;
    top: 50%;
    transform: translate(0, -50%);
    width: 96%;
    padding: 20px;
}
.modal.modal-small{
    max-width: 800px;
    width: 95%;
}
.modal.modal-extrasmall {
    max-width: 500px;
    width: 80%;
}
.modal-dialog {
    width: 100%;
    background: #ffff;
    border-radius: 7px;
    align-items: center;
}
.modal-header {
    border-bottom: 1px solid #ababab;
}
/* =================Right Sidebar end====================== */

/* ======loader css======= */

.loader {
    /* -webkit-animation: rotation 4s infinite ease;
    animation: rotation 3.5s infinite ease; */
    width: 75px;
    height: 75px;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 46%;
    border-radius: 6px
}

.ontop {
    z-index: 999999;
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(180deg) scale(1.2);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(360deg) scale(1.2);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(180deg) scale(1.2);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(360deg) scale(1.2);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
/* ========================= */
/* ===== Scrollbar CSS ===== */

/* firefox */

body {
    scrollbar-width: 8px;
    scrollbar-color: #ffffff;
}

@-moz-document url-prefix() {
* {
    scrollbar-width: thin;
    scrollbar-color: #dfdbdb #ffffff;
  }
  .table-progress tr td:last-child
  {
    min-width: 28px;
  }
}

/*  Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color:#dfdbdb;
    border-radius: 6px;
    border: 1px solid  #dfdbdb;
}
.invoice-table ::-webkit-scrollbar{
    height: 8px;
}
/* ====================== login css ========================== */
.login-custom-width{
    width: 71%;
}
.form-label {
    position: absolute;
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    top: 17px;
    width: 100%;
    margin-bottom: 0 !important;
    color: #999;
    transition: all 0.2s ease;
    left: 20px;
}
.form-input {
    position: absolute;
    top: 26px;
    left: 20px;
    z-index: 1;
    resize: none;
    border: none;
    outline: none;
    color: #212529;
    background: transparent;
    transition: all 0.2s ease;
    width: 80%;
}

.login-signup .logo {
    width: 145px;
    position: absolute;
    left: 28px;
    top: 25px;
}

.login-signup-bg {
    height: 100vh;
    background-color: #070044;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 9;
}

.abstract-img {
    z-index: -1;
    opacity: 0.05;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.bg-text {
    text-align: center;
    color: #fff;
    z-index: 1;
}

.bg-text p {
    margin: 20px 0;
    padding: 0 20px;
}

.on-bg:hover {
    background-color: #4e4851 !important;
    color: #fff;
    transition: 0.5s;
}

.login-detail {
    height: 100vh;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
}

label.remember-forgot-pw {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

label.remember-forgot-pw input {
    width: 20px;
    margin-top: 0;
}

.login-signup .logo .title {
    font-size: 28px !important;
    margin-left: 13px;
    margin-top: 5px;
    display: block;
}

.custom-input input[type='text'],
.custom-input input[type='password'] {
    border: none;
}

.custom-input input:focus,
.custom-input input:active {
    outline: none;
}

.custom-input input[type='submit'] {
    color: white;
    background-color: #999;
}

.custom-input {
    position: relative;
    display: inline-block;
    border-top: 1px solid #dcdcdc;
    padding: 18px 20px;
    text-align: left;
    height: 50px;
}

.login-signup label {
    font-weight: 500;
}

.login-signup label.animate-label {
    font-size: 18px !important;
}
.form-input::-moz-placeholder {
    opacity: 0;
    visibility: hidden;
    color: transparent;
}
.form-input:-ms-input-placeholder {
    opacity: 0;
    visibility: hidden;
    color: transparent;
}
.form-input::placeholder {
    opacity: 0;
    visibility: hidden;
    color: transparent;
}
.form-input:not(:-moz-placeholder-shown).form-input:not(:focus) ~ .form-label {
    top: -0.75rem;
    left: 0;
    font-size: 0.875rem;
    z-index: 9;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.form-input:not(:-ms-input-placeholder).form-input:not(:focus) ~ .form-label {
    top: -0.75rem;
    left: 0;
    font-size: 0.875rem;
    z-index: 9;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.form-input:focus ~ .form-label,
.form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
    top: 5px;
    font-size: 12px;
    z-index: 9;
    transition: all 0.2s ease;
}
.custom-input label.animate-label {
    transform: scale(0.6) translateY(0%);
    transition: transform 0.2s linear;
}
.custom-input-box {
    box-shadow: 0px 1px 20px #dcdcdc;
    border-radius: 6px;
    background: #ffffff;
    width: 100%;
}

.custom-input-box i {
    color: #999;
    font-size: 16px;
    position: absolute;
    right: 20px;
}

.custom-input-box .input-invalid i {
    color: #b51700;
}
.carousel-css {
    height: 550px;
}

.carousel-css .carousel-caption {
    right: 0;
    left: 0;
    width: 100%;
}

.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.carousel-item {
    transition: transform 0.6s ease-in-out;
}

.error-message {
    width: 100%;
    margin-top: 0;
    font-size: 1em;
    color: #b51700;
    text-align: left;
    padding: 0px 5px 10px 20px;
    font-weight: 600;
}
.input-invalid {
    border-color: #b51700 !important;
    border: 1px solid;
    border-radius: 6px 6px 0 0;
}
.input-invalid.invalid-password{
    border-radius: 0px 0px 6px 6px;
    border-top: 0;
}
.cursor-pointer {
    cursor: pointer;
}

/* ======================Login End========================== */
/* sweet alert */
.swal-icon:first-child {
    margin-top: 15px;
}
.swal-overlay--show-modal .swal-modal {
    will-change: unset !important;
    }
.swal-text {
    text-align: center !important;
    font-weight: 500;
    color: #4e4851;
}
.swal-title {
    font-weight: 500;
    color: #4e4851;
}
.swal-button {
    font-size: 14px;
    padding: 6px 24px;
    border-radius: 6px;
    font-weight: 400;
}
.swal-button:hover{
    transform: translateY(-2px);
    transition: all 0.2s ease-in-out;
}
.swal-button:focus {
    box-shadow: none;
}
.swal-button--cancel {
    background: #4e4851;
    color: #fff;
}
.swal-button--cancel:hover {
    background: #4e4851 !important;
    color: #fff;
}
.swal-button--danger {
    background-color: #e93b59;
}
.swal-footer {
    text-align: center;
}
.swal-button--cancel:focus {
    box-shadow: none;
}
.swal-icon--success__ring {
    border: 4px solid #a5dc86;
}
.swal-button--confirm {
    background-color: #070044;
}
.swal-button--confirm:hover {
    background-color: #070044 !important;
    color: #ffffff;
}
.swal-modal {
    width: 420px;
    padding: 8px;
}
.swal-button-container {
    margin: 0 5px;
}

/* =========================filepond================ */
/*  remove anchor tag and label from file picker  */
.filepond--root .filepond--credits[style] {
    top: 0;
    bottom: auto;
    margin-top: 14px;
    display: none;
}
.filepond--root .filepond--credits {
    position: absolute;
    right: 0;
    opacity: .175;
    line-height: .85;
    font-size: 11px;
    color: inherit;
    text-decoration: none;
    z-index: 3;
    bottom: -14px;
    display: none;
}
.filepond--drop-labe,.filepond--panel-root {
    background-color: #fff !important;
    box-shadow: 0px 1px 2px #dcdcdc;
    border-radius: 6px !important;
    border: 1px solid #ced4da;
}
.filepond--drop-label.filepond--drop-label label {
    font-size: 13px;
    color: #707880 !important;
    width: 100%;
}
.filepond--root .filepond--drop-label,.filepond--root {
    min-height: 61px !important;
    height: 61px !important;
    margin-bottom: 0px !important;
    width: 100%;
}
.filepond--root .filepond--list-scroller {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}
.filepond--list-scroller[data-state=overflow]{
    mask: 0 !important;
    -webkit-mask:0 !important ;
}
/* =========================== */
.loader-overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgb(124 76 142 / 20%);
}
.accordion-custom {
    position: relative;
}
.next-button,
.prev-button:not(.nav-div-prev-button .prev-button) {
    background: rgba(78, 72, 81, .7);
    border-radius: 18px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    height: 20.7px;
    left: 5%;
    padding-top: 2px;
    position: absolute;
    text-align: center;
    width: 41px;
    z-index: 99;
}
.next-button {
    bottom: 114px;
}

.prev-button:not(.nav-div-prev-button .prev-button) {
    top: -11px;
}
.next-button:hover,
.prev-button:hover {
    color: #fff;
}
.white-overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgb(235 233 235 / 36%);
}
.patient-name {
    width: 80%;
    text-transform: capitalize;
}
.room-2,.room-2,.room-3,.room-4{
    justify-content: flex-start;
}
.room-status{
width: 11px;
height: 11px;
border-radius: 50%;
}
/* .small_box.occupied .room-status{
    background: #FF5D5B;
    border: 2px solid #CF544D;
}
.small_box.reserved .room-status{
    background: #FFBB39;
    border: 2px solid #CFA64E;
}
.small_box.vacant .room-status{
    background: #00CD4E;
    border: 2px solid #0EA642;
} */
.small_box .status {
    padding: 0px 2px;
    text-align: center;
    border-radius: 3px;
    height: 17px;
}
.input-readonly{
    width: 90%;
}
.input-readonly textarea {
    border: 0 !important;
    width: 98%;
    resize: none;
    cursor: pointer;
    background: transparent;
    outline: 0 !important;
}
.input-readonly input:focus{
    border: 0 !important;
    outline: 0 !important;
}
.small_box .status p {
    font-size: 11px;
}
.small_box .occupied-status{
    background: #fde3e7;
    color: #b51700;
    border: 1px solid #ffbdc8;
}
.small_box .vacant-status{
    background: #d6f9e7;
    color: #0EA642;
    border: 1px solid #9fddbe;
}
.small_box .maintenance-status{
    color: #4e4851;
    border: 1px solid #a3a3a3;
    background: #dbdbdb;
}
.small_box .reserved-status{
    background: #ffffd3;
    color: #CFA64E;
    border: 1px solid #e1d45b;
}
/* Datepicker */
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
  }
  .vacant_room i{
    font-size: 20px;
    margin-bottom: 5px;
  }
select option:first-child{
    background-color: #c8c8c8;
}
/* toggle switch */
.switch-custom {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 14px;
}

.switch-custom input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #070044;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.2s;
}
input:checked + .slider {
  background-color: #070044;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .custom-margin-top-3{
    margin-top: -3px;
  }
  .release-maintenance-icon{
    margin-top: -3px;
  }
  .applicable-rate-table th{
    color: #070044;
    font-weight: 600;
  }
  .applicable-rate-table > tr > th:first-child{
    width: 20%;
  }
  .applicable-rate-table > tr >td{
    padding-top: 3px;
  }
  .clear-filter {
    color: #4e4851;
    position: absolute;
    right: 6px;
    top: 6px;
  }
  /* horizontal tab if 1 ward */
  .tab-horizontal{
    display: block !important;
    box-shadow: none;
  }
  .accordion-custom.tab-horizontal .nav-pills .nav-link.active {
    border-radius: 0;
    min-height: 53px;
    padding-bottom: 0;
    box-shadow: none;
}
  .accordion-custom.tab-horizontal .nav-pills{
    border-radius: 6px 6px 0 0px;
    box-shadow: 0 -1px 0px 0px #ebe6e6, -6px 0 3px -4px #ebe6e6, 2px 0 8px -4px #ebe6e6
  }
  .accordion-custom.tab-horizontal .nav-pills p {
    color: #070044;
}
.accordion-custom.tab-horizontal .tab-content{
    margin-top: -2px;
}
.accordion-custom.tab-horizontal .nav-pills{
    width: 140px;
}
.accordion-custom .info-group .count_up:hover,
.accordion-custom .info-group .count_up:hover + .count_up, .accordion-custom .info-group .prev  {
  transform: none ;
  transition: none ;
  transform-origin: unset ;
}
.accordion-custom  .info-group .count_up, .accordion-custom  .prev {
  transform: none ;
  transition: none ;
  transform-origin: unset ;
  backface-visibility: unset;
  margin-bottom: 0;
}


  /* service dropdown */
  .nav-link.service-dropdown {
    border: 1px solid #ddd;
    padding: 4px 25px 4px 6px;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.topbar .nav-link.service-dropdown:before {
    content: "\f107";
    font-family: "Font Awesome 6 Free";
    right: 5px;
    position: absolute;
    top: 7px;
    font-weight: 900;
}
.service-dropdown-menu{
    padding: 0 !important;
    width: 190px;
    left: auto !important;
    right: 0 !important;
}
.year-dropdown .service-dropdown-menu{
    width: 140px;
}
.service-dropdown-menu .accordion-button {
    padding: 10px;
    border-top: 1px solid #ddd;
    font-size: 13px;
    font-weight: 500;
}
.service-dropdown-menu .accordion-button::after {
    background-size: 13px;
    background-position: 99% 50%;
    margin-left: 0;
    width: 12px;
    position: absolute;
    right: 9px;
}
.service-dropdown-menu .accordion-body {
    padding: 0px;
}
.service-dropdown-menu .accordion-body a {
    display: block;
    font-size: 13px;
    padding: 4px 0;
    font-weight: 500;
    padding: 4px 12px;
}
.service-dropdown-menu .accordion-body a:hover{
    background-color: #f2f2f2;
}
.service-dropdown-menu .accordion-button:not(.collapsed) {
    background: #f2f2f2;
}
.year-dropdown .nav-link.service-dropdown {
    padding:4px 6px 4px 7px;
}
.service-dropdown-menu {
    padding: 8px 0px;
}
.service-dropdown-menu li a {
    font-weight: 400;
    font-size: 13px;
    display: block;
    padding: 3px 10px;
}
.service-dropdown-menu li:hover a{
    background-color:#e9ecef
}

.form-control fieldset{
    border: 0;
  }
  .split-table th {
    font-weight: 600;
    color: #070044;
    padding: 0 15px 23px 0px;
    vertical-align: top;
    border: 0;
  }
  .prev-plan-table th{
    font-weight: 600;
    color: #070044;
    padding: 0 15px 18px 0px;
    vertical-align: top;
    border: 0;
  }
  .split-table th{
    white-space: nowrap;
  }
  .split-table th:last-child , .split-table td:last-child{
    padding-right: 0;
  }
  .table-responsive::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    }
    .table-responsive::-webkit-scrollbar-track {
        background: #ffffff;
    }
    .table-responsive::-webkit-scrollbar-thumb {
        background-color:#dfdbdb;
        border-radius: 6px;
        border: 1px solid  #dfdbdb;
    }
  .split-table td{
    padding: 0 10px 18px 0px;
    border: 0;
  }
  .prev-plan-table td{
    padding: 0 10px 10px 0px;
    border: 0;
  }
  .split-table input{
    width: 83px;
    height: 30px;
  }
  hr.dash-style {
    border-style: dashed;
    margin: 10px 0;
    border-color: #afafaf;
    opacity: 1;
}
.modal .datepicker-input-css .form-control.white{
    width: 150px;
}
.modal .custom-width{
    width: 100px;
}
.gray-border-box{
    background: #E7E7E7;
    border: 1px solid #C1C1C1;
    border-radius: 6px;
    padding: 9px;
}
.modal-footer{
    border: 0;
}
#tooltip-breakdown{
    width:200px !important;
}
.close-modal{
    background: #4e4851;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff !important;
    text-align: center;
    line-height: 1.6;
}
.plan-list .expired span i{
    color: #b51700;
    font-size: 9px;
}
.plan-list .current span i{
    color: #0EA642;
    font-size: 9px;
}
.plan-list .future span i{
    color: #CFA64E;
    font-size: 9px;
}
.plan-list li a{
    float: right;
}
.plan-list li {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
}
.plan-list{
    background: #fff;
    border-radius: 6px;
}
.plan-list li:last-child{
    border-bottom:none;
}
.tab-list button,.tab-list button:hover{
    text-align: left;
    color:#4e4851;
}
.tab-list.nav-tabs .nav-link.active{
   color: #fff;
   background-color: #070044;
}
 .tab-list.nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    margin-right: 7px;
}
.tab-list.nav-tabs .nav-link span{
color: #070044;
}
.tab-list.nav-tabs .nav-link.active span{
    color: #ffffff;
}
.tab-list{
    border: 0;
}
 .tab-list-content{
    border: 1px solid #dee2e6;
    border-radius: 0 6px 6px 6px;
    padding: 14px;
    /* min-height: 185px !important; */
    display: block !important;
}
.accordion-list .form-control:disabled {
    background-color: #f7f7f7 !important;
}
.accordion-list .collapse:not(.show){
    background-color: #f7f7f7 !important;
}

.box-shadow{
    box-shadow: 0 0 2px 1px #ddd;
    -webkit-box-shadow: 0 0 2px 1px #ddd;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
}
.new-box-shadow {
    padding: 25px 10px !important;
}
.box-title{
    font-size: 13px;
    font-weight: 700;
}
.card-padding
{
    padding: 12px;
}
.billing-list li{
    border-radius: 6px;
    box-shadow: 0 0 2px 1px #ddd;
    -webkit-box-shadow: 0 0 2px 1px #ddd;
    background: #fff;
    width: 100%;
    padding: 13px 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.billing-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, 22%);
    grid-gap: 18px;
    justify-content: space-between;
}
.billing-list .box{
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.billing-list .box.paid-box{
    background-color: #C3F3DB;
}
.billing-list .box.paid-box i{
    color: #14804A;
}
.billing-list .box.payment-box{
    background-color: #F2F3A1;
}
.billing-list .box.payment-box i{
    color: #adaf00;
}
.billing-list .box.unpaid-box{
    background-color: #ffe0e0;
}
.billing-list .box.unpaid-box i{
    color: #b51700;
}
.billing-list .box.draft-box{
    background-color: #ECECFF;
}
.billing-list .box.draft-box i{
    color: #5856a9;
}
.billing-list .box i{
    font-size: 16px;
}
.billing-list h3{
    font-size: 20px;
    margin-bottom: 2px;
}
.billing-list p{
    font-size: 14px;
    font-weight: 600;
}
.table-header-icon-box.paid-box {
    background-color: #c3f3db !important;
    border:unset !important;
}
.table-header-icon-box.paid-box i{
    color:#14804a;
}
.table-header-icon-box.paid-box.active
{
    border: 1px solid #14804a !important;
}
.table-header-icon-box.payment-box {
    background-color: #f2f3a1 !important;
    border:unset !important;
}
.table-header-icon-box.payment-box  i{
    color:#adaf00;
}
.table-header-icon-box.payment-box.active
{
    border: 1px solid #adaf00 !important;
}
.table-header-icon-box.unpaid-box {
    background-color: #ffe0e0 !important;
    border:unset !important;
}
.table-header-icon-box.unpaid-box  i{
    color:#b51700;
}
.table-header-icon-box.unpaid-box.active
{
    border: 1px solid #b51700 !important;
}
.table-header-icon-box.draft-box {
    background-color: #ececff !important;
    border:unset !important;
}
.table-header-icon-box.draft-box  i{
    color:#5856a9;
}
.table-header-icon-box.draft-box.active
{
    border: 1px solid #5856a9 !important;
}
.form-control.white.search-invoice {
    width: 190px;
    box-shadow: none;
    padding: 4px 27px 4px 27px;
    height: 30px;
}
.search-icon{
    position: absolute;
    top: 8px;
    left: 8px;
}
.filter-dropdown > .nav-link{
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding: 4.7px 7px;
    font-weight: 600;
}
.filter-dropdown > .nav-link.show,.filter-dropdown > .nav-link:hover , .filter-apply {
    border: 1px solid #070044;
    background-color: #070044 ;
    color: #fff !important;
}
.filter-dropdown > .nav-link:hover .applied-filter{
    background-color: #fff;
    color: #4e4851;
}
.filter-dropdown > .nav-link:hover .applied-filter a i{
    color: #4e4851;
}
.filter-dropdown .nav-link:after{
    display: none;
}
.btn.btn-create{
    padding: 4px 7px;
    font-size: 12px;
}
.dropdown  label,.dropdown-menu-action a{
    font-size: 13px;
    display: flex;
    align-items: center;
}
.dropdown  .dropdown-item,
.dropdown-menu label{
    padding: 4px 10px;
}
.dropdown-submenu-custom{
    padding: 6px 10px;
    width: 300px;
    height: 110px;
}
.applied-filter{
    background: #4e4851;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
    padding: 2px 7px;
    margin-left: 7px;
}
.filter-dropdown > .nav-link.show span{
    background-color: #fff;
    color: #4e4851;
}
.filter-dropdown > .nav-link.show span i{
    color: #4e4851;
}
.applied-filter > i{
    font-size: 9px;
}
.dropdown-submenu .dropdown-menu {
top: 0;
left: 100%;
margin-top: -1px;
}
.dropdown-submenu{
display: none;
position: relative;
}
.dropdown-menu-columns{
    width: 350px;
    right: 0 !important;
    left: auto !important;
}
.dropdown-menu-action{
    width: 90px;
    min-width: 90px;
}
.dropdown-menu.dropdown-submenu.show {
    position: absolute;
    left: 100% !important;
    top:0px !important;

    transform: none !important;
}
.sub-dropdown .nav-link.show,.sub-dropdown a:hover,
.dropdown-menu  label:hover{
    background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
    color: #4e4851;
    background-color: transparent;
}
.dropdown-submenu .datepicker-input-css .MuiInputBase-input {
    height: 28px;
    padding: 0 0px 0 6px !important;
}
.dropdown-submenu .datepicker-input-css .MuiButtonBase-root{
    padding-right: 2px !important;
    padding-left: 0 !important;
    width: 28px;
    height: 28px;
}
.dropdown-submenu p{
    font-size: 13px;
}
.invoice-status li.active{
    background: #070044;
    color: #fff;
}
.invoice-status li:nth-child(2).active:after,
.invoice-status li:last-child.active:after {
    transform: rotate(-90deg);
    right: auto;
    border-top: 26px solid #ffffff;
    left: -11px;
    border-left: 27px solid transparent;
    border-right: 20px solid transparent;
    top: 10px;
}
.invoice-status li:first-child{
    border-left: 1px solid #ddd;
    padding: 14px 25px 14px 28px;
}
.invoice-status li:first-child.active{
    padding: 14px 25px 14px 28px;
}
.invoice-status li:nth-child(2).active,
.invoice-status li:last-child.active {
    padding: 14px 16px 14px 36px;
    position: relative;
}
.invoice-status li {
    padding: 14px 25px 14px 42px;
    position: relative;
    font-size: 13px;
}
.invoice-status li.active{
    padding: 14px 16px;
    position: relative;
}
.invoice-status li.active:after {
    content: '';
    position: absolute;
    right: -34px;
    top: 12px;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 24px solid transparent;
    border-top: 22px solid #070044;
    clear: both;
    transform: rotate(-90deg);
}
.invoice-form label{
    width: 142px;
}
.invoice-form p{
    width: calc(100% - 142px);
}
.invoice-form .form-control,.w-70{
    width: calc(100% - 142px);
}
.box-header{
    border-bottom: 1px solid #ddd;
    height: 46px;
}
.table-custom{
    border: 1px solid #ddd;
    border-radius: 6px;
}
.table-custom th{
    color: #070044;
    padding: 11px 10px;
    white-space: nowrap;
}
.table-custom input,.table-custom select{
    height: 26px;
    padding:2px 20px 2px 8px;
}
.table-custom select{
    min-width: 100px;
}
.table-custom td{
    border-top: 1px solid #ddd;
    padding: 9px 10px;
    vertical-align: middle;
    white-space: nowrap;
}
.table-custom tr:last-child td{
    border: 0;
}
.select-product{
    width: 135px;
}
.select-uom{
    width: 80px;
}
.table-custom td span {
    background-color: #eee;
    color: #4e4851;
    border-radius: 33px;
    padding: 2px 7px;
    font-size: 11px;
    font-weight: 600;
}
.total-div {
    width: 260px;
    float: right;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 20px;
}
.qty-input{
    width: 50px;
}
.info-btn{
    position: absolute;
    right: -20px;
    top: 7px;
}
.dark-gray-bg{
    background-color: #4e4851;
    color: #fff;
    border-radius: 0 0 6px 6px;
}
.hidefield{
    display: none;
}
.showfield{
    display: block;
}
.Mui-disabled {
    background: #e9e9e9 !important;
    -webkit-text-fill-color: #4e4851 !important;
}

/*  Chrome, Edge, and Safari */
.table-responsive::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}
.table-responsive::-webkit-scrollbar-thumb {
    background-color:#dfdbdb;
    border-radius: 6px;
    border: 1px solid  #dfdbdb;
}
.whitespace-wrap{
    white-space: normal !important;
}
  .form-control fieldset{
    border: 0;
  }
  .split-table th , .prev-plan-table th{
    font-weight: 600;
    color: #070044;
    padding: 0 10px 12px 0px;
    vertical-align: top;
  }
  .split-table td{
    padding: 0 10px 20px 0px;
  }
  .prev-plan-table td{
    padding: 0 10px 10px 0px;
  }
  .split-table input{
    width: 75px;
    margin: 0 auto;
    height: 33.5px;
  }
  hr.dash-style {
    border-style: dashed;
    margin: 10px 0;
    border-color: #afafaf;
    opacity: 1;
}
.modal .datepicker-input-css .form-control.white{
    width: 152px;
}
.modal .custom-width{
    width: 100px;
}
.gray-border-box{
    background: #F6F6F6;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
    padding: 9px;
}
.modal-footer{
    border: 0;
}
#tooltip-breakdown{
    width:180px !important;
}
.close-modal{
    background: #4e4851;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff !important;
    text-align: center;
    line-height: 1.6;
}
.plan-list .expired span i{
    color: #b51700;
    font-size: 9px;
}
.plan-list .current span i{
    color: #0EA642;
    font-size: 9px;
}
.plan-list .future span i{
    color: #CFA64E;
    font-size: 9px;
}
.plan-list li a{
    float: right;
}
.plan-list li {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
}
.plan-list{
    background: #fff;
    border-radius: 6px;
}
.plan-list-modal{
    border: 1px solid #ddd;
    width: 242px;
}
.plan-list li:last-child{
    border-bottom:none;
}
.tab-list button,.tab-list button:hover{
    text-align: left;
    color:#4e4851;
}
.tab-list.nav-tabs .nav-link.active{
   color: #fff;
   background-color: #070044;
}
 .tab-list.nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    margin-right: 7px;
}
.tab-list.nav-tabs .nav-link span{
color: #070044;
}
.tab-list.nav-tabs .nav-link.active span{
    color: #ffffff;
}
.tab-list{
    border: 0;
}
 .tab-list-content{
    border: 1px solid #dee2e6;
    border-radius: 0 6px 6px 6px;
    padding: 14px;
}
/* .invoice-form input:disabled,.invoice-form textarea:disabled{
    background-color: transparent !important;
    border: 0;
    font-weight: 700;
} */
  /* horizontal tab if 1 ward */
  .tab-horizontal{
    display: block !important;
    box-shadow: none;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 10px;
}
/* .invoice-form .Mui-disabled{
    background-color: transparent !important;
    border: 0;
    font-weight: 700;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 10px;
} */
  .accordion-custom.tab-horizontal .nav-pills{
    border-radius: 6px 6px 0 0px;
    box-shadow: 0 -1px 0px 0px #ebe6e6, -6px 0 3px -4px #ebe6e6, 2px 0 8px -4px #ebe6e6
  }
  .accordion-custom.tab-horizontal .nav-pills p {
    color: #070044;
}
.accordion-custom.tab-horizontal .tab-content{
    margin-top: -2px;
}
.accordion-custom.tab-horizontal .nav-pills{
    width: 140px;
}
.accordion-custom .info-group .count_up:hover,
.accordion-custom .info-group .count_up:hover + .count_up, .accordion-custom .info-group .prev  {
  transform: none ;
  transition: none ;
  transform-origin: unset ;
}
.accordion-custom  .info-group .count_up, .accordion-custom  .prev {
  transform: none ;
  transition: none ;
  transform-origin: unset ;
  backface-visibility: unset;
  margin-bottom: 0;
}

  /* service dropdown */
  .topbar .nav-link.service-dropdown {
    border: 1px solid #ddd;
    padding: 4px 25px 4px 6px;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.topbar .nav-link.service-dropdown:before {
    content: "\f107";
    font-family: "Font Awesome 6 Free";
    right: 5px;
    position: absolute;
    top: 7px;
}
.service-dropdown-menu {
    padding: 8px 0px;
}
.service-dropdown-menu li a {
    font-weight: 400;
    font-size: 13px;
    display: block;
    padding: 3px 10px;
    color: #212529;
}
.service-dropdown-menu li:hover a{
    background-color:#e9ecef
}

.form-control fieldset{
    border: 0;
  }
  .split-table th {
    font-weight: 600;
    color: #070044;
    padding: 0 10px 23px 0px;
    vertical-align: top;
    border: 0;
  }
  .prev-plan-table th{
    font-weight: 600;
    color: #070044;
    padding: 0 10px 18px 0px;
    vertical-align: top;
    border: 0;
  }
  .split-table th{
    white-space: nowrap;
  }
  .split-table th:last-child , .split-table td:last-child{
    padding-right: 0;
  }
  .table-responsive::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    }
    .table-responsive::-webkit-scrollbar-track {
        background: #ffffff;
    }
    .table-responsive::-webkit-scrollbar-thumb {
        background-color:#dfdbdb;
        border-radius: 6px;
        border: 1px solid  #dfdbdb;
    }
  .split-table td{
    padding: 0 10px 18px 0px;
    border: 0;
  }
  .prev-plan-table td{
    padding: 0 10px 10px 0px;
    border: 0;
  }
  .split-table input{
    width: 83px;
    height: 30px;
    margin-left: 0;
  }
  hr.dash-style {
    border-style: dashed;
    margin: 10px 0;
    border-color: #afafaf;
    opacity: 1;
}
.modal .datepicker-input-css .form-control.white{
    width: 150px;
}
.modal .custom-width{
    width: 100px;
}
.modal-footer{
    border: 0;
}
#tooltip-breakdown{
    width:200px !important;
}
.close-modal{
    background: #4e4851;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff !important;
    text-align: center;
    line-height: 1.6;
}
.plan-list .expired span i{
    color: #b51700;
    font-size: 9px;
}
.plan-list .current span i{
    color: #0EA642;
    font-size: 9px;
}
.plan-list .future span i{
    color: #CFA64E;
    font-size: 9px;
}
.plan-list li a{
    float: right;
}
.plan-list li {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
}
.plan-list{
    background: #fff;
    border-radius: 6px;
}
.plan-list li:last-child{
    border-bottom:none;
}
.tab-list button,.tab-list button:hover{
    text-align: left;
    color:#4e4851;
}
.tab-list.nav-tabs .nav-link.active{
   color: #fff;
   background-color: #070044;
}
 .tab-list.nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    margin-right: 7px;
}
.tab-list.nav-tabs .nav-link span{
color: #070044;
}
.tab-list.nav-tabs .nav-link.active span{
    color: #ffffff;
}
.tab-list{
    border: 0;
}
.more-info .pending-list li{
    margin-bottom: 10px;
    flex: 1;
}
.pending-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, 100px);
    justify-content: space-between;
    align-content: flex-start;
    grid-gap: 2%;
}


/* Invoice table css */
.Mui-TableHeadCell-Content-Wrapper{
    color: #070044;
}
.MuiTableCell-head,.MuiTableCell-body{
    padding: 5px 10px !important;
    vertical-align: middle !important;
    border-color: #e8e8e8 !important;
    color: #4e4851 !important;
}
.MuiTableHead-root tbody tr:nth-child(even){
    background-color: #f2f2f2 !important;
}
.invoice-table .MuiPaper-root{
    box-shadow: none;
}
.invoice-table .MuiTableRow-root:hover td {
    background-color:rgb(241 241 241);
}
.invoice-table-pagination ul{
    justify-content: end !important;
}
.invoice-table-pagination{
    border-top: 1px solid #ddd;
    padding-top: 14px;
    padding-bottom: 4px;
}
svg[data-testid="CheckBoxOutlineBlankIcon"]{
    fill: #c7c7c7;
    width: 22px;
}
svg[data-testid="CheckBoxIcon"],svg[data-testid="IndeterminateCheckBoxIcon"]{
    fill: #070044;
    width: 22px;
}
.MuiTableRow-root.Mui-selected {
    background-color: #e9e9e9 !important;
}
.status{
    border-radius: 33px;
    padding: 4px 10px;
    font-size: 12px;
}
span[aria-label="Collapse"] .Mui-disabled,span[aria-label="Expand"] .Mui-disabled{
    display: none !important;
}
/* .invoice-table table thead tr th:first-child,
.invoice-table table tbody tr td:first-child,
.invoice-table table thead tr th:nth-child(2),
.invoice-table table tbody tr td:nth-child(2){
    width: 20px !important;
    min-width: 20px !important;
    padding: 0 !important;
} */
.MuiCheckbox-root{
    padding: 0 !important;
}

/* Invoice print  */

.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    border-top: 1px solid #212121 ;
    padding: 5px 0;
    text-align: center;

}
.invoice-heading{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 18px;
    line-height: 23px;
    margin-top: 10px;
}
.invoice-section{
    display: flex;
    gap: 20px;
}
.width-20{
    width: 20%;
}
.width-80{
    width: 80%;
}
.table-border{
    border: 1px solid #ccc;
}
.table-border td{
    padding: 10px;
}
.table-border td{
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
.border-tr{
    border-bottom: 1px solid #ccc;
}
.table-right-align{
    margin-left: auto;
}
.border-bottom-dashed{
    border-bottom: 3px dashed #212121 !important
}
.table-invoice .border-bottom{
    border-bottom: 1px solid #212121 !important;
}
.table-invoice .title{
    font-size: 35px;
    margin-bottom: 12px;
}
.margin-bottom-10{
    margin-bottom: 10px;
}
.margin-bottom-5{
    margin-bottom: 5px;
}
.table-center{
    margin: 0 auto;
}
.invoice-padding{
    padding: 40px;
}
@media print {

    @page {
        margin: 40px;
    }
    html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        color: #000000;
      }
      .pagebreak{
        page-break-before: always;
      }

    }

.task-tab{
    overflow: visible;
}
.right_content-height-tab .tab-custom-height-task {
    /* padding-bottom: 41px; */
    /* height: calc(100vh - 177px);
    overflow: auto; */
    padding-left: 1px;
    padding-right: 1px;
}
.task-tab li:first-child button{
    border-radius: 6px 0 0 6px;
}
.task-tab  button.nav-link{
    position: relative;
    padding: 9px 7px 4.5px 7px;
    height: 51px;
}
.task-tab button.nav-link.confirmation-tab{
    padding: 4.5px 7px;
}
.task-list li a{
    box-shadow: 0 0 2px 1px #ddd;
    -webkit-box-shadow: 0 0 2px 1px #ddd;
    display: block;
    border-radius: 6px;
    padding: 4px 10px 4px 20px;
    background: #fff;
    margin-bottom: 15px;
    font-weight: 600;
    position: relative;
}
.task-list li a:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #4e4851;
    border-radius: 50%;
    top: 11px;
    left: 7px;
}
.task-count {
    background: #fff;
    color: #000;
    min-width: 21px;
    min-height: 21px;
    padding: 1px;
    border-radius: 50%;
    position: absolute;
    right: 3px;
    bottom: 41px;
    box-shadow: 0 0 1px 1px #b7b7b7;
    font-weight: 700;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
}
.section-title{
    color: #070044;
    font-size: 17px;
    margin-bottom: 14px;
}
.bg-white{
    padding: 15px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 30px;
}
.MuiTableCell-body.purple-text {
    color: #070044 !important;
}
.padding-right-30{
    padding-right: 30px !important;
}
.nowrap{
    white-space: nowrap !important;
}
.service-logo{
    max-height: 110px;
    object-fit: contain;
    max-width: 300px;
}

/* Referral Manager */
.status-icon {
    width: 28px;
    height: 28px;
    display: block;
    padding: 6px;
    box-shadow: 0 0 2px 1px #ddd;
    -webkit-box-shadow: 0 0 2px 1px #ddd;
    border-radius: 6px;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
}
.status-icon.active{
    background-color:#070044 ;
}
.status-icon.active svg path {
    fill: #ffffff;
    stroke: #ffffff;
 }
 .referral-table td, .referral-table th{
    white-space: nowrap;
 }
 .count_up.active{
    border: 1px solid #070044;
 }
 .count_up.active svg path{
    fill: #070044;
 }
 .legend-color{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
 }
 .legend-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 3px;
}
.pie-container{
    width: 70%;
    margin: 0 auto;
}
.legend-container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.legend-label {
    font-size: 13px;
}
.chart-center{
    position: absolute;
    top: 37%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.pending-list-referral .count_up.active{
    background-origin: 1px solid #070044;
}
.pending-list-referral .count_up.active h3 {
    color: #070044;
}
.pending-list-referral .count_up.active svg,.pending-list-referral .count_up.active svg path{
    color: #070044;
    fill: #070044;
    stroke:#070044 ;
}
.referral-height {
    padding-bottom: 50px;
    /* height: 75vh;
    overflow: auto; */
}
.referral-tabs .nav-link {
    box-shadow:0 0 1px 1px #ededed;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #d9d9d9 !important;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 35px;
    width: 35px;
}
.referral-tabs .nav-link:disabled{
    background: #f2f2f2;
}
.referral-tabs{
    border: 0;
    position: relative;
}
.referral-tabs .nav-item{
    position: relative
}
.referral-tabs .nav-item .nav-link.active{
    background: #070044 !important;
}
.referral-tabs .nav-item .nav-link.active svg,.referral-tabs .nav-item .nav-link.active svg path{
    fill: #ffffff !important;
    color: #ffffff !important;
    stroke: #ffffff !important;
}
/* .referral-tabs .nav-item:not(:last-child):after {
    content: "";
    position: absolute;
    right: -19px;
    width: 17px;
    height: 1px;
    background-color: #d8d8d8;
    top: 15px;
}
.referral-tabs.service .nav-item:not(:last-child):after{
    right: -32px;
    width: 30px;
} */
.referral-tabs:before
{
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    top: 17px;
    
}
.main.show .referral-overview{
    flex-direction: column;
}
.referral-tabs .nav-link svg {
    width: 18px;
    height: 38px;
}
.status-box {
    box-shadow: 0 0 1px 1px #efefef;
    padding: 9px 11px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
}
.referral-status{
    border-radius: 6px;
    font-size: 12px;
    padding: 2px 9px;
    font-weight: 600;
}
.yellow-status{
    background: #F2F3A1;
    color: #746907;
    border: 1px solid #e0e192;
}
.green-status{
    background: #C3F3DB;
    color: #14804A;
    border: 1px solid #a9d9c1;
}
.red-status{
    background: #FFD1D9;
    color: #E93B59;
    border: 1px solid #efbac3;
}
.selectbox > div{
    box-shadow: 0 0 0px 1px #efefef !important;
    border: 1px solid #ced4da !important;
    min-height: 32px !important;
    padding: 0px !important;
}
.selectbox > div >div{
    margin-top: -1px !important;
}
.css-1u9des2-indicatorSeparator{
display: none !important;
}
.accordion-item.white-bg{
    background-color: #ffffff;
}
.funder-number {
    width: 20px;
    height: 20px;
    background-color: #dddddd;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 9px;
    padding: 2px;
    color: #4e4851;
}
.right_content .accordion-item .accordion-button.accordion-button-gray{
    color: #4e4851 !important;
}
.icon-md{
    font-size: 15px;
}
/* Assessment form  */
.modal-title-header {
    background-color: #070044;
    padding: 8px 10px;
    border-radius: 6px;
    height: 36px;
    display: flex;
    align-items: center;
}
.modal-title-header h3 {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
}
.table-progress th{
    background-color: #F0F0F0;
    text-transform: uppercase;
    color: #070044;
    padding: 8px 10px;
    border-right: 1px solid #ddd;
    font-size: 12px;
}
.table-progress tr th:last-child{
    border-right: 0;
}
.table-progress td{
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.table-progress tr:last-child td{
    border-bottom: 0;
}
.table-progress tr td:last-child{
    border-right: 0;
}
.table-progress td input{
    border: 0 !important;
    box-shadow: none !important;
}
.table-radius{
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #ddd;
}
.table-custom-width{
    width: 90%;
    margin: 0 auto;
}
.border-layout{
    border: 1px solid #070044;
    border-radius: 6px;
}
.quickaccess-buttons {
    background: #EBEBEB;
    padding: 7px;
    border-radius: 6px;
    box-shadow: 0 0 2px 0px #828282;
    /* position: fixed;
    right: 13px; */
    height: 387px;
    /* top: 94px; */
    margin-right: 12px;
}
.quickaccess-container {
    margin-right: 20px;
    margin-top: 3px;
    height: 370px;
    overflow-y: scroll;
    overflow-x: hidden;
}
body{
    scroll-padding-top: 10px;
}
.quickaccess-buttons a {
    background: #fff;
    padding: 9px 9px 9px 7px;
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 0 5px 1px #c8c8c8;
    position: relative;
    margin-bottom: 10px;
    justify-content: center;
}
.section-staus {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 5px;
}
.section-staus.green{
    background-color: #2EC077;
}
.section-staus.red{
    background-color: #e93b59;
}
hr.dashed-border {
    border-style: dashed;
    border-width: 1px;
}
.modal-assessment .modal-body{
    padding: 0px 7px 2px 13px;
    max-height: 445px;
}
.modal-top-button{
    background: #fff;
    padding: 8px 10px 0;
    /* position: fixed;
    right: 1px;
    width: 99%;
    padding: 8px 10px;
    z-index: 9;
    top: 48px; */
}
.progress-bar div[dir="ltr"]>div>div{
    background-color: #2EC077 !important;
    height: 14px !important;
}
.progress-bar div[dir="ltr"]>div{
    height: 14px !important;
}
.progress-bar div[dir="ltr"]>div>div>span{
    font-size: 12px !important;
}
.section-staus.large {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    right: auto;
    top: auto;
}
.float-left{
    float: left;
}
.table-progress td input.input-border{
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    color: #4e4851;
    box-shadow: 0 0 0px 1px #efefef !important;
    border: 1px solid #ced4da !important;
    padding: 3px 10px;
}
.no-right-border{
    border-right: 0 !important;
}

.table-custom-border tr td:last-child{
    border-right: 0;
}
.table-custom-border tr:last-child td{
    border-bottom: 0;
}
.costing-modal .modal-body{
padding-top: 10px;
}
.form-control.white.costing-search{
    background: #f2f2f2;
    padding: 8px 7px 8px 27px;
}
  .costing-search-icon {
    top: 12px !important;
}

.access-denied-page i{
    font-size: 37px;
    color: #4e4851;
}
.h-100vh{
    height: 100vh;
}

.access-denied-page,
.error-page{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 43%;
    background: #f2f2f2;
    padding: 48px;
    box-shadow: 0px 1px 20px #472454;
    border-radius: 6px;
}
.error-page i{
    font-size: 37px;
    color: #4e4851;
}
.error-page h2{
    font-size: 25px;
    margin: 10px 0 10px;
}
.error-logo img{
    width: 140px;
    margin: 35px;
}
.abstarct1 img{
    opacity: 0.06;
    position: absolute;
    width: 56%;
    left: 20%;
    top: -40%;
    transform: rotate(83deg);
}
.error-bg{
    background-color: #070044;
    position: relative;
    overflow: hidden;
    height: 100vh;
}
.error-page p{
    font-size: 15px;
}
.organization-dropdown .tag-item {
    margin: 0 !important;
    margin-bottom: 4px !important;
}
.organization-dropdown .placeholder {
    background: transparent;
    color: #4e4851;
    opacity: 1;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer !important;
}
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: 1px solid #ced4da !important;
    border-radius: 6px !important;
    padding: 3px 7px 0 !important;
}
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-content .search {
    margin: 0;
    padding: 6px 2px;
    margin-bottom: 7px;
}
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-content{
    padding: 5px;
    max-height: 325px;
    overflow-y: scroll;
    z-index: 999;
}
.organization-dropdown .node {
    padding: 5px 4px;
    display: flex;
    align-items: center;
}
.organization-dropdown .toggle {
    background: #070044;
    color: #fff;
    padding: 0px 3px;
    border-radius: 3px;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 1;
    font-style: normal;
    margin-left: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.organization-dropdown .node.select-all i{
display: none !important;
}
.organization-dropdown .tag {
    background-color: #4e4851;
    border: 1px solid #e9e9e9;
    padding: 0px 8px;
    border-radius: 2px;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    border-radius: 6px;
    margin-right: 7px;
}
.organization-dropdown .tag:focus-within {
    background-color: #4e4851;
    border-color: #e9e9e9;
}
.organization-dropdown .tag-remove {
    color: #ffffff;
    font-size: 13px;
    margin-left: 8px;
    font-weight: 600;
}
.organization-dropdown .tag-remove:focus {
    color: #ffffff;
}
.organization-dropdown .toggle.expanded:after {
    content: "-";
    margin-top: -5px;
    font-size: 20px;
}
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after,
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: "\f078" !important;
    color: #4e4851;
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    font-size: 12px !important;
    margin-right: 0 !important;
    margin-left: 14px;
}
.dropdown-trigger.arrow.top{
    background: #070044;
}
.organization-dropdown .dropdown-trigger.arrow.top .placeholder{
color: #fff;
}
.organization-dropdown .dropdown-trigger.arrow.top .tag{
    background-color: #fff;
    color: #4e4851;
    font-weight: 600;
}
.organization-dropdown .dropdown-trigger.arrow.top .tag-remove{
    color: #4e4851;
}
.organization-dropdown .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
    color: #fff;
}
.organization-dropdown .node>label {
    color: #212529;
}
.search-service i{
    font-size: 14px;
}
.search-service input{
    width: 100% !important;
    background: #f2f2f2 !important;
}
.search-service{
    width: 93%;
    margin: 6px auto !important;
}
.table-custom-border tr td:last-child{
    border-right: 0;
}
.table-custom-border tr:last-child td{
    border-bottom: 0;
}
.costing-modal .modal-body{
padding-top: 10px;
}
.form-control.white.costing-search{
    background: #f2f2f2;
    padding: 8px 7px 8px 27px;
}
  .costing-search-icon {
    top: 12px !important;
  }
.progress-bar div[dir="ltr"]>div>div{
    background-color: #2EC077 !important;
    height: 14px !important;
}
.progress-bar div[dir="ltr"]>div{
    height: 14px !important;
}
.progress-bar div[dir="ltr"]>div>div>span{
    font-size: 12px !important;
}
.section-staus.large {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    right: auto;
    top: auto;
}
.float-left{
    float: left;
}
.table-progress td input.input-border{
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    color: #4e4851;
    box-shadow: 0 0 0px 1px #efefef !important;
    border: 1px solid #ced4da !important;
    padding: 3px 10px;
}
.no-right-border{
    border-right: 0 !important;
}
.table.table-costing>:not(caption)>*>*{
    padding:0.35rem 0.5rem !important;
  }
.gray-status{
    border: 1px solid #ababab;
    background: #dadada;
}
.selectbox .css-12a83d4-MultiValueRemove:hover {
    background-color: #ffd1d9;
    color: #E93B59;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.selectbox .css-wsp0cs-MultiValueGeneric {
    font-weight: 600;
}
.selectbox .css-1p3m7a8-multiValue {
    border: 1px solid #bfbfbf;
    border-radius: 6px;
}
.cursor-pointer{
    cursor: pointer;
}
.close-small-btn{
    border: 0;
    background: transparent;
}
.date-custom-style{
    box-shadow: 0 0 0px 1px #efefef;
    border: 1px solid #ced4da;
    padding: 2px 11px;
    border-radius: 6px;
}
.date-custom-style .form-control.white{
    box-shadow: none !important;
    border: none !important;
}
.date-custom-style.datepicker-input-css .MuiInputBase-adornedEnd{
padding-right: 5px !important;
}
.date-custom-style.datepicker-input-css .MuiInputBase-input{
    padding-left: 0 !important;
}
.mark-read{
    color: #e93b59 !important;
    font-size: 12px;
    text-decoration: underline;
}
.scroll-box{
    height: calc(100vh - 109px);
    overflow: auto;
}
.message-box a:hover{
    background-color: #f2f2f2;
}
.gray-light-bg{
    background-color: #e3e3e3;
    border-radius: 6px;
}
.modal-preview .modal-content{
    min-height: 540px;
}
.modal-preview .modal-body {
    max-height: 450px;
}
.contract-attachments a{
    max-width: 90%;
    word-wrap: break-word;
    cursor: pointer;
}
.red-box{
    background: #FFE9ED;
    border-radius: 6px;
    padding: 6px;
    font-weight: 500;
    color: #E93B59;
    border: 1px solid #eeafb9;
}
.referral-tabs .nav-item .nav-link.current {
    background: #070044!important;
    outline: 1px solid #070044;
    outline-offset: 1.5px;
}
.disable-all button{
    opacity: 0.6;
}
.main .tabular-data .custom-width-p,.main .tabular-data .custom-width-p-sm{
    width: 99px;
}
.main .tabular-data-custom p{
    width: 109px;
}
.main.show .tabular-data .custom-width-p{
    width: 109px;
}
.yellow-box {
    color: #887D1F;
    background-color: #FEFFDF;
    border: 1px solid #CCC054;
    border-radius: 6px;
    padding: 6px 8px;
    margin: 10px 0 15px;
}
.green-box {
    color: #41976C;
    background-color: #D6F8E7;
    border: 1px solid #76D2A4;
    border-radius: 6px;
    padding: 6px 8px;
    margin: 10px 0 15px;
}
.red-box {
    color: #E93B59;
    background-color: #FFE9ED;
    border: 1px solid #FFA5A5;
    border-radius: 6px;
    padding: 6px 8px;
    margin: 10px 0 15px;
}
.pound-sign-input label{
    position: absolute;
    top: 6.5px;
    left: 10px;
    font-size: 14px;
}
.pound-sign-input  input{
    padding-left: 22px;
}
.rpv-core__inner-page,.rpv-core__page-layer {
    width: 100% !important;
}
.error-page.success-page i{
    font-size: 50px;
    color: #2EC077;
}
.custom-icon-input i{
    color: #999;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 8px;
}
.change-password-box label{
    width: 176px;
}
.border-right-box{
    padding-right: 40px;
    border-right: 1px solid #ddd;
}
.custom-icon-input.input-invalid{
    border: 0;
}
.custom-icon-input.input-invalid input{
    border-color: #b51700 !important;
    border: 1px solid;
}
.custom-icon-input.input-invalid i{
    color: #b51700;
}

.page-select.form-control{
    padding: 2px 14px 2px 6px;
    width: 49px;
    font-weight: 600;
    background-position: 93% 50%;
    box-shadow: none;
}
.page-select option:first-child{
    background-color: #fff;
}

.multiselect-recipients-dropdown .MuiFilledInput-root{
padding: 0 !important;
background-color: transparent !important;
padding-right: 53px !important;
cursor: default;
}
.multiselect-recipients-dropdown  .MuiFormControl-root .MuiIconButton-sizeMedium svg{
    font-size: 15px;
    fill: #4e4851;
}
.multiselect-recipients-dropdown .MuiFilledInput-root:after{
    display: none;
}
.multiselect-recipients-dropdown .MuiFilledInput-root:before{
    border-bottom: 1px solid #ced4da !important;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input{
    padding: 0px 0 7px 0;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input::placeholder{
    color: #4e4851;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input::-ms-input-placeholder{
    color: #4e4851;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiAutocomplete-endAdornment {
    right: 21px !important;
    top: 0px;
}
.multiselect-recipients-dropdown .MuiFormControl-root .MuiIconButton-sizeMedium{
    padding: 3px;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input{
    padding: 0px 4px 7px 0px !important;
    font-size: 13px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}
.multiselect-recipients-dropdown .form-control.is-invalid{
    background-position:94% 3px !important;
    padding: 0 !important;
}
.multiselect-recipients-dropdown:after{
    content: "\f078";
    position: absolute;
    right: 5px;
    top: 3px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    pointer-events: none;
}
.custom-width-subject-input{
    width: calc(100% - 82px);
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiAutocomplete-tag {
    height: 23px;
    background: #f1f1f1;
    margin-right: 5px;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiChip-label{
    padding-left: 7px;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}
.multiselect-recipients-dropdown .MuiFilledInput-root .MuiChip-deleteIcon {
    font-size: 18px;
}
.custom-file-uploader .file-list{
    display: flex;
    flex-wrap: wrap;
}
.custom-file-uploader .file-list>div {
    background: #efefef;
    padding: 5px 8px;
    border-radius: 3px;
    margin-right: 10px;
    font-weight: 600;
    border: 1px solid #e2e2e2;
    font-size: 12px;
    box-shadow: 0 1px 1px 1px #eeeeee;
    margin-bottom: 10px;
}
.custom-file-uploader .file-list .fa-close{
    margin-left: 12px;
    cursor: pointer;
}
.custom-file-uploader .file-list .fa-close:before{
    content: "\f1f8";
    font-size: 12px;
    color: #e93b59;
}
.custom-file-uploader .file-list .fa-check{
    color: #14804a;
    margin-left: 13px;
}
.custom-file-uploader .file-input{
    cursor: pointer;
}
/* ==========Integration============= */
.child-submenu
{
    max-height: 0;
}
.child-submenu.show {
    position: relative !important;
    transform: none !important;
    background: #f2f2f2;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    max-height: 170px;
    transition: max-height 0.3s ease-out;
    padding-bottom: 10px;
}
.child-submenu svg , .child-submenu path{
    fill:#4E4851 !important;
}
.navigation .child-submenu a {
    padding: 0 0px;
    margin-left: 20px;
    width: 190px;
    z-index: 999;
}
.navigation.show .child-submenu a
{
 margin-left: 8px;
 width: 38px;
}

.navigation .child-submenu a  span{
    color: #4e4851 !important;
}
.sidebar-menu.show::after,.child-submenu.show:after
{
    content:'';
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px #f2f2f2;
    pointer-events: none;
}
.sidebar-menu.show::before
{
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px #f2f2f2;
    pointer-events: none;
}
.sidebar-menu{
    cursor: pointer;
}
.sidebar-menu.show{
    background: #f2f2f2;
}
.sidebar-menu.show span{
    color: #4e4851 !important;
}
.sidebar-menu.show span svg,.sidebar-menu.show span svg path{
    fill: #4e4851;
}
.child-menu-effect.active {
    background-color: #dfdfdf !important;
    border: 1px solid #bebebe;
    border-radius: 10px;
}
.navigation .child-submenu a span.icon{
    min-width: 38px;
}
.navigation .child-submenu a:hover{
    background-color: transparent;
}
.integration-header{
    border-bottom: 1px solid #ddd;
}
.list-unstyled li .show .title .custom-arrow {
    transform: rotate(-270deg);
    color:#4E4851;
}
.parent-menu-css
{
    width: 67%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.child-menu-effect.active:before {
    display: none;
}
.child-menu-effect.active:after {
    display: none;
}
.child-menu-effect .title
{
    font-size: 13px !important;
}
/* ==========Integration============= */
.activebed {
    border: #070044 1px solid !important;
    box-shadow: 0 0 1px 0.5px #ba83cd7a;
}
.small_box.activebed .card_header > p {
    color: #070044;
}
.alert-input{
    position: absolute;
    right: 36px;
    top: 6.5px;
    font-size: 15px;
    color: #e93b59;
}

/* auto-complete css */
.auto-complete-dropdown-cus {
    width: 100%;
    margin-bottom: 10px;
}
.invoice-ddl{
    width: calc(100% - 142px);
}
.auto-complete-dropdown .MuiAutocomplete-root,.auto-complete-dropdown .MuiFormControl-root{
    height: 34px;
    width: 100%;
}
.auto-complete-dropdown-cus .MuiAutocomplete-root, .auto-complete-dropdown-cus .MuiFormControl-root {
    background-color: #fff;
}
.auto-complete-dropdown .MuiFormLabel-root{
    color: #707880;
    font-size: 13px;
    font-weight: 400;
    top: -9px;
    font-family: "Roboto", sans-serif;
}
.auto-complete-dropdown .MuiInputBase-input{
    padding: 0px .75rem !important;
    height: 33.5px;
    /* margin-bottom: 10px; */
    font-size: 13px;
    color: #4e4851;
    background-image: url(../assets/images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 16px;
    padding-right: 50px !important;
    background-color: #ffffff;
}
.disabled-auto-complete-dropdown .MuiInputBase-input{
    padding: 0px .75rem !important;
    height: 33.5px;
    margin-bottom: 0px;
    font-size: 13px;
    color: #4e4851;
    background-image: url(../assets/images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: 16px;
    padding-right: 50px !important;
}
.auto-complete-dropdown .MuiAutocomplete-endAdornment{
    top: 4.5px;
    right: 28px !important;
}
.auto-complete-dropdown .MuiOutlinedInput-notchedOutline{
    height: 33.5px;
    width: 100%;
    top: 0;
    box-shadow: 0 0 0px 1px #efefef;
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.auto-complete-dropdown legend,.MuiInputLabel-shrink{
    display: none !important;
}
.auto-complete-dropdown .MuiOutlinedInput-root{
    padding: 0 !important;
}
.auto-complete-dropdown .MuiButtonBase-root svg{
    width: 16px;
}
.auto-complete-dropdown .MuiAutocomplete-popupIndicator svg{
display: none;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #ced4da !important;
    border-width: 1px !important;
}
.MuiAutocomplete-option,.load-more-text{
    font-size: 13px !important;
    color: #4e4851;
}
.auto-complete-dropdown .MuiButtonBase-root{
    height: 24px;
}

/* ====================== Responsive Design ========================== */
@media(min-width:1900px){
    .room-5,.room-6{
        justify-content: flex-start;
    }
}
@media(max-width:1523px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 157px);
    }
    .right_content {
        height: calc(100vh - 42px);
    }
}
@media(max-width:1395px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 155px);
    }
    .main.show .accordion-custom select.form-control, .main.show .accordion-custom input {
        width: 120px;
    }
    .main.show .chart-center {
        top: 30%;
    }
    .right_content {
        height: calc(100vh - 42px);
    }
    
}
@media(min-width : 1199px) and (max-width:1320px){
    .form-control.white.search-invoice {
        width: 140px;
    }
    .main.show .collapsible-panel .collapsible-panel-left {
        padding: 14px 22px 14px 8px;
    }
    .main.show   .collapsible-panel-left .info-group .count_up {
        margin-right: 18px;
    }
    .main.show   .collapsible-panel-left .info-group .count_up:last-child{
        margin-right: 0px;
    }
    .main.show .collapsible-panel .collapsible-panel-left .me-3.column-count-custom{
        margin-right: 14px !important;
    }
    .main.show .collapsible-panel .collapsible-panel-left .tabular-data p {
        width: 112px;
    }
    .main .tabular-data .custom-width-p-sm {
        width: 99px
    }
    .filter-responsive label
    {
        font-size: 11px;
    }
    .filter-responsive .bed-form-control.select-responsive
    {   
        width: 90px !important;
        font-size: 10px !important;
    }
    .filter-responsive .bed-form-control
    {   
        width: 60px !important;
    }
    .margin-left-md
    {
        margin-left:15px;
    }
    .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition
    {
        min-height: 110px !important;
    }
    .MuiDateCalendar-root {
        width: 220px !important;

    }
}
@media(max-width:1297px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 141px);
    }
    .small_box .card_header > p {
        font-size: 12px;
    }
    .main.show .filter .CircularProgressbar {
        margin-right: 7px !important;
    }

}
@media(max-width:1237px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 141px);
    }
    .chart-container {
        padding: 10px !important;
    }

}
@media(max-width:1224px){
    .referral-overview{
        flex-direction: column;
    }

}
@media(max-width:1211px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 155px);
    }
    .chart-center {
        top: 32%;
    }
    .main.show .chart-center {
        top: 30%;
    }
}
@media(max-width:1145px){
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 140px);
    }
}
@media (max-width: 1199px) {
    .chart-center {
        top: 38%;
    }
    .modal .datepicker-input-css .form-control.white{
        width: 125px;
    }
    .modal .datepicker-input-css .MuiInputBase-input{
        padding: 0 0 0 5px !important;
    }
    .modal .datepicker-input-css .MuiInputLabel-root {
        left: -5px;
    }
    .dropdown-menu-columns {
        right: auto !important;
        left: 0 !important;
    }
    .main.show .more-info .column1,.main.show .more-info .column2 {
        flex: 0 0 auto !important;
        width: 100% !important;
    }
    .main.show .more-info .column1{
        margin-bottom: 20px;
    }
    .lead-text {
        font-size: 14px;
    }
    .accordion-custom .tab-content {
        flex: 5 1;
    }

    .navigation.main {
        width: 300px;
        left: 0;
    }
    .main.show .collapsible-panel{
        flex-wrap: wrap;
    }
    .accordion-custom input,
    .accordion-custom select.form-control {
        width: 120px;
    }
    .filter{
        flex-wrap: wrap;
    }
    .count_up .large-icon svg {
        width: 25px;
    }
    .main.show .info-group .count_up {
        margin-bottom: 0;
    }
    .admission-icon {
        width: 10px;
    }
    .chart-list{
        justify-content: center !important;
    }
    .chart-list li{
        margin-right: 20px;
    }
    .main.show .pending-list .count_up {
        width: 82px;
        padding: 5px !important;
    }


    /* Login css */
    .login-signup-bg {
        height: unset;
    }

    .accordion-button .count_up,
    .prev {
        margin-right: 30px;
    }
    .main.show .pending-list.pending-list-referral {
        grid-template-columns: repeat(auto-fit, 81px);
    }
    .main.show .pending-list.pending-list-referral p{
        font-size: 12px;
    }
    .main.show .pending-list.pending-list-referral .count_up{
        height: 51px;
    }
    .chart-title{
        flex-wrap: wrap;
    }
    .chart-title h3{
        margin-bottom: 10px !important;
    }
}
@media(max-width:1056px){
    .dropdown-submenu-custom .date-filter{
        flex-direction: column;
        gap: 8px;
    }
    .dropdown-submenu-custom{
        height: auto;
        width: 180px;
        height: 168px;
    }
    .dropdown-submenu-custom .datepicker-input-css{
        margin-right: 0 !important;
    }

    .main.show .pending-list.pending-list-referral {
        grid-template-columns: repeat(auto-fit, 73px);
    }
    .main.show .pending-list.pending-list-referral p,.main.show .pending-list.pending-list-referral h3{
        font-size: 11px;
    }
    .main.show .pending-list.pending-list-referral .count_up{
        height: 51px;
        width: 70px;
    }
    .main.show .pending-list.pending-list-referral .count_up svg{
        width: 14px;
    }
    .main.show .legend-container {
        justify-content: start;
    }

}
@media (max-width: 991px) {
    .referral-overview{
        flex-direction: column;
    }
    .main.show .chart-center {
        top: 41%;
    }
    .main.show .legend-container {
        justify-content: center;
    }
    .pending-list.pending-list-referral {
        grid-template-columns: repeat(auto-fit, 105px);
    }
    .box-header {
        height: auto;
    }
    .invoice-table{
        margin-top: 20px;
    }
    .invoice-status li:first-child.active {
        padding: 8px 15px 8px 15px;
    }
    .invoice-status li:first-child{
        padding: 8px 15px 8px 20px;
    }
    .invoice-status li:nth-child(2).active, .invoice-status li:last-child.active{
        padding: 8px 15px 8px 30px;
    }
    .invoice-status li{
        padding: 8px 15px 8px 30px;
    }
    .invoice-status li:nth-child(2).active:after, .invoice-status li:last-child.active:after {
        border-top: 17px solid #ffffff;
        left: -10px;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        top: 8px;
    }
    .invoice-status li {
        border-top: 1px solid #ddd;
    }
    .invoice-status li.active:after {
        right: -27px;
        top: 7px;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        border-top: 20px solid #070044;
    }
    .flex-order-1{
        order: 1;
        flex: 1 1 100%;
        text-align: center;
        margin: 10px 0 10px 0;
    }
    .flex-order-2{
        order: 2;
        flex: 1 1 50%;
    }
    .flex-order-3{
        order:3;
        flex: 1 1 50%;
    }
    .dropdown-menu.dropdown-submenu.dropdown-submenu-custom.show{
        left: -115% !important;
    }
    .dropdown-menu.dropdown-submenu.show {
        left: -100% !important;
    }
    .small_card_group {
        grid-template-columns: repeat(auto-fit, 154px);
    }
    .navigation {
        width: 198px;
    }
    .topbar {
        width: calc(100% - 198px);
        left: 198px;
    }
    .main {
        width: calc(100% - 198px);
        left: 198px;
        padding: 3px;
    }
    .main .more-info .column1,.main .more-info .column2 {
        flex: 0 0 auto !important;
        width: 100% !important;
    }
    .main.show {
        width: calc(100% - 50px);
        left: 50px;
    }
    .main .more-info .column1{
        margin-bottom: 20px;
    }
    .main.show .more-info .column1,.main.show .more-info .column2 {
        flex: 0 0 auto !important;
        width: 100% !important;
    }
    .accordion-custom .tab-content {
        flex: 3;
    }
    .lead-text {
        font-size: 13px;
    }
    .CircularProgressbar.large {
        width: 53px;
    }
    .navigation.main {
        width: 300px;
        left: 0;
    }
    .accordion-custom input,
    .accordion-custom select.form-control {
        width: 120px;
    }

    .count_up .large-iconsvg {
        width: 25px;
    }

    .info-group-left {
        margin-top: 21px;
    }

    .tabular-data {
        justify-content: left;
    }

    .admission-icon {
        width: 10px;
    }

    .pending-list .count_up {
        width: 100px;
        padding: 6px !important;
    }
    .accordion-custom .tab-content {
        height: calc(100vh - 300px );
    }
    .status-box.change-password-box .border-right-box{
        border-right: 0 !important;
        padding-right: 0;
    }


    /* Login css */
    .login-signup-bg {
        height: unset;
    }
    .login-detail {
        height: unset;
        margin-top: 20px;
        width: 100%;
    }
    .accordion-button .count_up,
    .prev {
        margin-right: 30px;
    }
    .chart-container {
        width: 70%;
        margin: 0 auto;
    }

}
@media(max-width:939px){
    .small_card_group{
        grid-gap: 1.5rem;
        justify-content: flex-start;
    }
    .billing-list {
        grid-template-columns: repeat(auto-fit, 48%);
    }
}
@media (max-width: 768px) {

    .chart-center {
        top: 31%;
    }
    .accordion-custom input,
    .accordion-custom select.form-control {
        width: 100px;
    }

    .small_card_group {
        grid-column-gap: 24px;
    }
    .small_box {
        max-width: 170px;
    }
    .count_up p {
        font-size: 12px;
    }
    .tab-list.nav-tabs .nav-link {
        padding: 7px 10px;
    }

}
@media (min-width:768px) and (max-width:900px){
    .collapsible-panel{
        flex-direction: column;
    }
}

@media screen {
	.yourClassName {
		 display: none;
	}
}

.MuiTableCell-body.red-text {
    color: #ff0000 !important;
}

.accordion-button-custom:after{
    margin-left: unset;
}
.tempMUIInput {
    width: 100%;
}

.loading {
    display:inline-block;
    font-size: 15px;
    clip-path: inset(0 1.4ch 0 0);
    animation: l 1s steps(4) infinite;
    color:#E93B59;
  }

  @keyframes l {
    to {
      clip-path: inset(0 -4ch 0 0)
    }
  }

  .costing-input {
    width: 80px !important;
    height: 25px !important;
    display: inline;
    margin-left: 4px;
    font-size: 14px;
  }

  .error-page.nointernet-page
  {
    padding: 48px 35px;
  }

.schedule-assessment-modal .modal-body {
    max-height: 70vh !important;
}
.schedule-assessment-modal .MuiFormControl-root {
    background-color: #ebebeb;
    border-radius: .375rem;
}
.schedule-assessment-modal .multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input {
    height: 28px;
    padding: 6px 0.75rem !important;
    border-radius: .375rem;
}
.schedule-assessment-modal .multiselect-recipients-dropdown .MuiFilledInput-root .MuiFilledInput-input:disabled {
    cursor: not-allowed !important;
}
.schedule-assessment-modal .multiselect-recipients-dropdown .MuiFilledInput-root .MuiAutocomplete-endAdornment {
    right: 44px !important;
    top: 9px;
}
.schedule-assessment-modal .multiselect-recipients-dropdown .MuiInputBase-root.Mui-disabled {
    opacity: 0.38;
}
.schedule-assessment-modal .multiselect-recipients-dropdown .MuiFilledInput-root::before {
    border: none !important;
}
.schedule-assessment-modal .multiselect-recipients-dropdown::after {
    right: 18px;
    top: 12px;
}
.schedule-assessment-modal .form-control {
    background-color: #ebebeb;
    border: none;
    height: 36px;
}
.schedule-assessment-modal .form-control:disabled {
    background-color: #ebebeb !important;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25) !important;
}
.schedule-assessment-modal .label {
    font-size: 14px;
}
.schedule-assessment-modal .attendees {
    height: 100px;
    resize: none;
}
.schedule-assessment-modal .antd-date-picker input::placeholder {
    color: #6c757d;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.schedule-assessment-modal .modal-body .editor {
    height: 200px;
    box-shadow: 0px 0px 2px 1px #ddd;
    border-radius: 8px;
}

.schedule-assessment-modal .modal-body .editor .rdw-editor-toolbar {
    border-radius: 8px 8px 0 0 ;
}

.schedule-assessment-modal .modal-body .editor .rdw-editor-wrapper,
.schedule-assessment-modal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root,
.schedule-assessment-modal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root .DraftEditor-editorContainer
{
    height: 100%;
}

.schedule-assessment-modal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root .DraftEditor-editorContainer .public-DraftStyleDefault-block
{
    margin: 0 !important;
    padding: 6px 0.75rem !important;
}

.schedule-assessment-modal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main{
    height: calc(100% - 48px);
}

.notesModal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main{
    height: calc(100% - 48px);
    padding-left: 10px;
}

.noteTextContainer {
    display: flex;
    margin: 10px 0px;
    box-shadow: 0px 0px 2px 1px #ddd;
    padding: 20px 25px;
    width: 100%;
    word-wrap: break-word;
}

.noteTextContainer .text {
    width: 95%;
}

.noteTextContainer .text .title {
    font-size: 20px;
    font-weight: 500;
}

.noteTextContainer .text .authorDate {
    font-size: 11px;
    color: #a8a6a9;
    font-weight: 500;
}

.noteTextContainer .text .content {
    line-height: 22px;
}

.noteTextContainer .text {
    width: 90%;
    margin-right: 10px;
}

.noteTextContainer .icons {
    width: 10%;
    justify-content: end;
    display: flex;
    align-items: flex-start;
}

.noteiconsize
{
    width: 17px;
}
.notesIcon {
    margin-right: 30px;
    position: relative;
    background: none;
}
.notesIcon img
{
    width: 17px;
}

.notesIcon .badge {
    position: absolute;
    top: -6px;
    left: -9px;
    width: 18px;
    height: 18px;
    border-radius: 12px;
    color: #070044;
    background-color: #fff;
    border: 1px solid #e1e1e1;
}

.notesModal {
    /* width: 90vw !important; */
    max-width: unset !important;
}

.notesModal .modal-body {
    /* height: 80vh !important; */
    max-height: 80vh !important;
}

.notesModal .modal-body .editor {
    height: 300px;
    box-shadow: 0px 0px 2px 1px #ddd;
}

.notesModal .modal-body .editor .rdw-editor-wrapper,
.notesModal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root,
.notesModal .modal-body .editor .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root .DraftEditor-editorContainer
{
    height: 100%;
}

.refferalStagesView-notes-parent {
    background-color: #ebebeb;
    padding: 12px 10px;
    border-radius: 8px;
    word-wrap: break-word;
}

.refferalStagesView-notes-parent > p{
    color: #707880;
    margin-bottom: 4px;
}

.refferalStagesView-notes-parent > .readmore {
    cursor: pointer;
    color: #070044;
    margin-top: 2px;
    font-weight: 400;
}

.note-editor .modal-body{
    min-height: 250px !important;
}

.enabled {
    pointer-events: all;
}
.editor-custom-class .public-DraftEditorPlaceholder-root{
    margin : 10px !important;
}
#penPictureUpload .filepond--list .filepond--item {
    display: none;
}

#penPictureUpload .filepond--wrapper .filepond--drop-label {
    visibility: visible !important;
    transform: unset !important;
    opacity: 1 !important;
    pointer-events: unset !important;
}
.custom-datepicker input {
    padding: 7.1px 10px!important;
    font-size: 13px;
  }
  .custom-datepicker svg {
    font-size: 20px !important;
  }
  
  /*   service link content */
  .service-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
}
.service-link-main {
    width: 80%;
    margin: 0 auto;
    padding: 10px;
}

.service-link-top {
    text-align: center;
}

.service-link-top h3 {
    font-size: 40px;
    font-weight: 700;
}

.service-link-gray {
    background-color: #f5f5f7;
    border-radius: 30px;
    padding: 20px 40px;
}

.rightside-text-linkbox {
    text-align: right;
    padding: 0 30px;
    font-weight: 600;
}

.locate-quotelinkbox {
    width: 85%;
    margin: 0 auto;
    font-family: "Kalam", cursive;
}

.link-details-innercontent {
    padding: 10px 0;
}

.link-details-innercontent ul {
    list-style: none;
    padding-left: 0;
}

.red-text {
    color: #FF0000;
}

.mustard-text {
    color: #FFDB58;
}
.dot-round-red {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #bf1000;
    border-radius: 3px;
}
.dot-round-orange {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #f90;
    border-radius: 3px;
}
.clickable {
    cursor: pointer;
}

  .hand-cursor{
    cursor: pointer;
    /* z-index: 99; */
  }

  img.logo-nhs-header {
    width: 145px;
}

.infobox p i:first-child{
    margin-right: 5px;
}
.swal-text
{
    text-align: center;
    line-height: 20px;
    color: #4e4851;
}
.swal-footer
{
    text-align: center;
}
button.swal-button
{
    background-color: #070044 !important;
    color: #ffffff !important;
    transition: all 0.3s ease-in-out;
    border-radius: 33px;
    width: max-content;
    padding: 10px 35px;
    font-size: 15px;
    transition: 0.1s all ease-in-out;
    outline: none;
}
button.swal-button:focus,
button.swal-button:focus-within{
    outline: none;
    box-shadow: none;
}
img.react-photo-album--photo {
    cursor: pointer;
}


.patient-enquiry-btn {
    position: absolute;
    top: 0;
    /* width: 26px; */
    width: 35px;
    height: 26px;
    color: #fff;
    border-radius: 13px 0 0 13px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
}
.patient-enquiry-close
{
    right: 0;
    left: auto;
    top: 5px;
    z-index: 2;
    border: 0px !important;
}
.patient-enquiry-open
{
    right: 25%;
    top: 5px;
    z-index: 2;
    border: 0px !important;
}
.patient-enquiry-btn i.fa-angle-left
{
    animation: blinkingArr 3s infinite;
    width: 6px;
    /* transform: translateX(10px); */
    animation-delay: 2s;
}
.patient-enquiry-btn i.fa-angle-left:nth-child(2)
{
animation-delay: 1s;
}
.patient-enquiry-btn i.fa-angle-left:nth-child(3)
{
animation-delay: 0s;
}
.patient-enquiry-btn i.fa-angle-right
{
    animation: blinkingArr 3s infinite;
    width: 6px;
    /* transform: translateX(-10px); */
}
.patient-enquiry-btn i.fa-angle-right:nth-child(2)
{
animation-delay: 1s;
}
.patient-enquiry-btn i.fa-angle-right:nth-child(3)
{
animation-delay: 2s;
}
@keyframes goRight {
	0% {
		transform: translateX(-10px);
	}
	100% {
		transform: translateX(35px);
	}
}
@keyframes goLeft {
	0% {
		transform: translateX(10px);
	}
	100% {
		transform: translateX(-35px);
	}
}
@keyframes blinkingArr {
	0% {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
      }
      20% {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
      }
      40% {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
      }
      60% {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
      }
      80% {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
      }
      100% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
      }
}



.service-slider .owl-stage-outer {
    overflow: visible !important;
}
.total-fee {
    display: table;
    background-color: #070044;
    color: #ffffff;
    padding: 0px 10px;
    border-radius: 5px;
    min-width:230px;
}
.other-fee {
    display: table;
    background-color: #ffffff;
    color: #070044;
    padding: 0px 10px;
    min-width: 230px;
    border-radius: 5px;
}
.includednote{
    margin-top: 10px !important;
    color: #070044;
    font-size: 12px;
}

/* .badge {
    position: relative;
     top: -10px;
    right: -10px; 
    padding: 5px 10px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 0.75rem;
  }

  .notification-container {
    position: relative;
    display: inline-block; 
}

.notification-container .fa-bell {
    font-size: 17px;
}

.notification-container .badge {
    position: absolute;
    top: -5px; 
    right: -1px; 
    background-color: red;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 3px 3px;
    font-size: 8px;
}

@keyframes shake {
    0%, 100% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-20deg);
      }
      50% {
        transform: rotate(20deg);
      }
      75% {
        transform: rotate(-20deg);
      }
  }

.shake {
    animation: shake 1s linear infinite ;
} */

  
/* Notification Icon  */


.bell-icon,.bell-icon-without-animation {
    position: relative;
    width: 50px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
 }

  .bell-icon svg , .bell-icon-without-animation svg {
    margin: auto;
    position: relative;
    right: 2%;
    width: 80%;
    height: 80%;
    stroke: rgba(0, 0, 0, 0.75);
 }
 /* .bell-icon .bell-icon__group */
 .bell-icon .bell-icon__group {
    transform-origin: 50% 2px;
    transform: rotate(0deg);
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation: animateGroup 2.3s infinite;
 }
 .bell-icon .bell-icon__ball {
    transform-origin: 50% 2px;
    transform: translateX(0);
    animation-fill-mode: both;
    animation-iteration-count: 3;
    animation-timing-function: ease-in-out;
    animation: animateBall 2.3s infinite;
    animation: hideShow 1s;
 }
  .bell-icon:focus, .bell-icon:hover {
    outline: none;
 }
 /* .bell-icon:hover .bell-icon__group {
    animation: animateGroup 2.3s;
 } */
  /* .bell-icon:focus .bell-icon__ball, .bell-icon:hover .bell-icon__ball {
    animation: animateBall 2.3s;
 }
  .bell-icon:focus .notification-amount, .bell-icon:hover .notification-amount {
    opacity: 1;
    visibility: visible;
    animation: straightLine 1s linear;
 } */
  .bell-icon:focus .notification-amount span, .bell-icon .notification-amount span {
    animation: hideShow 1s;
 }
  /* .bell-icon:focus .notification-amount::before, .bell-icon .notification-amount::before {
    animation-name: ripple;
    animation-delay: 1s;
 } */
  .notification-amount {
    position: absolute;
    top: 0%;
    right: 19%;
    width: 18px;
    height: 18px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    background-color: #070044;
    opacity: 1;
    visibility: visible;
    animation: straightLine 2s linear;
 }
 .notification-amount span.second {
    position: absolute;
    top: 15%;
    right: 25%;
    width: 11px;
    height: 11px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Copse", serif;
    font-size: 14px;
    background-color: #099ffd;
    opacity: 0;
    visibility: visible;
    animation: straightLine1 1s linear;
    animation-delay: 0.2s;
  }
 .without-notification-amount
 {
    position: absolute;
    top: 0%;
    right: 19%;
    width: 18px;
    height: 18px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    background-color: #070044;
 }
 .notification-amount span.second.two {
    top: 7%;
    right: 20%;
    animation-delay: 0.35s;
  }

  .notification-amount span.second.three {
    top: 4%;
    right: 15%;
    animation-delay: 0.5s;
  }

  .notification-amount span.second.four {
    top: 1%;
    right: 10%;
    animation-delay: 0.65s;
  }

  .notification-amount span.second.five {
    top: -2%;
    right: 5%;
    animation-delay: 0.8s;
  }
  .notification-amount span {
    position: relative;
 }
 .notification-amount::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #070044;
    border-radius: 50%;
    z-index: 0;
    transform: scale(0);
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-name: ripple;
    animation-delay:2s;
  }
  .iqvia-disabled {
    pointer-events: none;
    opacity: 0.5;
  } 
 @keyframes animateGroup {

    0%,
    100% {
      transform: rotate(0deg);
    }

    17.542% {
      transform: rotate(0deg);
    }

    35.084% {
      transform: rotate(-20deg);
    }

    48.2405% {
      transform: rotate(20deg);
    }

    57.0115% {
      transform: rotate(-20deg);
    }

    64.9054% {
      transform: rotate(8deg);
    }

    74.5535% {
      transform: rotate(-15deg);
    }

    78.939% {
      transform: rotate(-7deg);
    }
  }

  @keyframes animateBall {

    0%,
    100% {
      transform: translateX(0);
    }

    17.542% {
      transform: translateX(0%);
    }

    21.9275% {
      transform: translateX(-1%);
    }

    35.084% {
      transform: translateX(11%);
    }

    48.2405% {
      transform: translateX(-11%);
    }

    52.626% {
      transform: translateX(0%);
    }

    59.6428% {
      transform: translateX(10%);
    }

    68.4138% {
      transform: translateX(-11%);
    }

    78.939% {
      transform: translateX(11%);
    }

    85.9558% {
      transform: translateX(-11%);
    }
  }

  @keyframes straightLine {
    33% {
      transform: translate3D(-25px, 15px, 0);
      opacity: 1;
      width: 11px;
      height: 11px;
      background-color: #099ffd;
    }

    66% {
      transform: translate3D(10px, -5px, 0);
      opacity: 1;
      z-index: -1;
      width: 11px;
      height: 11px;
      background-color: #099ffd;
    }

    100% {
      transform: translate3D(0, 0, 0);
      opacity: 1;
      width: 18px;
      height: 18px;
      background-color: #070044;
    }
  }

  @keyframes straightLine1 {
    20% {
      opacity: 1;
    }

    33% {
      transform: translate3D(-25px, 15px, 0);
      opacity: 1;
      width: 10px;
      height: 10px;
    }

    55% {
      opacity: 0;
    }

    66% {
      transform: translate3D(10px, -5px, 0);
      opacity: 1;
      z-index: -1;
      width: 10px;
      height: 10px;
      opacity: 0;
    }

    100% {
      transform: translate3D(0, 0, 0);
      /* / opacity: 1; / */
      width: 18px;
      height: 18px;
    }
  }

  @keyframes ripple {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.3);
      opacity: 1;
    }

    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }

  @keyframes hideShow {
    0% {
      transform: scale(0);
    }

    99% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  .add-comments
  {
  position: absolute;
  top: 50%;
  left: 70%;
  font-size: 12px;
  color: #070044;
  text-decoration: underline;
  transform: translate(-50%,-50%);
  }
  .add-comments-SM
  {
  position: absolute;
  top: 50%;
  right: 26px;
  font-size: 12px;
  color: #070044;
  text-decoration: underline;
  transform: translate(-50%,-50%);
  }

.status-icon.disabled-btn {
    opacity: 0.6;
}  /* Hot Fixes - 19-09-2024 */
.nhs-body .top-header .dropdown-item i,
.nhs-body .top-header .dropdown-item svg {
    font-size: 16px;
    width: 20px;
}
  .nhs-funder-icon-width
{
    width: 65px;
}
.referral-wrapper .service-title
{
    font-size: 18px !important;
}
.question-answer-accordion {
    min-height: 300px;
}
.nhs-body .patient-tabs .nav-tabs .nav-link:hover {
    border: none;
}
.nhs-body .patient-tabs .nav-tabs .nav-link {
    border: none;
}
.nhs-sidebar .right_content-height-tab .auto-complete-dropdown-cus .MuiAutocomplete-root, .nhs-sidebar .right_content-height-tab .auto-complete-dropdown-cus .MuiFormControl-root {
    border-radius: 4px;
    overflow: hidden;
}
.nhs-sidebar .status-box
{
    margin-bottom: 0;
}
.nhs-body .right_content-height-tab .tab-custom-height {
    height: calc(100vh - 360px);
}

/* sidebar changes */
.nhs-main-left
{
    width: calc(100% - 350px);
}
.nhs-sidebar-width
{
    width: 350px;
}
.patient-enquiry-open
{
    right: 350px;
}
.nhs-body .nhs-sidebar, .nhs-body .saveforlater
{
    background-color: #fff !important;
}
.nhs-body .nhs-accordion .accordion-item.white
{
    background: #ffffff !important;
}

.right_content .right_content-height-tab .tab-custom-height
{
    height: calc(100vh - 263px);
    padding-bottom: 20px;
}

.react-select-custom-cross-indicator
{
    padding: 0 !important;
    margin-top:1%;
}
.react-select-custom-down-indicator {
    padding: 0 !important;
    padding-right: 10px !important;
    padding-left: 9px !important;
    cursor: text;
}
.react-select-custom-cross
{
    font-size:16px !important;
    color: rgba(0, 0, 0, 0.54) !important;
}
.react-select-custom-down {
    width: 11px !important;
    fill: rgb(0 0 0 / 68%) !important;
}
.react-select-custom-tag{
    font-size:16px !important;
    color: rgba(0, 0, 0, 0.54) !important;
    border-radius: 2px;
}
.selectbox .css-12a83d4-MultiValueRemove:hover svg
{
    fill: #E93B59;
}
.react-select-custom-cross-indicator:hover
{
    color: rgba(0, 0, 0, 0.54) !important;
}
.referral-height input::placeholder
{
    font-size: 13px !important;
}
.referral-height textarea::placeholder
{
    font-size: 13px !important;
}
.react-select-custom-cross-indicator i {
    color: rgba(0, 0, 0, 0.44) !important;
    margin-right: 3px;
    font-size: 15px;
    display: none;
}
.react-select-multi-select:hover .react-select-custom-cross-indicator i {
    display: block !important;
}

.padding-top-100{
    padding-top: 100px !important;
}
 
.remove-disable-bg:disabled {
    background-color: white !important;
}
.Mui-disabled.remove-disable-bg {
    background-color: white !important;
}

.planchange-reason::placeholder {
    color: #ccc9cc;
}
/* Sprint 19 */
.administrator-content
  {
      padding:15px;
  }
  .onboarding-servicebuilder {
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 20px;
    color: #000000;
    font-weight: 500;
}
.trans-button
{
    border: none;
    border-radius: 6px;
    font-size: 13px;
    padding: 5px;
    background-color:#ffffff54;
    color: #fff;
}
.servicebuilder-textarea
{
    min-height: unset !important;
}
.servicecardsecbuilder.nhs-body{
    overflow-x: unset !important;
}
.r-small-btn
{
    border: 1px solid transparent;
    outline: none;
    color: #fff;
    background: #070044;
    border-radius: 6px;
    padding: 5px 20px;
}
.border-radius-8
{
    border-radius: 8px;
}
.administrator-header{
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;    
}
.administrator-content
{
    padding:15px;
}
.grid-switch
{
  width: 40px;
  box-shadow: none;
}
.grid-switch .switch-custom .slider
{
    background-color: #4e4851;
}
.grid-switch .switch-custom input:checked+.slider
{
    background-color: #047225 !important;
}


.activeStatus
{
    color: #00c462;
}

/* Reports CSS */
.reports-box
{
    border-radius: 6px;
    width: 100%;
    background: #fff;
    min-height: 118px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    border: 1px solid #f2f2f2;
    height: 100%;
}
.report-icon
{
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border-radius: 100%;
  margin: 0 auto;
  padding: 25px;
}
.report-box-inn
  {
      padding: 25px;
      text-align: center;
  }
  .report-box-inn p{
    font-size: 14px;
    font-weight: 700;
    min-height: 40px;
  }
  .administrator-header{
      border-bottom: 1px solid #ddd;
      padding: 10px 15px;    
  }
  .administrator-content
  {
      padding:15px;
  }
   
  .dropdown-menu-columns.report-dropdownmenu
  {
      width: 425px;
  }
  .reports-table tr th:first-child,
  .reports-table tr td:first-child{
      padding-left: 25px !important;
  }
  .report-modal-heading
  {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
  }

  .admin-modal-header
{
  padding: 15px 20px ;
  border-bottom: 1px solid #ddd;
}
.admin-modal-body
{
  padding: 20px;
}
.userAdminModal
{
  max-width: 95%;
}
.user-details-table td
{
  padding-right: 20px;
  padding-bottom: 10px;
}
.strong-font
{
  font-weight: 600;
}
.primary-color,
.primary-color:hover
{
  color: #070044;
}
.service-cards-sticky
{
    position: sticky;
    top: calc(42px - 0px);
    background-color: #ffffff;
    z-index: 99;
    padding: 10px 0;
}


/* App.css */
.dot-pulse {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    background-color: #fff;
    border-radius: 50%;
  }
  .description-item .generatedText
  {
    font-style: italic;
    padding: 0 5px;
    background: transparent;
    resize: none;
    color: #fff;
    border: none;
    min-height: 200px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .modal.exportreportModal {
    max-width: 430px;
}

select.form-control
{
    font-size: 13px;
}
@media (max-width: 1400px) {
    .report-cards .col-xl-2 {
        width: 25%;
    }
    .report-box-inn
    {
        padding: 20px;
    }
}
.table-green-color
{
    color: #14804A;
}
.table-red-color
{
    color: #ff0000;
}

.multiselectDropdown
{
    width: 100%;
}
.report-thirdcolumns-width {
    width: 850px !important;
    transform: translate(0px, 32px) !important;
}
.userHeader.modal-title-header
{
    border-radius: 6px 6px 0 0;
}
.userHeader h3{
    font-size: 13px;
}
.box-border
{
    border: 1px solid #dee2e6;
    border-radius: 6px;
    height: 100%;
}
.userContentCols .auto-complete-dropdown-cus {
    margin-bottom: 0;
}
.services-list-table .MuiPaper-root {
    box-shadow: none;
}
.services-list-table .MuiTable-root {
    border: 1px solid #e8e8e8;
    border-collapse:collapse;
}
.services-list-table .MuiTableCell-root
{
    border-right: 1px solid #e8e8e8;
    /* text-align: center; */
}
.status-toggle .slider {
    background-color: #017100;
    border: 1px solid #017100 !important;
}
.sdfds
{
    white-space:pre-wrap;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield ;
}
.font13 {
    font-size: 13px !important;
}

.export-popup-mui .MuiAutocomplete-root{}
.export-popup-mui .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    max-height: 70px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.export-popup-mui .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root fieldset{
    border: none;
}
.vertical-bottom
{
    vertical-align: bottom;
}
.modal-550
{
    max-width: 550px;
}
.floating-field {
    position: relative;
    display: inline-block;
  }
    
  .floating-form-label {
    padding: 0.490rem .75rem;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
    font-size: 13px;
    color: #4e4851;
    font-weight: 400;
  }
  .floating-field input:focus + label, .floating-field input:not(:placeholder-shown) + label{
    opacity:1;
    transform: scale(0.75) translateY(-65%) translateX(-5px);
    background-color: #fff;
    font-size: 14px;
    color: #4e4851;
    padding: 0 10px;
    z-index: 1;
  }
  .floating-field textarea:focus + label, .floating-field textarea:not(:placeholder-shown) + label{
    opacity:1;
    transform: scale(0.75) translateY(-65%) translateX(-5px);
    background-color: #fff;
    font-size: 14px;
    color: #4e4851;
    padding: 0 10px;
    z-index: 1;
  }
  
  /* For IE Browsers*/
  .floating-field  input:focus + label, .floating-field input:not(:-ms-input-placeholder) + label{
    opacity:1;
    transform: scale(0.75) translateY(-65%) translateX(-5px);
    background-color: #fff;
    font-size: 14px;
    color: #4e4851;
    padding: 0 10px;
    z-index: 1;
  }
  .floating-field textarea:focus + label, .floating-field textarea:not(:-ms-input-placeholder) + label{
    opacity:1;
    transform: scale(0.75) translateY(-65%) translateX(-5px);
    background-color: #fff;
    font-size: 14px;
    color: #4e4851;
    padding: 0 10px;
    z-index: 1;
  }
  .floating-field textarea
  {
    scrollbar-gutter: stable;
  }
  .margin-left-right-100
  {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .grey-span-box{
    border-radius: 6px;
    font-size: 13px;
    color: #4e4851;
    box-shadow: 0 0 0px 1px #efefef;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    background-color: #e9e9e9;
    opacity: 1;
    width: calc(100% - 142px);
  }

.nhs-body .welcome-action-column .btn-white{
    color: #4E4851;
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding: 4px 7px;
    white-space: nowrap;
    background-color: #fff;
    font-size: 13px;
}
.nhs-body .welcome-action-column .btn-white:hover{
    background-color: #070044 !important;
    border-color: #070044 !important;
    color: #fff !important;
}
.nhs-body .welcome-action-column .dropdown-menu{
    overflow-y: visible;
}

.nhs-body .service-detail-padding.stickey-header-section.is-sticky {
    background: #070044;
}
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list,
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a,
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a.active{
    background: #070044 !important;
    color: #fff !important;
}
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a
{
    border-bottom:0;
}
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a:after
{
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
}
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a:hover::after,
.nhs-body .service-detail-padding.stickey-header-section.is-sticky .tab-list a.active::after
{
    width: 100%;
    transition: width .3s;
}
.nhs-body .servicename-stickey-bar
{
    padding: 23px 0;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
}
.nhs-body h5.list-title
{
    font-weight: 300;
}
.nhs-body .list-title span.medium-text
{
    font-weight: 500;
}

div#question-answer-section
  {
    min-height: 100vh;
    padding-top: 100px !important;
  }