.lightblue {
    color: #00a2ff !important;
}

.orange-bg {
    background-color: #f27200;
    color: #ffffff;
}

.green-text {
    color: #1db100;
}

.getlocated-body .red-text {
    color: #a62b17;
}

.grey {
    color: #4e4851;
}

.grey-bg {
    background-color: #f5f5f7;
}

.underline {
    text-decoration: underline;
}

.text-small {
    font-size: 12px !important;
}

.normal {
    font-weight: 400 !important;
}

.section-text-small {
    font-size: 18px;
}

.justify-right {
    justify-content: flex-end;
}

.ml-25 {
    margin-left: 25%;
}

.margin-top-30 {
    margin-top: 30px;
}
.height-60
{
    height: 60px;
}
.margin-top-15
{
    margin-top: 15px;
}
.lightblue-bg
{
    background: #00a1ff !important;
}

.classic-btn {
    border: 1px solid transparent;
    outline: none;
    color: #fff;
    background: #070044;
    border-radius: 6px;
    padding: 7px 40px !important;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
}

.classic-btn.white-classic-btn {
    background: #fff !important;
    border: 1px solid #070044 !important;
    color: #070044 !important;
    box-shadow: 0px 0 3px 1px rgb(0 0 0 / 47%);
}

.classic-btn.change-btn {
    padding: 4px 20px !important;
    font-size: 14px !important;
}

.classic-btn.light-blue-classic-btn {
    background: #00a1ff !important;
    font-weight: 300;
    height: 38px;
}

.classic-btn.submit-btn {
    padding: 7px 27px;
}

.classic-btn.small-btn {
    padding: 8px 20px !important;
    font-size: 14px;
    border-radius: 30px;
    font-weight: 500;
}

.classic-btn.back-btn {
    background: none !important;
    box-shadow: none !important;
    color: #070044 !important;
    border: 1px solid #070044;
}

.classic-btn.back-btn:hover,
.classic-btn.back-btn:active,
.classic-btn.back-btn:focus {
    color: #000 !important;
    border-color: #000;
}

.classic-btn.black-btn {
    background: #4e4851;
}

.classic-btn.black-btn:hover {
    background-color: #4e4851;
}

/* .classic-btn:hover {
    color: #fff;
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.2392156863);
    transition: all 0.3s ease-in-out;
    background-color: #070044;
} */

.classic-btn:focus {
    border: 1px solid #000;
}

.classic-card {
    background: #fff;
    border-radius: 8px;
    padding: 40px;
    transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    position: relative;
}

.classic-card:not(.no-hover):hover {
    transform: scale(1.0161290323);
}

.custom-select {
    display: inline-block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #44476a;
    vertical-align: middle;
    background: url(../assets/images/select-arrows.svg) no-repeat right 0.75rem center / 8px 10px;
    background-color: #fff;
    border: 0.0625rem solid #dee2e6;
    border-radius: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.blue-small-text {
    color: #31b4ff !important;
    font-size: 14px;
}

.getlocated-body .small-text {
    font-size: 14px;
    color: #919191;
}

.section-sub-title {
    letter-spacing: 0;
}

.section-sub-title.mb-4 {
    margin-bottom: 1rem !important;
}

.font-medium {
    font-weight: 500;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    cursor: pointer;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #d1d9e6;
    box-shadow: none;
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #e6e7ee;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    padding: 0.375rem 0.75rem;
    font-weight: 300;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 0.0625rem solid #d1d9e6;
    border-radius: 0.55rem;
    cursor: pointer;
}

.custom-file-label {
    border: 0.0625rem solid #d1d9e6;
    font-size: 1rem;
    color: #44476a;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #44476a;
    content: "Browse";
    border-left: inherit;
    border-radius: 0 0.55rem 0.55rem 0;
    height: 100%;
    background-color: #4e4851;
    color: #ecf0f3;
    border: 0;
}

.getlocated-body {
    padding: 35px 80px;
    margin-top: 97px;
    min-height: calc(100vh - 97px);
}

/* .onboarding-bg-gray {
    background-color: #fafafa;
} */

.getLocated-header {
    padding: 20px 0 10px;
    background-color: #20224a;
}

.link-heads {
    list-style: none;
    margin: 0;
    justify-content: flex-end;
}

.link-heads li {
    padding: 0 20px;
    color: white;
    font-size: 12px;
}

.link-heads li:last-child {
    padding-right: 0;
}

.head-btn {
    height: 39px;
    width: 39px;
    border-radius: 50px !important;
    padding: 0 !important;
    margin-right: 15px;
    margin: 5px 15px 5px 0;
}

.head-btn img {
    height: 17px;
    width: 17px;
}

.head-btn.white-btn {
    background-color: #e6e7ee !important;
    height: 50px;
    width: 50px;
    margin: 0;
    box-shadow: inset 2px 2px 3px #c3c4c8, inset -2px -2px 3px #fff;
}

.head-btn.white-btn:hover,
.head-btn.white-btn:focus,
.head-btn.white-btn:active {
    box-shadow: 3px 3px 16px #0c0e1e, -3px -3px 16px #c8cdff ad;
}

.head-btn.white-btn.admin-btn img {
    width: 10px;
    height: auto;
}

.getlocated-section.custon-padding {
    padding: 0 120px;
}

.getlocated-section h5.section-title {
    font-weight: 600;
    background-color: #ebebeb;
    border-radius: 10px;
    padding: 25px;
    /* font-size: 35px; */
}

.gradient-text {
    background: #000;
    background: linear-gradient(to right, #ff1493, #7ec4dd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.getlocated-section .section-description {
    padding-left: 25px;
    width: 100%;
}

.section-description .head-title-left {
    width: calc(100% - 200px);
}

.getlocated-section .section-description p {
    font-weight: 400;
    /* padding-right: 50px; */
}

.section-description .head-btns-right {
    width: 160px;
}

.cards-section {
    margin: 40px 0px 0;
}

a.cards-links {
    color: inherit;
    display: block;
    margin: 0 25px;
}

a.cards-links p {
    font-size: 20px;
}

a.cards-links:hover p {
    color: #000;
}

.section-description.provider-desc p {
    letter-spacing: 0.13px;
    margin-bottom: 20px !important;
}

.onboard-form {
    margin-top: 20px;
}

.neumorphic-form .input-group .form-control::placeholder {
    color: #000 !important;
    font-size: 14px;
}

.search-btn-group.input-group>.input-group-append>.btn {
    background: #070044;
    color: #fff;
    padding: 8px 14px;
}

.search-btn-group input {
    outline: none;
}

.search-btn-group input:focus {
    outline: 0;
    box-shadow: none;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.group-switch a {
    color: inherit;
}

.or-section {
    margin: 11px 0;
    text-align: center;
    position: relative;
}

.or-section span {
    background: #fafafa;
    padding: 0px 20px;
    position: relative;
    font-size: 18px;
}

.or-section::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    background: #919191;
    top: calc(50% - 1px);
}

.map-part p {
    font-size: 18px;
}

.map-part p.light-blue-text {
    color: #31b4ff;
}

.map-part .map-card {
    margin: 25px 0;
    border-radius: 15px;
    overflow: hidden;
    height: 400px;
}

.map-part .map-card img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.click-to-claim {
    font-size: 18px;
}

.verification-steps .classic-btn {
    min-width: 200px;
    font-weight: 300;
    height: 38px;
}

.verification-hr {
    margin: 20px 0;
}

.verification-steps p {
    color: #5e5e5e;
    font-weight: 500;
}

.profile-card {
    padding: 25px;
}

.box-details h3 {
    color: #191919;
    font-size: 1.75rem;
}

.box-details h6 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 16px;
    margin-bottom: 5px;
}

.img-height-center {
    height: 485px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.custom-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
}

.aboutPlaceContent {
    padding-right: 40px;
}

.content-alignment {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.neumorphicOrderList {
    height: 25px;
    width: 25px;
    border: 1px solid;
    border-radius: 50px !important;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
}

.orderListBig {
    height: 30px;
    width: 30px;
    font-size: 16px;
    line-height: 30px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    display: inline-block;
    background-color: #ffffff;
}

.steps-headline {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.steps-headline h4 {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    justify-items: center;
}

.steps-headline h4 .neumorphicOrderList {
    margin: 5px 0;
}

.steps-headline p {
    text-align: left;
    font-size: 14px;
}

.steps-description ol {
    padding-left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.steps-description ol li {
    margin-bottom: 7px;
    font-size: 16px;
}

.light-blue-text {
    color: #31b4ff;
}

.facility-card {
    padding: 20px;
    margin: 0 20px;
    width: 20%;
    text-align: center;
}

.facility-card:hover,
.facility-card:active,
.facility-card:focus,
.facility-card.selected-facility {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s ease;
}

.facility-card p {
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 15px !important;
}

.pagination-neumorphic {
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-weight: 600;
    padding: 5px;
    border: 1px solid #070044 !important;
    color: #070044;
    background-color: transparent;
    text-align: center;
    /* margin-right: 10px; */
    background-color: #fff;
    transition: all 0.3s ease;
}

.pagination-neumorphic:not(input):hover {
    transition: all 0.3s ease;
    background-color: #070044;
    color: #fff;
}


.pagination-neumorphic button:disabled {
    pointer-events: none;
}

.pagination-neumorphic.active {
    background-color: #070044;
    color: #fff;
}

.pagination-neumorphic::placeholder {
    color: #070044 !important;
    font-weight: 600;
    font-size: 15px;
}

.steps-heading-button-right {
    position: absolute;
    top: 0;
    right: 0;
}

.facility-cards-parent {
    margin: 0 -10px;
}

.facility-cards-parent .facility-card {
    padding: 40px 20px;
    margin: 0 10px;
    margin-bottom: 20px;
    width: calc(25% - 20px);
    text-align: center;
    position: relative;
    border-radius: 15px;
}

.facility-cards-parent .facility-card .selection-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.facility-cards-parent .facility-card .infoIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.facility-cards-parent .facility-card .selectedButton {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    opacity: 0;
}

.facility-cards-parent .facility-card .selection-box:checked+.selectedButton {
    opacity: 1;
}

.pb-115 {
    padding-bottom: 115px;
}

.stepfooter {
    background-color: #fafafa;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.step-progress {
    height: 5px;
    background: #ddd;
    position: relative;
}

.step-progress:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: #000;
}

.step-progress.step0:before {
    width: 0;
}

.step-progress.step10:before {
    width: 10%;
}

.step-progress.step20:before {
    width: 20%;
}

.step-progress.step30:before {
    width: 30%;
}

.step-progress.step40:before {
    width: 40%;
}

.stepbuttons {
    justify-content: space-between;
    padding: 16px 0;
}

.neumorphic-fieldset {
    display: flex;
    flex-direction: column;
    position: relative;
}

.neumorphic-fieldset label {
    margin-left: 10px;
    z-index: 9;
}

.neumorphic-fieldset label .label-style {
    background: #fafafa;
    font-weight: 400;
    padding: 0 7px;
    font-size: 12px;
}

.neumorphic-fieldset input,
.neumorphic-fieldset textarea {
    font-size: 1rem;
    background: transparent;
    margin-top: -10px;
    border-radius: .375rem;
    padding-top: 10px;
}

.neumorphic-fieldset select.custom-select {
    font-size: 1rem;
    border-radius: 0.55rem;
    margin-top: -10px;
    border-radius: .375rem;
    background-color: transparent;
}

.neumorphic-fieldset input:focus,
.neumorphic-fieldset textarea:focus {
    background-color: #fff;
    box-shadow: none;
}

.fieldset-fixed-text {
    position: absolute;
    right: 14px;
    top: calc(50% - 5px);
    z-index: 9;
    color: #cacccf;
    width: 20px;
}
.is-invalid ~ .fieldset-fixed-text
{
    right: 30px;
}

.fieldset-fixed-padding-right {
    padding-right: 40px;
}

.ward-card {
    padding: 10px 0;
}

.wards-heading h4 {
    font-size: 20px;
}

.wards-heading h4 i {
    font-size: 20px;
    line-height: 24px;
}

.editRoomName {
    cursor: pointer;
}

.ward-card-h5 {
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 18px;
}

.ward-allocations {
    padding: 20px 10px 0;
}

.ward-selection {
    height: 180px;
    overflow-x: hidden;
}

.add-amenities-btn {
    padding: 3px 20px !important;
    width: 100%;
    font-size: 14px !important;
}

.ward-selection ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.ward-selection ul li {
    width: 33px;
    height: 33px;
    border: 1px dotted #00a2ff;
    border-radius: 6px;
    list-style: none;
    padding: 0;
    margin-bottom: 3px;
    display: inline-block;
    margin: 3px;
    display: flex;
}

.ward-selection ul li.hasItem {
    border: 1px solid #000;
}

.ward-selection ul li .selected-amenity {
    display: table;
    height: 100%;
    width: 100%;
}

.ward-selection ul li .selected-amenity .selected-amenity-icon {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 3px;
}

.addWardButton {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: -15px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    line-height: 0;
}

.nameaddWardButton {
    right: 15px;
    top: 50%;
    /* position: unset; */
}

.addWardButton .blue-bg {
    padding: 0 !important;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-ward {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    /* margin-bottom: 10px; */
}

.delete-button {
    background: #c4314b !important;
    padding: 0px !important;
    height: 25px;
    width: 30px;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    line-height: 0;
}

.modal.addAminitiesToWard-popup {
    width: 40%;
    padding: 30px;
}

.modal.addAminitiesToWard-popup .small-text {
    font-size: 13px;
}

.modal.addAminitiesToWard-popup .header h4 {
    color: #00a2ff;
    font-weight: 600;
    font-size: 25px;
}

.modal.addAminitiesToWard-popup .popup-subtitle {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.modal.addAminitiesToWard-popup .amenities-box-container>div.row {
    margin: 0 -6px !important;
}

.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 {
    margin-bottom: 12px;
    padding: 0 6px;
}

.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 .classic-card {
    padding: 0;
    background-color: #fff;
    margin: 0;
    height: 100%;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #dee2e6;
}

.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 .classic-card.checked-card {
    border: 1px solid #4e4851;
}

.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 .classic-card:hover,
.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 .classic-card:active,
.modal.addAminitiesToWard-popup .amenities-box-container div.col-md-4 .classic-card:focus {
    border-color: #4e4851;
    transition: all ease 0.3;
}

.modal.addAminitiesToWard-popup .applytoallrooms {
    justify-content: space-between;
}

.amenities-modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.amenities-modal-body::-webkit-scrollbar {
    width: 5px;
}

.amenities-modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.amenities-modal-body::-webkit-scrollbar-thumb {
    background: #888;
}

.amenities-modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.amenities-card-content {
    position: relative;
    padding: 13px;
}

.amenities-card-content p {
    font-size: 13px;
}

.amenities-card-content>img {
    width: 26px;
}

.amenities-card-content .amenity-selection-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.amenities-card-content .amenitySelectedButton {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 18px;
    line-height: 18px;
    color: #4e4851;
    opacity: 0;
}

.amenities-card-content .amenity-selection-box:checked+.amenitySelectedButton {
    opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.fieldset-fixed-margin-right {
    margin-right: -22px;
}

.fieldset-fixed-margin-left {
    margin-left: -15px;
}

.small-content-center {
    height: calc(100vh - 310px);
}

.neumorphic-label {
    font-size: 1rem !important;
    border-radius: 0.55rem;
    background: transparent;
    box-shadow: inset 2px 2px 4px #b8b9be, inset -3px -3px 4px #fff;
    padding: 0.375rem 0.75rem;
}

.text-justify {
    text-align: justify;
}

.applyallswitch {
    width: 30%;
}

.gallery-header {
    margin: 0 15px;
}

.gallery-header .select-multiple {
    justify-content: flex-end;
}

.gallery-header .select-multiple .select-btn {
    padding: 5px 15px !important;
}

.gallery-header .select-multiple .select-icon img {
    width: 18px;
}

.gallery-item .gallery-image-collage>.row {
    margin: 0 10px;
}

.gallery-item .gallery-image-collage .col-md-3,
.gallery-item .gallery-image-collage .col-md-6 {
    padding: 0 5px;
}

.gallery-item .gallery-image-block {
    height: 200px;
    padding: 0;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    background-color: #ccc;
    margin-bottom: 10px;
}

.gallery-item .gallery-image-block.dotted-border {
    border: 2px dotted #000;
}

.gallery-item .gallery-image-block.big-collage-img {
    height: calc(100% - 10px);
}

.gallery-item .gallery-image-block input.fileInput {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.gallery-item .gallery-image-block .default-img img {
    max-width: 50px;
}

.bg-image
{
    background-image: url("../assets/images/drag-n-drop.svg");
    background-repeat: no-repeat;
    background-position: center;
}

/* .profileInfo {
    padding: 0 15%;
} */
/* .profileInfoChild .classic-card {
    padding: 30px;
    width: 100%;
} */
.profileInfo {
    margin-top: 50px;
}

.profileInfo .row {
    /* margin: 0 -5px; */
}

.profileInfo .col-md-4,
.profileInfo .col-md-8,
.profileInfo .col-md-6,
.profileInfo .col-md-10,
.profileInfo .col-md-2 {
    /* padding: 0 5px; */
}

.info-text-p {
    font-size: 15px;
}

.profileInfoChild .sec-frame {
    width: 100%;
}

.profileinfoblock {
    padding: 20px;
}

.profileInfoChild .classic-card .neumorphic-fieldset label .label-style {
    background: #fff;
}

.profileInfoChild .neumorphicOrderList {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 60%);
}

.profilepic-upload h6 {
    font-weight: 700;
}

.profile-pic {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    vertical-align: middle;
    text-align: center;
    transition: all 0.3s ease;
    text-decoration: none;
    cursor: pointer;
    background-color: #d5d5d5;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.profile-pic img {
    /* border-radius: 50%; */
}

.profile-pic span {
    display: inline-block;
    padding-top: 4.5em;
    padding-bottom: 4.5em;
}

.profile-pic input[type="file"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.daily-fields .form-control {
    padding: 1px 7px;
}

.robot-animation {
    margin: 0 10px;
}

.serviceofferbox,
.accreditationsbox {
    padding: 20px;
    position: relative;
}

.serviceofferbox-text {
    min-height: 100px;
}

.neumorphic-form .custom-switch .custom-control-label.disabled::before {
    background-color: #e3e3e3;
}

.cover-pic {
    position: absolute;
    left: 15px;
    bottom: 15px;
}

.cover-pic .coverpicBtn {
    box-shadow: 3px 3px 8px #e6e7ee d4, -3px -3px 8px #000 c4 !important;
}

.cover-pic .coverpicBtn.gallery-btn-shadow {
    box-shadow: 3px 3px 8px rgba(230, 231, 238, 0.8), -3px -3px 8px #000 !important;
}

.cover-pic .coverpicBtn.gallery-btn-shadow:hover,
.cover-pic .coverpicBtn.gallery-btn-shadow:focus {
    box-shadow: inset 3px 3px 5px #b8b9be, inset -2px -2px 7px #fff !important;
}

.see-allpics {
    position: absolute;
    right: 15px;
    bottom: 35px;
    width: 167px;
}

.see-allpics .seeAllPicsBtn {
    box-shadow: 3px 3px 8px #e6e7ee d4, -3px -3px 8px #000 c4 !important;
}

.more-options {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 26px;
    line-height: 26px;
    cursor: pointer;
}

.more-options .more-options-container {
    position: absolute;
    bottom: 0;
    right: 10px;
    background-color: #fff;
    width: 150px;
    padding: 10px;
    border-radius: 16px;
    display: none;
}

.more-options .more-options-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100px;
}

.more-options .more-options-container ul li {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 0;
}

.more-options .more-options-container ul li a {
    color: #000;
}

.more-options .more-options-container ul li a:hover {
    color: #31b4ff;
}

.more-options .more-options-container.openMoreOption {
    display: block;
}

.owl-theme .owl-nav [class*='owl-']:hover,
.owl-theme .owl-nav [class*='owl-']:focus {
    box-shadow: none;
}

.gallery-grid .all-owl.owl-carousel .owl-nav {
    bottom: auto;
    top: calc(50% - 21px);
    margin: 0;
    width: 100%;
}

.gallery-grid .all-owl.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: -20px;
}

.gallery-grid .all-owl.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: -20px;
}

.gallery-img,
.gallery-img>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cover-pic .classic-btn,
.see-allpics .classic-btn {
    border-color: #fff !important;
    color: #4e4851 !important;
}
.gallery-instruction-part
{
    width: calc(100% - 860px);
}
.gallery-layout-part
{
    width: 860px;
    padding-left: 28px;
}
.gallery-tab {
    position: relative;
}

.gallery-tab .nav-tabs {
    /* background: #fff;
    border-radius: 26px;
    border: 1px solid #070044 !important; */
    /* color: #31b4ff !important; */
    justify-content: center;
    margin-bottom: 20px;
    max-width: 100%;
}

.gallery-tab .nav-tabs li.nav-item {
    /* display: inline-block;
    width: 14.28%;
    height: 45px;
    min-width: 100px; */
}

/* .gallery-tab .nav-tabs li.nav-item .nav-link {
    border-radius: 0;
    padding: 11px 20px;
    text-align: center;
    color: #070044;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    justify-content: center;
} */

/* .gallery-tab .nav-tabs li.nav-item .nav-link.active {
    background-color: #070044;
    border-radius: 25px;
    color: #fff;
    margin-bottom: 0;
    border: none;
} */

/* .gallery-tab .nav-tabs li.nav-item .nav-link:hover,
.gallery-tab .nav-tabs li.nav-item .nav-link:focus {
    box-shadow: none;
    border-color: transparent;
    margin-bottom: 0;
} */

.info-block {
    width: 70%;
}

.img-arrow-block {
    width: 30%;
}

.image-placeholder-1,
.image-placeholder-2,
.image-placeholder-3 {
    background-color: #fff;
    border: 1px solid #efefef;
}

.best-img-msg p {
    font-size: 16px;
}

.row.thumbnail-imag-container {
    margin: 0 -5px;
}

.thumbnail-imag-container .col-md-2 {
    padding: 0 5px;
}
.thumbnail-imag-container:not(.all-image-thumbnails) .col-md-2 {
    width: 20%;
}

.gallery-img-thumbnail {
    overflow: hidden;
    height: 80px;
    margin-bottom: 10px;
}

.gallery-img-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Decorations */
.accred-slider {
    padding-bottom: 60px;
}

.accred-slider .owl-carousel .owl-item img {
    width: auto;
}

.accred-slider .owl-carousel .owl-stage {
    display: flex;
}

.accred-slider .accred-cards {
    height: calc(100% - 12px);
}

.ten-columns.col-md-2 {
    background: #f93;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    padding-top: 6px;
}

.ten-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
}

.ten-columns>.col-md-2 {
    margin-bottom: 30px;
    width: 100% !important;
}

.accreditationsbox {
    height: 100%;
    box-shadow: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    margin: 5px;
}

.accreditationsbox h6 {
    min-height: 53px;
    font-weight: 400;
    /* margin-bottom: 15px; */
    margin: 0 -20px 15px;
    background: #070044;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accreditations-text {
    min-height: 105px;
    margin-bottom: 10px !important;
}

.acc-img {
    margin: 10px 20px;
    min-height: 150px;
}

.acc-img img {
    max-height: 120px;
}

.accred-list li {
    padding-bottom: 3px;
}

.popup-plus-icon-box {
    width: 25px;
    height: 25px;
    z-index: 9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #070044;
    color: #fff !important;
}

.popup-plus-icon-box.open {
    width: 25px;
    height: 25px;
    background-color: #070044;
}

.popup-plus-icon-btn {
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    text-decoration: none;
}

.popup-plus-icon-btn i {
    transition: all 0.5s ease-in-out;
}

.popup-plus-icon-btn.rotate i{
    transform: rotate(45deg);
}

.popup-plus-icon-btn svg {
    transform: rotate(45deg);
}

.popup-plus-icon-btn.rotate svg{
    transform: rotate(0deg);
}

.plusmore-box {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    left: 0;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #4e4851;
}

.plusmore-box .neumorphic-fieldset label .label-style {
    background: #fff;
}

/* .nhs-body .accred-slider .all-owl.owl-carousel .owl-nav {
    left: 0;
} */

.acc-dot {
    width: 9px;
    height: 9px;
}

.accreditations-file-input {
    margin: 10px 0;
}

.accreditations-file-input input {
    opacity: 0;
    position: absolute;
}

.accreditations-file-input img {
    opacity: 0.3;
    max-width: 30px;
    margin: 0 auto;
}

.accreditations-file-input label {
    position: relative;
    border: 1px dashed #e0e0e0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    background-color: #fff;
}

.accreditations-drop-file {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 8px 0;
}

.accreditations-or {
    font-weight: 500;
    font-size: 11px;
    color: #6b7280;
    display: block;
    margin-bottom: 8px;
    display: flex;
}

.accreditations-or:before,
.accreditations-or::after {
    color: white;
    content: '';
    flex: 1;
    border-bottom: solid 1px #dcdcdc;
    margin: auto 0.25rem;
}

.accreditations-browse {
    font-weight: 500;
    font-size: 11px;
    color: #07074d;
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #31b4ff;
    border-radius: 4px;
    cursor: pointer;
}

.accreditations-browse:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.2392156863);
    transition: all 0.3s ease-in-out;
}

.no-min-height {
    min-height: unset !important;
}

.factsheet-section {
    margin-left: 200px;
    padding: 70px 40px;
}

.steps-heading-buttons.factsheet-btns {
    top: -50px;
    right: -25px;
}

.factsheet-form .service-field {
    width: calc(100% - 140px);
}

.factsheet-sidebar {
    width: 300px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 0;
    padding: 10px;
    border-right: 1px solid;
}

.factsheet-sidebar .sidebar-heading {
    padding: 10px;
    border-bottom: 1px solid #00a1ff;
    text-align: center;
    width: 100%;
}

.factsheet-sidebar .sidebar-heading .heading-items {
    width: 100%;
}

.factsheet-sidebar .sidebar-heading .heading-items .neumorphic-fieldset>label {
    width: 120px;
}

.factsheet-sidebar .sidebar-list {
    padding: 10px;
}

.factsheet-sidebar .sidebar-list .delete-template {
    text-align: right;
}

.factsheet-sidebar .sidebar-list .delete-template span {
    color: red;
    cursor: pointer;
}

.factsheet-sidebar .sidebar-list .template-list {
    margin: 0;
    padding: 0;
}

.factsheet-sidebar .sidebar-list .template-list li {
    list-style: none;
    position: relative;
    margin-bottom: 10px;
}

.factsheet-sidebar .sidebar-list .template-list li .template-selection-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.factsheet-sidebar .sidebar-list .template-list li .template-selection-box:checked+.template-selected i {
    display: block;
}

.factsheet-sidebar .sidebar-list .template-list li .template-selected {
    height: 20px;
    width: 20px;
    border: 1px solid #31b4ff;
    position: absolute;
    right: 14px;
    top: calc(50% - 10px);
    border-radius: 50%;
    background-color: #fff;
}

.factsheet-sidebar .sidebar-list .template-list li .template-selected i {
    display: none;
    font-size: 18px;
    color: #31b4ff;
}

.factsheet-sidebar .sidebar-list .template-list li .template-list-item {
    padding: 10px 40px 10px 15px;
    border-radius: 15px;
    background: #f5f5f7 !important;
}

.factsheet-sidebar .sidebar-list .template-list li .template-list-item h4 {
    font-size: 16px;
    line-height: 20px;
    color: #31b4ff;
}

.factsheet-sidebar .sidebar-list .template-list li .template-list-item h4 span {
    background-color: #ccc;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    padding: 2px 5px;
    font-weight: 400;
    border-radius: 5px;
}

.factsheet-sidebar .sidebar-list .template-list li .template-list-item p {
    font-size: 14px;
    line-height: 18px;
}

.accreditations-data {
    padding: 20px;
}

.sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 15px 0;
}

.neumorphic-accordion .accordion-button {
    color: #4e4851;
    background: transparent;
    border-top: 1px solid #d0d9e6;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 0 !important;
}

.neumorphic-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.neumorphic-accordion .accordion-item {
    background-color: unset !important;
    border: none !important;
}

div.accordion-collapse {
    /* background-color: #f5f5f7; */
    margin-bottom: 10px;
    border-radius: 15px;
}

.neumorphic-checkbox input {
    background-color: #f5f5f7;
    height: 18px;
    width: 18px;
    border: 1px solid #cbc0c0;
    margin-top: 0;
}

.neumorphic-checkbox .form-check-input:checked {
    /* background-color: transparent; */
    border: unset;
    outline: unset;
    box-shadow: none;
    border: 1px solid #cbc0c0;
}

.neumorphic-checkbox .form-check-input[type=checkbox] {
    outline: unset;
    cursor: pointer;
}

.neumorphic-checkbox .form-check-input:active {
    filter: unset;
}

.neumorphic-checkbox .form-check-input:focus {
    border: 1px solid #cbc0c0;
}

.neumorphic-checkbox .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(../assets/images/check.svg);
}

.w-title {
    width: 80px;
}

.checkbox-title-part {
    width: 100px;
}

.checkboxes-container {
    width: calc(100% - 100px);
}

.checkboxes-container .form-check {
    justify-content: flex-start;
    width: 22%;
}

.w-40 {
    width: 40%;
}

.w-10 {
    width: 10%;
}

.collapsible-link {
    position: absolute;
    right: 0;
    top: 0;
}

.padding-30 {
    padding: 30px;
}

.modal-hdr {
    padding-bottom: 20px;
}

.group-form {
    padding: 20px 40px;
}

.group-map-img {
    max-height: 310px;
    overflow: hidden;
    border-radius: 6px;
}

.group-map-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin-top: -50px;
}

.popup-body {
    max-height: 450px;
    overflow-y: auto;
}

.map-image {
    height: 370px;
    overflow: hidden;
}

.map-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.info-round {
    height: 22px;
    width: 22px;
    line-height: 20px;
}

.room-ward {
    padding: 30px;
}
.room-ward-box{
    margin-top: 15px;
}

.card-field-part {
    width: calc(100% - 40px);
    margin-right: 15px;
}

.card-number-part {
    align-items: start;
    display: flex;
}

.describe-facility-content {
    padding: 30px 20px;
    border-radius: 8px;
    /* min-height: 370px; */
}

.nhs-body .description-slider .all-owl.owl-carousel .owl-nav {
    bottom: -17px;
}

.nhs-body .description-slider .all-owl.owl-carousel .owl-nav button.owl-prev:before,
.nhs-body .description-slider .all-owl.owl-carousel .owl-nav button.owl-next:before {
    width: 25px;
    height: 25px;
    background: #fff;
    color: #4e4851;
    font-size: 15px;
    line-height: 25px;

}

/* .description-item {
    padding-bottom: 50px;
} */

.describe-facility-content p {
    font-style: italic;
    padding: 0 5px;
}

.generatedText
{
    position: relative;
}
.description-item .desc-textarea {
    font-style: italic;
    padding: 0 5px;
    background: transparent;
    resize: none;
    color: #fff;
    border: none;
    min-height: 200px;
}
.description-item .desc-textarea.hideTextarea {
    visibility: hidden;
    position: absolute;
    z-index: -1;
}
.textareaAnimation
{
    position: relative;
    z-index: 1;
}

/* Robot animation */
.robot-container {
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
}

.robot-animation {
    position: relative;
}

.robot-animation {
    animation: float 2s ease-in-out infinite;
    /* animation-delay: 2.5s; */
}

.mouth {
    position: absolute;
    top: 42px;
    left: 19px;
    width: 18px;
    height: 13px;
    background-color: #fff9f9;
    border-radius: 5px;
    animation: speak 0.5s infinite alternate;
    /* animation-delay: 2.5s; */
    clip-path: ellipse(30% 50% at 50% 50%);
}

@keyframes speak {
    0% {
        clip-path: ellipse(30% 50% at 50% 50%);
    }

    100% {
        clip-path: ellipse(50% 30% at 50% 50%);
    }
}

@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

.describe-facility-content p {
    position: relative;
}

/* Question answers screen */
textarea.form-control.answer-textarea {
    resize: none;
    min-height: 120px;
}


/* toggle switch */
.switch-custom {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 14px;
}

.switch-custom input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-custom .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #070044;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border: 1px solid #070044;
}

.switch-custom .slider:before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 2px;
    background-color: #fff;
    transition: 0.2s;
}

.switch-custom input:checked+.slider {
    background-color: #070044;
}

.switch-custom input:disabled+.slider {
    opacity: 0.6;
}

.switch-custom input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

.switch-custom input:checked+.slider:before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
    background-color: #fff;
}

/* Rounded sliders */
.switch-custom .slider.round {
    border-radius: 34px;
}

.switch-custom .slider.round:before {
    border-radius: 50%;
}

.accordion-body .neumorphic-fieldset textarea {
    background-color: #fff;
}

.modal.modal-amenities input[type="checkbox"] {
    /* background-color: #f5f5f7; */
    height: 18px;
    width: 18px;
}

.pwd-field {
    position: relative;
}

.eye-pwd-icon {
    position: absolute;
    right: 12px;
    top: 12px;
}

ul.SearchbarMenu {
    position: absolute;
    background: #fff;
    left: 0;
    top: 39px;
    right: 0;
    z-index: 9;
    padding: 10px 0;
    list-style: none;
    border: 1px solid #dee2e6;
    border-top: 0;
    display: none;
    border-radius: 0 0 6px 6px;
}

ul.SearchbarMenu li {
    padding: 10px 15px;
    font-size: 14px;
}

ul.SearchbarMenu.openDrp {
    display: block;
}

.search-btn-group.open-search-dropdown input {
    border-bottom-left-radius: 0 !important;
}

.search-btn-group.open-search-dropdown.input-group>.input-group-append>.btn {
    border-bottom-right-radius: 0;
}

.group-selection .form-check-input {
    cursor: pointer;
}

.group-label {
    font-size: 18px;
}

.selection-box {
    padding: 1.2rem 0;
}

.qa-accordion .accordion-item {
    border: none;
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    background-color: unset;
}

.qa-accordion div.accordion-collapse {
    background: transparent;
}

.qa-accordion .accordion-body {
    padding-left: 0;
    padding-right: 0;
}

.qa-accordion .accordion-button:not(.collapsed) {
    color: unset;
    box-shadow: none;
}

.qa-accordion .accordion-button {
    font-size: 25px;
    color: #000;
    padding-left: 0;
    padding-right: 0;
    background-color: unset;
}

.qa-accordion .accordion-button:focus {
    box-shadow: unset;
}

.qa-accordion .accordion-item:last-child {
    border: none;
}

.add-accord-btn.hidebtn {
    display: none;
}

.add-accordion-section {
    display: none;
}

.add-accordion-section.show-add-accordion {
    display: block;
}

.amenitiesToWardSelect {
    display: none;
}

.amenitiesToWardSelect.showSection {
    display: block;
}

.disclaimer-text {
    font-weight: 600;
}

.neumorphic-fieldset label {
    text-align: left;
}

.getlocated-body textarea.form-control {
    min-height: 200px;
}

.addRoomSlider .owl-stage-outer {
    margin: -20px;
    padding: 20px;
}

.setup-slider .owl-stage-outer {
    margin: -20px;
    padding: 20px;
}

.setup-slider .all-owl.owl-carousel .owl-nav {
    margin-top: 0;
    bottom: -45px;
}

.getlocated-section .neumorphic-fieldset label .label-style {
    background-color: #ffffff;
}

.servicesListing {
    list-style: none;
}

.servicesListing li {
    padding: 10px 0;
    font-size: 18px;
}

.servicesListing li a {
    color: inherit;
    font-size: 20px;
    font-weight: 500;
}

.serviceStatusBox {
    padding: 5px 0;
}

.serviceStatus .progress {
    height: 24px;
    border-radius: 16px;
}

.serviceStatus .status10 {
    width: 10%;
}

.serviceStatus .status20 {
    width: 20%;
}

.serviceStatus .status30 {
    width: 30%;
}

.serviceStatus .status40 {
    width: 40%;
}

.serviceStatus .status50 {
    width: 50%;
}

.serviceStatus .status60 {
    width: 60%;
}

.serviceStatus .status70 {
    width: 70%;
}

.serviceStatus .status80 {
    width: 80%;
}

.serviceStatus .status90 {
    width: 90%;
}

.serviceStatus .status100 {
    width: 100%;
}

.serviceStatus .progress-bar {
    background-color: #070044;
    text-align: left;
    padding-left: 10px;
}

.applyallswitch label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 999px;
    transition: 0.3s;
}

.applyallswitch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 25px;
    background: #838383;
    display: block;
    border-radius: 999px;
    position: relative;
}

.applyallswitch [type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.applyallswitch input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.applyallswitch input:checked+label {
    background: #070044;
}


.preview {
    position: relative;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preview-text {
    margin: 0;
}

.edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.edit-area {
    width: 100%;
    height: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-icon-preview {
    background-color: transparent;
    height: 35px;
    width: 35px;
    min-width: auto;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-icon-preview:hover {
    background-color: rgb(140 140 140/.1);
}

.modal-header--sticky {
    position: sticky;
    top: 0;
    background-color: inherit;
    /* [1] */
    z-index: 1055;
    /* [2] */
}

.modal-footer--sticky {
    /* position: sticky; */
    bottom: 0;
    position: fixed;
    background-color: inherit;
    /* [1] */
    z-index: 1055;
    /* [2] */
    width: 100%;
}

.preview-modal .modal-content {
    border: none;
}

.preview-modal {
    padding: 0;
}

.preview-modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding: 0 2rem;
    height: 70vh;
}

.img-above-edit-icon-preview {
    position: absolute;
    top: 10px;
    /* right: 24px; */
    right: 10px;
    background-color: #ffffff;
    padding: 0;
}
.first-img-edit-icon
{
    left:10px !important;
    right:unset !important;
}

.img-above-edit-icon-preview img {
    object-fit: unset !important;
    width: 16px !important;
}

.img-above-edit-icon-preview:hover {
    background-color: #ffffff !important;
}

.preview-small-title h4 {
    font-size: 28px;
}

.preview-modal-body .neumorphic-fieldset label .label-style {
    background-color: #ffffff;
}

.preview-profile-pic {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-edit {
    position: absolute;
    right: 0;
    z-index: 99;
    top: 0;
    border: 2px solid #070044;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.avatar-edit input {
    display: none;
}

.cusror-default {
    cursor: default !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.preview-modal-body .ward-selection ul li:not(:nth-child(5n)) {
    margin-right: 5px;
}

.preview-modal-body .servicesmore-box ul li a {
    font-size: 17px;
    display: flex;
}

.preview-modal-body .servicemore-data {
    padding: 20px;
}

.preview-modal-body .servicesmore-box ul li a::after {
    display: none;
}

.preview-modal-body .qa-accordion .accordion-button {
    font-size: 20px;
}

.previewaddWardButton .blue-bg {
    padding: 0;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
}

.previewaddWardButton {
    margin-top: 20px;
    cursor: pointer;
}

.preview-modal-body .delete-ward {
    margin-top: 0;
}

.preview-modal-body .ward-allocations {
    padding-top: 0;
}

.img-above-edit-icon-preview .dropdown-menu {
    top: 45px !important;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

.previewpublish-button {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    z-index: 9;
    background-color: #ffffff;
    padding: 5px;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .1) !important;
}

.article-menu .dropdown-toggle {
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background-color: unset;
    height: 100%;
    width: 100%;
    justify-content: center;
    border-radius: 50%;
}

.article-menu .dropdown-toggle::after {
    display: none;
}

.article-menu .dropdown-menu {
    transform: none !important;
    width: 175px;
    max-height: -moz-fit-content;
    max-height: fit-content;
    border: 1px solid #f1f1f1;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: unset;
}

.article-menu li a {
    padding: 8px;
    color: #4e4851;
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: 500;
}

.article-menu li a:hover {
    background-color: #f2f2f2;
}

.article-menu i {
    font-size: 16px;
}

.preview-accreditation-section {
    padding-bottom: 100px !important;
}

.line-preview-accridations {
   min-height: 515px;
}
.line-accridations {
   min-height: 440px;
}

.float-button-sec-margin {
    /* margin-bottom: 110px; */
    margin-bottom: 60px;
}

.profileInformation {
    padding: 0 25px;
}

/* welcome screen */
.group-tabs {
    /* max-width: 700px; */
    max-width: 790px;
    margin: 0 auto;
    background-color: #070044;
    /* ebebeb -th */
    border-radius: 20px;
    padding: 0 34px;
    position: relative;
    z-index: 1;
}

.group-tabs .nav-item {
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-tabs .nav-item .nav-link {
    color: #fff;
    padding: 0.3rem 1.7rem;
    width: 100%;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    transition: all ease 0.3s;
}

.group-tabs.nav-pills .nav-link.active,
.group-tabs.nav-pills .show>.nav-link {
    background-color: transparent;
    border-radius: 20px;
    color: #070044;
    transition: all ease 0.7s;
    font-weight: 700;
}
.group-tabs .slick-track:before
{
    position: absolute;
    display: flex;
    height: calc(100% - 6px);
    width: 149px;
    background-color: #fff;
    z-index: 0;
    border-radius: 99px;
    margin: 3px;
    box-shadow: 0px 0 3px 1px rgb(0 0 0 / 47%);
    content: '';
    transition: all ease 0.7s;
}
.group-tabs.welcome-slider .slick-track:before
{
    position: absolute;
    display: flex;
    height: calc(100% - 6px);
    /* width: 207px; */
    width: 235px;
    background-color: #fff;
    z-index: 0;
    border-radius: 99px;
    margin: 3px;
    box-shadow: 0px 0 3px 1px rgb(0 0 0 / 47%);
    content: '';
    transition: all ease 0.7s;
}
/* .group-tabs .slick-track:before
    {
        width: 121px;
    } */

/* 
.glider {
    position: absolute;
    display: flex;
    height: calc(100% - 6px);
    width: 146px;
    background-color: #fff;
    z-index: 0;
    border-radius: 99px;
    transition: 0.25s ease-out;
    margin: 3px;
    box-shadow: 0px 0 3px 1px rgb(0 0 0 / 47%);
}
.glider.glinderActive1 {
    transform: translateX(0);
}
.glider.glinderActive2 {
    transform: translateX(160px);
}
.glider.glinderActive3 {
    transform: translateX(320px);
}
.glider.glinderActive4 {
    transform: translateX(480px);
}
.glider.glinderActive5 {
    transform: translateX(640px);
} */

/* 
.group-tabs.nav-pills .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    border-radius: 20px;
    transform: translateX(calc(-100% - 13px));
    transition: all ease 0.5s;
}
.group-tabs.nav-pills .nav-link.active:before,
.group-tabs.nav-pills .show>.nav-link:before {
    transform: translateX(0);
    transition: all ease 0.5s;
}
.group-tabs.nav-pills .nav-link > span
{
    z-index: 2;
    position: relative;
} */

.upload-section {
    height: 100%;
    width: 100%;
}

.upload-gallery-img {
    text-align: center;
    color: #070044;
}

.upload-gallery-img i {
    font-size: 36px;
}

.smartSearchField {
    border-radius: 20px;
    padding: 7px 20px;
    padding-left: 25px;
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
}

.smartSearchField.form-control:focus {
    border-width: 0;
    border-bottom-width: 1px;
}

.smartSearch {
    position: relative;
    /* max-width: 700px;
    margin: 0 auto; */
}

.smartSearch i {
    position: absolute;
    left: 0px;
    top: 12px;
    font-size: 15px;
}

.nhs-body .group-tabs .owl-carousel .owl-stage {
    margin: 0 auto;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-prev:before,
.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-next:before {
    height: 25px;
    width: 25px;
    line-height:25px;
    border-color: #070044;
    font-size: 15px;
    color: #070044;
    background: #fff !important;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav {
    margin-top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: relative;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-prev,
.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-next {
    margin: 4px;
    position: absolute;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-prev {
    left: -34px;
    top: -34px;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-next {
    right: -34px;
    top: -34px;
}

.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-prev.disabled,
.nhs-body .group-tabs .all-owl.owl-carousel .owl-nav button.owl-next.disabled {
    /* display: none; */
}

.bordered-tab-content {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 25px 0 0;
    margin-top: -25px;
}

.nhs-body .tab-table .btn {
    font-size: 13px;
    padding: 3px 8px;
}
.nhs-body .welcome-container .tab-table .btn
{
    min-width:75px;
}

.nhs-body .tab-table .btn.hide-btn {
    display: none;
}

.table-row-number {
    height: 24px;
    width: 24px;
    border: 1px solid #5e5e5e;
    border-radius: 50px !important;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    display: inline-block;
    color: #5e5e5e;
    vertical-align: top;
}
.table-row-title
{
    display: inline-block;
    width: calc(100% - 32px);
}

.tab-table .table {
    margin-bottom: 0;
}

.tab-table .text-warning {
    color: #feae00 !important;
}

.tab-table .text-success {
    color: #1db100 !important;
}

.tab-table .text-primary {
    color: #31b4ff !important;
}

.tab-table td:not(:last-child), .tab-table th:not(:last-child)  {
    border-right: 1px solid #e5e5e5;
}



.tab-table td,
.tab-table th {
    border-bottom: 0px;
    padding: 10px 20px;
    vertical-align: middle;
}
.tab-table td p{
    font-size: 13px;
}
.tab-table th {
    background: #ebebeb;
}
.tab-table th.bigCell{
    min-width: 150px;
}

.tab-table th:not(:first-child),
.tab-table td:not(:first-child) {
    text-align: left;
}


.tab-table tr:first-child td {
    padding-top: 20px;
}

.tab-table tr:last-child td {
    padding-bottom: 20px;
}

.tab-table tr td span.font-bold {
    font-weight: 600;
}

.tab-table td.text-underline a {
    text-decoration: underline;
}

.upload-file-parent {
    width: 35px;
    margin: 0 auto;
}

.upload-file-parent img.upload-icon {
    cursor: pointer;
}

img.upload-icon {
    width: 35px;
    margin: 0 auto;
}

.upload-field {
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    opacity: 0;
}

/* .glider{
    position: absolute;
    display: flex;
    height: 100%;
    width: 150px;
    background:#fff;
    z-index: 0;
    border-radius: 80px;
    transition: 0.25s ease-out;
    box-shadow: 0 5px 13px rgba(255, 165, 0, 0.5);
} */


.facility-pills .nav-link {
    background-color: #ebebeb;
    font-size: 15px;
    font-weight: 700;
    padding: 20px;
    color: #000;
    margin-bottom: 3px;
    margin-right: 3px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-left: 8px solid transparent;
}

.facility-pills .nav-link.active,
.facility-pills .show>.nav-link {
    background-color: #ebebeb;
    color: #00a1ff;
}


.facility-tab-content {
    background-color: #ebebeb;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 20px;
    min-height: 500px;
}

.onboarding-tab-content {
    background-color: #ebebeb;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 20px;
}

@font-face {
    font-family: hanzipen;
    src: url("/public/hanzipen-sc-regular-en.woff");
}

.hanzipen-font {
    font-family: "hanzipen";
}

.hanzipen-font-bold {
    font-family: "hanzipen";
    font-weight: 700;
}

.hidden {
    visibility: hidden;
}

.gradient-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    /* Adjust width as needed */
}

.gradient-select {
    width: 100%;
    background: linear-gradient(to right, #d4007f, #120b53);
    border: none;
    color: #ffffff !important;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 14px;
    border-radius: .375rem !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
}

.gradient-select-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .gradient-select-wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border-width: unset !important;
}

.gradient-select-input {
    height: 8px !important;
    padding: .375rem 1.75rem .375rem .75rem !important;
    font-size: 14px !important;
    color: #ffffff !important;
}

.gradient-select svg {
    color: white !important;
}

.gradient-select option {
    background: white;
    color: black;
}

.gradient-select-arrow {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this for more/less padding */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    /* Arrow color */
    pointer-events: none;
}

.selectob-btn {
    font-size: 15px;
    padding: .375rem 1rem;
    border-radius: 6px;
    border: none;
}

.sec-frame {
    border: 2px solid #000;
    border-radius: 8px;
    position: relative;
    padding: 20px;
}

.sec-frame-title {
    background-color: #fff;
    padding: 0 10px;
    margin-top: -10px;
    margin-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.sec-frame-title p {
    font-weight: 700;
}

.addRoomSlider {
    padding: 10px 10px 10px 10px;
}


/* This needs to integrate in portal live */
.nhs-body .anim-switch .switchts {
    --switch-width: 40px;
    --switch-height: 24px;
    --switch-bg: #838383;
    --switch-checked-bg: #00da50;
    --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
    --switch-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    --circle-diameter: 18px;
    --circle-bg: #fff;
    --circle-shadow: 1px 1px 2px hsla(0, 0%, 57%, .45);
    --circle-checked-shadow: -1px 1px 2px hsla(0, 0%, 64%, .45);
    --circle-transition: var(--switch-transition);
    --icon-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    --icon-cross-color: var(--switch-bg);
    --icon-cross-size: 6px;
    --icon-checkmark-color: var(--switch-checked-bg);
    --icon-checkmark-size: 10px;
    --effect-width: calc(var(--circle-diameter) / 2);
    --effect-height: calc(var(--effect-width) / 2 - 1px);
    --effect-bg: var(--circle-bg);
    --effect-border-radius: 1px;
    --effect-transition: all .2s ease-in-out
}

.nhs-body .anim-switch .switchts input {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.nhs-body .anim-switch .switchts {
    display: inline-block
}

.nhs-body .anim-switch .switchts svg {
    transition: var(--icon-transition);
    position: absolute;
    height: auto
}

.nhs-body .anim-switch .switchts .checkmark {
    width: var(--icon-checkmark-size);
    color: #070044;
    transform: scale(0)
}

.nhs-body .anim-switch .switchts .cross {
    width: var(--icon-cross-size);
    color: var(--icon-cross-color)
}

.nhs-body .anim-switch .sliderts {
    box-sizing: border-box;
    width: var(--switch-width);
    height: var(--switch-height);
    background: var(--switch-bg);
    border-radius: 999px;
    position: relative;
    transition: var(--switch-transition);
    cursor: pointer
}

.nhs-body .anim-switch .circlets,
.nhs-body .anim-switch .sliderts {
    display: flex;
    align-items: center
}

.nhs-body .anim-switch .circlets {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    background: var(--circle-bg);
    border-radius: inherit;
    box-shadow: var(--circle-shadow);
    justify-content: center;
    transition: var(--circle-transition);
    z-index: 1;
    position: absolute;
    left: var(--switch-offset)
}

.nhs-body .anim-switch .sliderts:before {
    content: "";
    position: absolute;
    width: var(--effect-width);
    height: var(--effect-height);
    left: calc(var(--switch-offset) + var(--effect-width)/2);
    background: var(--effect-bg);
    border-radius: var(--effect-border-radius);
    transition: var(--effect-transition)
}

.nhs-body .anim-switch .switchts input:checked+.sliderts {
    background: #070044
}

.nhs-body .anim-switch .switchts input:checked+.sliderts .checkmark {
    transform: scale(1)
}

.nhs-body .anim-switch .switchts input:checked+.sliderts .cross {
    transform: scale(0)
}

.nhs-body .anim-switch .switchts input:checked+.sliderts:before {
    left: calc(100% - var(--effect-width) - var(--effect-width)/2 - var(--switch-offset))
}

.nhs-body .anim-switch .switchts input:checked+.sliderts .circlets {
    left: calc(100% - var(--circle-diameter) - var(--switch-offset));
    box-shadow: var(--circle-checked-shadow)
}

.onboarding-vacant-ward {
    border-left: 8px solid #1db100 !important;
}

.onboarding-occupied-ward {
    border-left: 8px solid #a62b17 !important;
}

.anim-switch {
    display: flex;
    align-items: center;
}


.gradient-bg {
    background: rgb(212, 0, 127);
    background: linear-gradient(90deg, rgba(212, 0, 127, 1) 0%, rgba(18, 11, 83, 1) 100%);
    color: #fff;
}

.message-box {
    border-radius: 8px;
    position: relative;
    padding: 20px;
}

.nhs-body .message-box.description-slider .all-owl.owl-carousel .owl-nav {
    bottom: -3px;
    right: 0;
    left: auto;
}

.message-box .fieldset-fixed-textarea {
    /* right: 10px; */
}

.message-textarea.form-control {
    border: none !important;
    border-radius: 8px;
    resize: none;
    color: #fff !important;
    min-height: 150px;
    padding: 15px;
}

.fieldset-fixed-textarea {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 9;
    color: #4e4851;
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-weight: 700;
}



/* onboardlocation screen */
.onboardMap,
.onboardMap>img {
    border-radius: 8px;
    height: 100%;
}

.nearbyPlaces .neumorphic-fieldset label .label-style {
    color: #00a2ff;
}

.onboard-location .neumorphic-fieldset input {
    padding: 5px 10px;
}

.userInfoModal {
    max-width: 450px;
}

.popup-box {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 30px;
}

.img-upload-box {
    border: 1px solid #ced4da;
    padding: 30px;
    min-height: 200px;
    width: 100%;
}

.img-upload-box i.fa-image {
    font-size: 40px;
}

.upload-logo-here p {
    font-size: 14px;
}

.upload-btn {
    background: transparent;
    border: 1px solid #070044;
    color: #070044;
}

.upload-btn:hover {
    transform: translateY(0px);
    transition: all 0.2s ease-in-out;
}

.upload-btn-field {
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-file-input {
    position: absolute;
    top: 0;
    opacity: 0;
    bottom: 0;
    cursor: pointer;
}

.button-ward-facility {
    border: 1px solid #5e5e5e;
    border-radius: 8px;
    font-weight: 600;
    width: 138px;
    height: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    background-color: #ffffff;
}

.button-ward-facility:hover,
.button-ward-facility.active {
    background-color: #090047;
    color: #ffffff;
}

.ob-ward-slider-sec {
    min-height: 174px;
    align-items: center;
    margin-bottom: 0;
}

.setup-wards-rooms-grey {
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 30px;
}

.list-style {
    list-style: number;
    font-size: 16px;
    font-weight: 600;
}

.counter-container input {
    /* width: 20%; */
    border: none;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin: 17px 0;
    width: 100%;
}

.counter-container input:focus-visible {
    outline: none;
}

.right-owl-nav .owl-nav .owl-next {
    margin-right: 0 !important;
}

.unset-nav .owl-nav {
    position: unset !important;
    display: flex;
    justify-content: end;
}

.onboarding-servicefirst {
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 20px;
    font-family: "hanzipen";
    color: #000000;
    font-weight: 500;
}

.onboarding-service-image {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.onboarding-service-image img {
    height: 100%;
    object-fit: cover;
}

.onboarding-services-details {
    position: absolute;
    z-index: 9;
    top: 0;
    padding: 15px;
    text-align: left;
    color: #ffffff;
    border-radius: 8px;
}

.onboarding-service-content-black h5,
.onboarding-service-content-black p {
    color: #000;
}

.onboarding-add-serc-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.onboarding-search-icon {
    position: absolute;
    top: 8px;
    left: 8px;
}

.search-onboarding {
    box-shadow: none !important;
    padding: 4px 7px 4px 27px;
}

.endlife-popup {
    width: 450px;
}

.endlife-popup-padding {
    padding: 0 50px 0 50px;
    max-height: calc(100vh - 150px);
}

.endlife-popup-bg {
    background-color: #ebebeb;
    padding: 30px;
    border-radius: 15px;
    padding-bottom: 20px;
}

.reviewroombox {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
}

.reviewroombox h3 {
    color: #099ffd !important;
    font-size: 20px;
}

.reviewroombox p {
    color: #4e4851 !important;
    font-size: 14px;
}

.bed-name-popup {
    font-size: 25px;
    font-weight: 600;
}

.need-assistance-popup,
.message-modal {
    padding: 25px 40px;
}
.need-assistance-popup .modal-body
{
    overflow-x: hidden;
}
.fixed-msg-count
{
    position: absolute;
    right: 0;
    font-size: 14px;
    line-height: 22px;
}

.onboarding-service-content p {
    font-size: 12px !important;
    line-height: 18px;
}

/* .owl-carousel .owl-item {
    width: fit-content !important;
} */

.owl-carousel .owl-item .onboarding-servicefirst img {
    display: inline-block !important;
    width: 50px !important;
}

.owl-carousel .owl-item .onboarding-servicefirst .toggle-img {
    width: 40px !important;
}

.onboarding-smartselect {
    margin-bottom: 0;
}

.onboarding-smartselect .MuiInputBase-input {
    padding: 0px .75rem !important;
    height: 33.5px;
    /* margin-bottom: 10px; */
    font-size: 13px;
    color: #ffffff;
    background-image: url(../assets/images/down-arrow-white.png);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: 16px;
    padding-right: 20px !important;
    background-color: transparent;
    z-index: 99;
}

.onboarding-smartselect .MuiOutlinedInput-notchedOutline {
    background: linear-gradient(90deg, #d4007f, #120b53);
    border: none;
    border-radius: .375rem !important;
    color: #fff !important;
    font-size: 14px;
    outline: none;
    padding: .375rem 1.75rem .375rem .75rem !important;
}

.onboarding-smartselect .MuiFormLabel-root {
    color: #ffffff;
}

.onboarding-smartselect .MuiAutocomplete-endAdornment {
    right: 9px !important;
}

.panelclosebtn:hover i {
    color: #fff;
    transition: all .3s linear;
}

.panelclosebtn i {
    transition: all .3s linear;
    line-height: 36px;
}

.delete-button i {
    color: hsla(0, 0%, 100%, .8);
    transition: all .1s linear;
    color: hsla(0, 0%, 100%, .8);
    transition: all .1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover i {
    color: #fff;
    transition: all .3s linear;
}

.preview-service-fieldset-text .label-style {
    background: #ffffff !important;
}

.modal-open .service-preview-full-modal-blur {
    -webkit-filter: blur(4px);
    filter: blur(5px);
}

.service-preview-gallery-edit .img-above-edit-icon-preview {
    right: 10px;
}

.service-preview-gallery-edit .img-above-edit-icon-preview i {
    font-size: 12px;
    color: #c4314b;
}

.service-preview-gallery-edit .img-above-edit-icon-preview {
    width: 30px;
    height: 30px;
    transform: scale(0);
    transition: all ease 0.3s;
}
.service-preview-gallery-edit .gallery-img:hover .img-above-edit-icon-preview,
.service-preview-gallery-edit .gallery-img-thumbnail:hover .img-above-edit-icon-preview {
    transform: scale(1);
    transition: all ease 0.3s;
}

.fieldset-smartsearch-select {
    margin-bottom: 0;
}

.fieldset-smartsearch-select .MuiInputBase-input {
    height: 36px;
    margin-top: -10px;
    font-size: 1rem;
}

.fieldset-smartsearch-select .MuiOutlinedInput-notchedOutline {
    height: 36px;
    width: 100%;
    top: 0;
    box-shadow: none;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    margin-top: -10px;
}

.custom-z-index {
    z-index: 9 !important;
}

.upload-section input[type=file] {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.addwardspreview {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px !important;
    color: #070044;
    cursor: pointer;
    display: inline-block;
}

.slick-slider {
    margin:0 -15px;
}
.slick-slide {
    padding: 15px;
    /* text-align:center; */
}
.addRoomSlider .slick-slide
{
    position: relative;
}
.review-margin{
    width: calc(100vw - 0px);
    margin-left: calc(-50vw + 50% + 0px);
}
 
.slick-slider
{
    position: relative !important;
    padding-bottom: 40px !important;
}
.slick-slider.map-list-slider{
    padding-bottom: 0 !important;
}
.map-list-slider .slick-slide{
    padding: 0 15px;
}
.slick-slider .slick-prev, 
.slick-slider .slick-next
{
    top: auto !important;
    bottom: -13px !important;
    right: 0 !important;
    left: auto !important;
    background: transparent !important;
    font-size: 0;
    width: 33px;
    height: 33px;
}
.slick-slider .slick-prev
{
    right: 40px !important;
}
.addRoomSlider .slick-slider .slick-next , .setup-slider .slick-slider .slick-next , .accreditation-slider .slick-slider .slick-next
{
    right: 15px !important;
}
.addRoomSlider .slick-slider .slick-prev , .setup-slider .slick-slider .slick-prev , .accreditation-slider .slick-slider .slick-prev
{
    right: 55px !important;
}
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    font-family: "Font Awesome 6 Free" ;
    font-style: normal;
    font-weight: 900;
    border: 1.5px solid #c3c3c3;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    background: #e2e2e2;
    box-shadow: none;
    color: #4e4851 ;
    opacity: 1 ;
    line-height: normal;
}
 
.slick-slider .slick-prev:before {
    content: "\f104";
}
 
.slick-slider .slick-next:before {
    content: "\f105";
}

.wardnumber-slick {
    padding-bottom: 0 !IMPORTANT;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.wardnumber-slick .slick-slide {
    padding: 0;
}

.nhs-body .amenities-pill-conent
{
    overflow-x: scroll !important;
    overflow: unset;
}

.nhs-body .service-preview-popup-slider .servicesmore-box {
    position: relative !important;
}

.nhs-body .service-image.item:hover {
    transform: scale(1);
}

/* Generate button */
.toggle-button {
    position: relative;
    width: 100%;
    height: 40px;
    background: linear-gradient(270deg, #070044, #099FFD, #F27200, #070044);
    background-size: 600% 600%;
    border-radius: 30px;
    border: none;
    color: white;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: background-position 0.5s ease, transform 0.3s ease;
    outline: none;
    overflow: hidden;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    animation: gradient 4s ease infinite;
}
.toggle-button.max-width-btn
{
    max-width: 100px;
    padding: 5px 20px;
    height: 35px;
}
.toggle-button.border-radius-less
{
border-radius: 6px;
}
.toggle-button:hover{
    -webkit-animation-play-state: paused;
}
.toggle-button .label {
    position: relative;
    z-index: 2;
    text-align: center;
    display: inline-block;
    width: 100%;
    transition: color 0.3s ease;
}
.toggle-button .toggle-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 30px;
    top: 0;
    left: -100%;
    transition: transform 0.4s ease, background-color 0.3s ease;
}
.toggle-button.border-radius-less .toggle-indicator
{
    border-radius: 6px;
}
.toggle-button:hover .toggle-indicator {
    transform: translateX(100%);
    background-color: rgba(255, 255, 255, 0.3);
}
.toggle-button:hover {
    transform: scale(1.05);
    background-position: 100% 50%;
}
.toggle-button:active {
    transform: scale(0.95);
}
.toggle-button:active .toggle-indicator {
    background-color: rgba(255, 255, 255, 0.2);
}

.toggle-button.variation3{
    background: linear-gradient(270deg, #070044, #F27200 , #F27200, #070044);
   transition: background-position 0.5s ease, transform 0.3s ease;
   animation: gradient 4s ease infinite;
   background-size: 600% 600%;
 }
 .toggle-button.variation3:hover {
      -webkit-animation-play-state: paused;
     }
 .toggle-button.variation3:hover .toggle-indicator
 {
   background: #F27200;
 }
 
 .classic-btn.small-btn.need-assistance-btn
 {
    background: transparent !important;
    text-decoration: underline;
    color: #070044 !important;
    padding-right: 0 !important;
 }
 .need-assistance-btn:focus{
    border-color: transparent;
 }
 .classic-btn.save-btn
 {
    background:#fff;
    color: #070044 !important;
    border-color: #070044 !important;
 }
 .classic-btn.save-btn:hover
 {
    background: #070044 !important;
    color: #fff !important;
 }
.setup-ward-amenities-popup {
    overflow-x: scroll !important;
    overflow: visible;
    overflow: initial;
}
.setup-ward-amenities-popup{
    max-height: 27vh;
}

 /* cancel button in popup */
.removeButton svg path {
    fill: hsla(0, 0%, 100%, .8);
    transition: all .1s linear;
}
.removeButton:hover svg path {
    fill: #fff;
    transition: all .1s linear;
}
.description-slider .slick-slider
{
    padding-bottom: 0 !important;
}

.description-slider .slick-slider .slick-prev,
.description-slider .slick-slider .slick-next{
    width: 25px;
    height: 25px;
    bottom: 8px !important;
    right: 50%  !important;
    margin: 0 5px !important;
    z-index: 99;
}
.description-slider .slick-slider .slick-next
{
    right: calc(50% - 35px) !important;
}
.description-slider .slick-slider .slick-prev:before,
.description-slider .slick-slider .slick-next:before{
    background: #fff;
    color: #4e4851;
    font-size: 15px;
}
.use-this-btn input[type='radio']
{
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    cursor: pointer;
}
.desc-count-btn{
    background: #fff;
    border-radius: 0.375rem;
    color: #4e4851;
    font-weight: 700;
    padding: 8px 10px;
}
.nhs-body .btn.useThisBtn
{
    /* padding: 5px 10px; */
    background: #F27200 !important;
    /* color: #ffffff !important;
    border-radius: 0.375rem;
    font-size: 13px;
    font-weight: 500; */
}
.nhs-body .btn.useThisBtn:hover{
    transform: translateY(0px);
    transition: all 0.2s ease-in-out;
}
.use-this-btn input[type='radio']:checked ~ .btn.useThisBtn:before
{
    opacity: 1;
}
.msg-textarea
{
    min-height: 65px !important;
    font-size: 14px;
}

.disable-btn{
    background-color: #f1f1f1;
}
.pagination-neumorphic.disable-btn:hover {
    background-color: #f1f1f1;
    color: #070044;
}
.nhs-body .service-preview-popup-slider .servicesmore-box {
    position: relative !important;
}

.nhs-body .service-image.item:hover {
    transform: scale(1);
}

/* Generate button */
.toggle-button {
    position: relative;
    width: 100%;
    height: 40px;
    background: linear-gradient(270deg, #070044, #099FFD, #F27200, #070044);
    background-size: 600% 600%;
    border-radius: 30px;
    border: none;
    color: white;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: background-position 0.5s ease, transform 0.3s ease;
    outline: none;
    overflow: hidden;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    animation: gradient 4s ease infinite;
}
.toggle-button.max-width-btn
{
    max-width: 100px;
    padding: 5px 20px;
    height: 35px;
}
.toggle-button.border-radius-less
{
border-radius: 6px;
}
.toggle-button:hover{
    -webkit-animation-play-state: paused;
}
.toggle-button .label {
    position: relative;
    z-index: 2;
    text-align: center;
    display: inline-block;
    width: 100%;
    transition: color 0.3s ease;
}
.toggle-button .toggle-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 30px;
    top: 0;
    left: -100%;
    transition: transform 0.4s ease, background-color 0.3s ease;
}

.toggle-button.border-radius-less .toggle-indicator
{
    border-radius: 6px;
}

.toggle-button:hover .toggle-indicator {
    transform: translateX(100%);
    background-color: rgba(255, 255, 255, 0.3);
}
.toggle-button:hover {
    transform: scale(1.05);
    background-position: 100% 50%;
}
.toggle-button:active {
    transform: scale(0.95);
}
.toggle-button:active .toggle-indicator {
    background-color: rgba(255, 255, 255, 0.2);
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}

.toggle-button.variation3{
    background: linear-gradient(270deg, #070044, #F27200 , #F27200, #070044);
   transition: background-position 0.5s ease, transform 0.3s ease;
   animation: gradient 4s ease infinite;
   background-size: 600% 600%;
 }
 .toggle-button.variation3:hover {
      -webkit-animation-play-state: paused;
     }
 .toggle-button.variation3:hover .toggle-indicator
 {
   background: #F27200;
 }
 
 .classic-btn.small-btn.need-assistance-btn
 {
    background: transparent !important;
    text-decoration: underline;
    color: #070044 !important;
    padding-right: 0 !important;
 }
 .need-assistance-btn:focus{
    border-color: transparent;
 }
 .classic-btn.save-btn
 {
    background:#fff;
    color: #070044 !important;
    border-color: #070044 !important;
 }
 .classic-btn.save-btn:hover
 {
    background: #070044 !important;
    color: #fff !important;
 }


 /* cancel button in popup */
.removeButton svg path {
    fill: hsla(0, 0%, 100%, .8);
    transition: all .1s linear;
}
.removeButton:hover svg path {
    fill: #fff;
    transition: all .1s linear;
}

/* developer css */
.rc-time-picker-panel-inner {
    margin-top: 31px;
    height: 225px;
}
.rc-time-picker-panel {
    position: fixed !important;
}
.rc-time-picker-panel-combobox {
    display: flex;
}
.rc-time-picker-input {
    padding: 4px !important;
}
a.rc-time-picker-clear {
    position: absolute;
    top: -2px;
    right: -2px;
    background: #a33d32;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    color: #fff;
    margin-left: 10px;
    line-height: 14px;
}
a .rc-time-picker-clear-icon:after {
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    height: 12px;
    width: 15px;
    font-weight: 600;
}

.nhs-body .message-slider .all-owl.owl-carousel .owl-nav
{

}
.neumorphic-fieldset .divinput
{
    font-size: 1rem;
    background: transparent;
    margin-top: -10px;
    border-radius: 0.375rem;
    height: 42px;
}
.neumorphic-fieldset .divinput input{
    margin-top: 0;
    padding: 2.5px 0;
}
.custom-select
{
    padding-right: 50px !important;
}
.onboarding-datepicker > .MuiDateCalendar-root
{
    width: unset !important;
}
.neumorphic-fieldset .divinput .MuiInputAdornment-root
{
    margin-left:30px;
}
.accreditation-slider .slick-slide {
    padding: 0px 5px 15px 5px;
}
.wardnumber-slick .slick-prev
{
    z-index: 9;
}
.neumorphic-fieldset .MuiTextField-root
{
    background: transparent;
    border-radius: .375rem;
    font-size: 1rem;
    margin-top: -10px;
    padding-top: 10px;
    padding-right: 0px;
}
.neumorphic-fieldset .MuiTextField-root input
{
    padding-bottom: 10px;
    font-size: 16px;
}
.neumorphic-fieldset .divinput .MuiTextField-root input
{
    padding-bottom: 0;
}
.nhs-body .onboard-location .gmnoprint>div {
    top:unset !important;
}
.selected-amenity-icon img
{
    width: 100%;
}
.rc-time-picker-clear-icon:hover:after {
    color: #ffff !important;
}
.accred-list {
    min-height: 63px;
}
p.file-name {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
    /* max-width: 230px;  */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.onboard-location .neumorphic-fieldset .form-control:disabled {
    background-color: #ffffff !important;
    opacity: 0.5;
}
.MuiTouchRipple-root {
    display: none;
}
.MuiIconButton-root:hover
{
    background-color: transparent !important;
}

.overlay-container {
    position:relative;
}
.side-pane {
    position: absolute;
    top: 0;
    left: 0; /* Position the overlay on the left side of the parent container */
    width: 400px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow to the right */
    z-index: 99;
    transform: translateX(-100%); /* Initially hide the overlay by shifting it left */
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .side-pane.visible {
    transform: translateX(0); /* Slide the overlay into view */
  }
  
  .overlay-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    background-color: white;
  }
  
  .overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .overlay-header p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .overlay-header i {
    cursor: pointer;
    font-size: 20px;
  }
  
  .location-overlay-body {
    @extend .overlay-body;
    flex-grow: 1;
    &::after {
      display: none;
    }
  }

.nearby-link {
    text-decoration: underline;
    cursor: pointer;
    font-size:16px;
}

  .btn-nearby,
.btn-nearby:hover,
.btn-nearby:active {
    position: absolute;
    top: 55px;
    right: 60px;
    color: #070044 !important;
    background: #FFFFFF !important;
    display: flex;
    box-shadow: 0 0 2px 2px #cdcdcd;
    font-weight: 600;
}
.define-access .auto-complete-dropdown-cus
{
    width: 50%;
}


/* my css */
/* .rc-time-picker-panel{
    top: 510px !important;
} */

.counter-container input:disabled {
    background-color: transparent;
}
.counter-container input[type=number] {
    -moz-appearance:textfield;
}

.gallery-tab .slick-slider
{
    padding-bottom: 0 !important;
    margin: 0 !important;
}
.gallery-tab .slick-slider .slick-prev, .gallery-tab .slick-slider .slick-next {
    height: 25px;
    width: 25px;
    bottom: auto !important;
    top: calc(50% - 12.7px) !important;
    transform: translate(0, 0);
}
.gallery-tab .slick-slider .slick-prev
{
    right: auto !important;
    left: -29px !important;
}
.gallery-tab .slick-slider .slick-next
{
    right: -29px !important;
}
.gallery-tab .slick-slider .slick-prev:before,
.gallery-tab .slick-slider .slick-next:before
{
    font-size: 14px;
}
.gallery-tab .slick-slide
{
    padding: 0 !important;
}
.progress-galleryimage .MuiCircularProgress-root
{
        width: 60px !important;
        height: 60px !important;
}
.progress-galleryimage .MuiTypography-root 
{
    color:#070044;
    font-weight: 800;
}
.welcome-table-header-fixed
{
    position: sticky;
    top:0;
    z-index: 1;
}

.text-loading {
    font-weight: bold;
    display:inline-block;
    clip-path: inset(0 1ch 0 0);
    animation: l 1s steps(4) infinite, textShine 1s ease-in-out infinite alternate;;
    color: #070044;
    font-size: 20px;
    line-height: 22px;
    padding-top:30px;
    background: linear-gradient(to right, #070044, #f27200);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 500% auto;
  text-align: center;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }


  /* Bot */
  #bot
{
  position: relative;
  text-align: left;
  width: 150px;
  height: 150px;
  min-width: 10em;
  min-height: 10em;
  /*border: 1px solid lightblue;*/
  margin: 0 auto;
}

#head
{
  position: relative;
  display: inline-block;
  margin-top: 15%;
  margin-left: 10%;
  width: 80%;
  height: 70%;
  /*border : 1px solid yellow;*/
}
#face
{
  position: absolute;
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
  height: 100%;
  border: 0.4em solid #070044;
  border-radius: 1.5em;
  /*border : 1px solid red;*/
  background-color: #fff;
}
#left-ear, #right-ear
{
  position: absolute;
  top: 30%;
  width: 6%;
  height: 25%;
  /* border: 0.15em solid #000; */
  background-color: #fff;
  border-radius: 0.1em;
}
#left-ear
{
  left: -6%;
}
#right-ear
{
  right: -6%;
}
#left-ear-inner, #right-ear-inner
{
  position: absolute;
  top: 20%;
  width: 100%;
  height: 60%;
  background-color: #00a1ff;
  border-radius: 0.1em;
}
#left-ear-inner
{
  left: -150%;
}
#right-ear-inner
{
  right: -150%;
}
#eyes
{
  position: absolute;
  width: 70%;
  height: 20%;
  margin-left: 16%; /* 16 */
  margin-top: 20%; /* 20 */
  /*border : 1px solid lightseagreen;*/
}
#left-eye, #right-eye
{
  position: absolute;
  width: 35%;
  height: 100%;
  background-color: #00a1ff;
  border-radius: 0.5em;
  /*border: 0.15em solid #FFF;*/
}
 #right-eye
{
  right: 0%;
}
#mouth
{
  position: absolute;
  width: 25%;
  height: 4%;
  border-left: 0.2em solid #000;
  border-right: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
  border-top: 0.0em solid #000;
  border-radius: 0.5em;
  left: 35%;
  bottom: 20%;
}
/* Animations */
#bot.neutral #left-eye, #bot.neutral #right-eye
{
   animation: blink-eyes 3s infinite ease-in alternate;
   animation-delay: 1s;
}
#bot.neutral #left-ear-inner
{
   animation: move-left-ear-inner 5.0s infinite ease alternate;
}
#bot.neutral #right-ear-inner
{
   animation: move-right-ear-inner 5.0s infinite ease alternate;
}
@keyframes blink-eyes {
  0%   { height: 10%; margin-top: 10%}
  10% { height: 100%; margin-top: 0%}
  100% { height: 100%; margin-top: 0%}
}
/* Speaking */
#bot.speaking #mouth
{
   border-top: 0.2em solid #070044;
   background-color: #070044;
   animation: speak-mouth 1.0s infinite ease alternate;
}
@keyframes speak-mouth {
  0%   { width: 10%; height: 4%; left: 45%;}
  25% { width: 30%; height: 10%; left: 35%;}
  50% { width: 6%; height: 4%; left: 47%;}
  75% { width: 40%; height: 8%; left: 30%;}
  100% { width: 30%; height: 4%; left: 35%;}
}

@keyframes glance-eyes {
  0%  { margin-left: 16%; }
  10% { margin-left: 6%; }
  40% { margin-left: 6%; }
  60% { margin-left: 24%; }
  70% { margin-left: 24%; }
  80% { margin-left: 16%; }
  100% { margin-left: 16%; }
}
@keyframes pinch-mouth {
  0%   { width: 30%; left: 35%; }
  48%  { width: 30%; left: 35%; }
  50%  { width: 10%; left: 45%; }
  52%  { width: 30%; left: 35%; }
  100% { width: 30%; left: 35%;}
}
@keyframes move-left-ear-inner {
  0%   { left: -150%; }
  48%  { left: -150%; }
  50%  { left: -100%; }
  52%  { left: -150%; }
  100% { left: -150%; }
}
@keyframes move-right-ear-inner {
  0%   { right: -150%; }
  48%  { right: -150%; }
  50%  { right: -100%; }
  52%  { right: -150%; }
  100% { right: -150%; }
}


.typewriter h3 {
    font-size: 20px;
    line-height: 22px;
    padding-top:10px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid 070044; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0; /* Adjust as needed */
    color: #070044;
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite,
      textShine 1s ease-in-out infinite alternate;
      /* background: linear-gradient(to right, #fff, #00a1ff); */
      background: linear-gradient(to right, #fff, #00a1ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 500% auto;
  }

  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #070044 }
  }


  .loader-modal
  {
    max-width: 450px;
    background:transparent;
  }
  @keyframes textShine {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  .setupward-scroll{
    overflow-y: scroll;
    max-height: 465px;
  }
  .setupward-scroll .nav-item
  {
    width: 100%;
  }
  .view-acc-sec {
    min-height: 270px;
  }
  .view-acc-sec img {
    width: 100px !important;
  }
  .text-popup {
    max-height: 350px;
    overflow-y: scroll;
    white-space: pre-line;
}

.description-slider .slick-slide
{
    padding: 0 15px;
}
.description-slider .slick-slider .slick-prev, 
.description-slider .slick-slider .slick-next
{
    bottom: -7px !important;
}

.red-btn{
    background: #ffe0e0 !important;
}
.red-btn path , .red-btn i{
    fill: #b51700 !important;
    color: #b51700 !important;
    font-size: 14px !important;
}
.yellow-btn
{
    background: #ffeecb !important;
    color: #feae00 !important; 
}
.yellow-btn i{
    color: #feae00 !important;
}
.green-btn{
    background: #c3f3db !important;
    color: #14804a !important;
}
.green-btn path,.green-btn i{
    fill: #14804a !important;
    color: #14804a !important;
}
.topblue-btn
{
    background:#eeecff !important;
    color: #070044 !important;
}
.topblue-btn path
{
    color: #070044 !important;
    fill: #070044 !important;
}
.lightgreen-btn
{
    background-color: #c6d8fd  !important;
    color: #7695FF  !important;
}
.lightgreen-btn i{
    color: #144efa  !important;
    font-size: 14px !important;
}
.lightred-btn
{
    background-color: #FFE8C5  !important;
    color: #f87c4f  !important;
}
.lightred-btn i{
    color: #f87c4f  !important;
    font-size: 14px !important;
}
.lightblue-btn
{
    background-color: #EFE6E8  !important;
    color: #644046  !important;
}
.lightblue-btn i{
    color: #644046  !important;
    font-size: 14px !important;
}
.count_up.green-btn.active {
    border-color: #14804a;
}
.count_up.yellow-btn.active {
    border-color: #8c8f02;
}
.count_up.red-btn.active
{
    border-color: #cf544d;
}
.count_up.blue-btn.active
{
    border-color: #5856a9;
}
.count_up.lightgreen-btn.active
{
    border-color: #7695FF;
}
.count_up.lightred-btn.active
{
    border-color: #f87c4f;
}
.count_up.lightblue-btn.active
{
    border-color: #644046;
}
/* Responsive CSS Write Here */
@media (min-width: 1200px) {
    .getlocated-body p {
        font-size: 16px;
    }
}
@media (min-width: 1400px) {

    .onboarding-service-image,
    .onboarding-servicefirst {
        height: 550px !important;
    }

    .neumorphic-fieldset label .label-style {
        font-size: 14px;
    }

    .tab-table td,
    .tab-table th {
        padding: 10px 15px;
        font-size: 14px;
    }

}
@media (min-width: 1128px) {

    .onboarding-service-image,
    .onboarding-servicefirst {
        height: 550px !important;
    }
}
@media (max-width: 1200px) {
    .ward-selection ul li {
        width: 28px;
        height: 28px;
    }

    .img-above-edit-icon-preview {
        top: 10px;
        right: 10px;
    }

    .edit-icon-preview,
    .thumbnail-imag-container .img-above-edit-icon-preview {
        height: 25px;
        width: 25px;
    }

    .thumbnail-imag-container .img-above-edit-icon-preview {
        height: 20px;
        width: 20px;
        top: 5px;
    }

    .img-above-edit-icon-preview .dropdown-menu {
        top: 28px !important;
    }

    .article-menu i {
        font-size: 14px;
    }

    .img-above-edit-icon-preview img {
        width: 12px !important;
    }
    .gallery-instruction-part

    {
        width: calc(100% - 730px) ;
    }
    .gallery-layout-part
    {
        width: 730px ;
    }
    .group-tabs.gallery-group-tab .owl-stage:before
    {
        width: 145px ;
    }
    .group-tabs.gallery-group-tab.glinderActive2 .owl-stage:before {
        transform: translateX(161px);
    }
    .group-tabs.gallery-group-tab.glinderActive3 .owl-stage:before {
        transform: translateX(322px) ;
    }
    .group-tabs.gallery-group-tab.glinderActive4 .owl-stage:before {
        transform: translateX(483px) ;
    }
    .group-tabs.gallery-group-tab.glinderActive5 .owl-stage:before
    {
        transform: translateX(644px);
    }
    .group-tabs.gallery-group-tab.glinderActive6 .owl-stage:before {
        transform: translateX(805px);
    }
    .group-tabs.gallery-group-tab.glinderActive7 .owl-stage:before {
        transform: translateX(966px);
    }
}
@media (max-width: 1100px) {
    .nhs-body .getlocated-section h5.section-title {
        font-size: 30px;
        padding: 20px 25px;
    }

    .facility-pills .nav-link {
        padding: 15px;
        font-size: 13px;
    }

    .facility-cards-parent .facility-card {
        padding: 20px;
    }

    .facility-card p {
        font-size: 13px;
    }

    .facility-tab-content {
        min-height: 420px;
    }

    .classic-btn.small-btn {
        padding: 5px 15px !important;
    }

    .ob-ward-slider-sec {
        min-height: 140px;
    }

    .button-ward-facility {
        height: 92px;
    }

    .list-style {
        font-size: 14px;
    }

    .setup-wards-rooms-grey {
        margin-top: 2rem !important;
    }

    .counter-container input {
        margin: 14px 0;
    }

    .counter-container.mt-4.mb-4 {
        margin: 10px 0 !important;
    }

    .delete-ward {
        margin-top: 10px;
    }

    .cards-section {
        margin: 20px 0 0;
    }

    .section-description.mt-4.mb-4 {
        margin-top: 15px !important;
    }

    .neumorphic-fieldset label {
        margin-left: 4px;
    }

    .neumorphic-fieldset.ms-3 {
        margin-left: 0.5rem !important;
    }

    .neumorphic-fieldset label .label-style {
        padding: 0 5px;
        font-size: 11px;
    }

    .onboarding-service-image,
    .onboarding-servicefirst {
        height: 550px !important;
    }

    .qa-accordion .accordion-button {
        font-size: 20px;
    }

    .preview-accreditation-section {
        padding-bottom: 50px !important;
    }

    .nhs-body #amenities-section {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .nhs-body #location-section,
    .nhs-body .overview-section {
        padding-top: 50px !important;
    }
}
@media (max-width: 992px) {

    /* .d-flex.small-title.align-items-center.preview-small-title
    {
       margin-bottom: 20px;
       padding-top: 30px; 
    } */
    .nhs-body .coordinator-box {
        padding: 50px;
    }
}
@media (max-width:1280px){
    .nhs-body .rate-div
    {
        width: 80px;
    }
}
/* Responsive CSS Write Here */
/* Please add CSS above responsive*/

.tags-edit
{
    height: 30px;
    width: 30px;
}

ul.document-list-table
{
    margin: 0;
}
ul.document-list-table li{
    display: inline-block;
    list-style: none;
    padding: 0 2px;
}
.document-list-icons i,
ul.document-list-table li i{
    /* font-size: 24px;
    line-height: 24px; */
}
.document-box
{
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}
.tab-table tr.docum-row td{
    padding: 5px 0;
}

.blue-btn
{
    background: #070044 !important;
    color: #fff !important;
}
.blue-btn i
{
    color: #fff !important;
}
.upload-docum i
{
    color: #070044 !important;
    line-height: 30px;
}
.upload-docum-btn i{
    line-height: 30px;
}
.expaneded-row td.Mui-TableBodyCell-DetailPanel 
{
    padding: 0 !important;
}

.tbodynodata
{
    position: relative;
}
.nodataDiv
{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    min-height: 67px;
}
p.nodatep
{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-table.welcome-tab-table, .tab-table.welcome-tab-table table, .tab-table.welcome-tab-table .tbodynodata
{
    height: 100%;
}
.desc-text-height
{
    min-height: 200px;
}
.default-desc-box
{
    min-height: 80px;
}
.description-item .generatedText.generatedTextSmallHeight
{
min-height: 60px !important;
}
.generatedText.generatedTextSmallHeight .textareaAnimation
{
    font-size: 14px;
}
.hidden-file-input
{
    position: absolute;
    width: 28px;
    height: 28px;
    opacity: 0;
    cursor: pointer;
}
.accreditation-slider .slick-list> div, .service-cards-slick-slider .slick-list> div {
    margin-left:0;
}
.service-cards-slick-slider .slick-slide {
    padding: 5px;
}