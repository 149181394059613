@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
    --secondary-color: #4e4851;
    --primary-color: #070044;
}

.nhs-body {
    background-color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    color: #4e4851;
    overflow-x: hidden;
}

.nhs-body *::-webkit-scrollbar {
    width: 4px;
}

.nhs-body *::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 6px;
}

.nhs-body *::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
    border: 1px solid #c1c1c1;
}

.nhs-body a {
    text-decoration: none;
}

.nhs-body h3 {
    font-size: 15px;
}

.nhs-body .text-small {
    font-size: 13px;
}

.nhs-body .normal {
    font-weight: 400 !important;
}

.nhs-body .bold {
    font-weight: 500;
}

.nhs-body .bolder {
    font-weight: 600;
}

.nhs-body .indigo-text {
    color: #070044;
}

.nhs-body .darkgray-text {
    color: #7C7C7C;
}

.nhs-body .gray-text {
    color: #4e4851;
}

.nhs-body .sky-color {
    color: #099ffd;
}

.nhs-body .justify {
    text-align: justify;
}

.nhs-body .underline {
    text-decoration: underline;
}

.nhs-body .lead-text {
    font-size: 16px;
}

.nhs-body p {
    margin-bottom: 0;
}

.nhs-body .bg-gray {
    background-color: #fafafa;
}

.nhs-body .btn {
    font-size: 15px;
    padding: 6px 14px;
    border-radius: 6px;
}

.nhs-body .nhs-sidebar .btn {
    font-size: 12px;
    padding: 5px 7px;
    border-radius: 6px;
}

.nhs-body .btn-white {
    background-color: #ffffff;
    border: 1px solid #4e4851;
    color: #4e4851;
}

.nhs-body .btn-white:hover,
.nhs-body .btn-white:focus {
    background-color: #070044 !important;
    border: 1px solid #070044 !important;
    color: #ffffff !important;
}

.nhs-body .btn-white:hover svg,
.nhs-body .btn-white:hover svg path .btn-white:focus svg,
.nhs-body .btn-white:focus svg path {
    fill: #ffffff !important;
}

.nhs-body .pill-btn {
    border-radius: 33px;
    width: max-content;
    padding: 10px 17px;
    font-size: 15px;
    transition: 0.1s all ease-in-out;
}

.nhs-body .pill-btn:hover {
    transform: scale(1.04) !important;
    transition: 0.1s all ease-in-out;
}

.primary-btn {
    background-color: #070044 !important;
    color: #ffffff !important;
    transition: all 0.3s ease-in-out;
}

.nhs-body .border-bottom {
    border-bottom: 1px solid #cdcdcd !important;
}

.nhs-body .border-top {
    border-top: 1px solid #cdcdcd !important;
}

.nhs-body .occupied {
    color: #E93B59 !important;
}

.nhs-body .occupied-bg {
    background-color: #ffc3cd !important;
}

.nhs-body .occupied-border {
    border-color: #E93B59 !important;
}

.nhs-body .filing-fast {
    color: #9E9010 !important;
}

.nhs-body .filing-fast-bg {
    background-color: #EFE389 !important;
}

.nhs-body .filing-fast-border {
    border-color: #9E9010 !important;
}

/* .nhs-body input[type=radio],
.nhs-body .form-check-input[type=checkbox] {
    outline: 1px solid #4e4851;
} */

.nhs-body .logo {
    width: 150px;
    margin-bottom: 0;
    margin-top: 0;
}

.nhs-body .serachbox {
    background-color: #ffffff;
    border-radius: 33px;
    padding: 7px;
    width: 410px;
    border: 1px solid #dddddd;
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 13%);
    height: 48px;
    cursor: pointer;
    margin: 0 auto;
    inline-size: fit-content;
}

.nhs-body .serachbox:hover {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px 0px;
}

.nhs-body .custom-dropdown-input {
    font-size: 15px;
    background-color: transparent;
    border: none;
    width: 140px;
    border-radius: unset;
    padding: 0;
    text-align: center;
}

.nhs-body .serachbox .form-control:focus {
    color: var(--bs-body-color);
    background-color: none;
    border-color: #070044;
    outline: 0;
    box-shadow: none;
}

.nhs-body .serachbox>div:nth-child(1):after,
.nhs-body .serachbox>div:nth-child(2):after {
    content: "";
    position: absolute;
    width: 1px;
    height: 24px;
    background-color: #dddddd;
    right: 0px;
    top: 12px;
    border-right: 1px solid #4e4851;
}

.nhs-body .show-second-header .animated-div {
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 770px;
}

.nhs-body .search-header {
    position: absolute;
    background: #ffffff;
    z-index: 9;
    width: 100%;
    padding: 25px 0;
    top: 0;
}

.nhs-body .show-second-header {
    background: #ffffff;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 16px 0;
    position: absolute;
    transform-origin: 50% 50%;
    top: 0;
}

.nhs-body .search-header .animated-div {
    transform: scale(1, 1) translateY(0);
    visibility: visible;
    transform-origin: 50% 50%;
    transition: transform 300ms cubic-bezier(0.2, 0, 0, 1), opacity 300ms 300ms cubic-bezier(0.2, 0, 0, 1), visibility 0ms 125ms;
}

.nhs-body .show-second-header .animated-div {
    transform: scale(1.1, 1.1) translateY(0);
    transform-origin: 50% 0%;
    visibility: visible;
    transition: transform 300ms cubic-bezier(0.2, 0, 0, 1), opacity 300ms 300ms cubic-bezier(0.2, 0, 0, 1), visibility 0ms 125ms;
}

.nhs-body .top-header:after {
    content: "";
    position: absolute;
    background-color: #070044;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    transition: transform 250ms cubic-bezier(0.2, 0, 0, 1);
}

.nhs-body .top-header.show-second-header:after {
    transform: scaleY(2.8);
    transform-origin: 50% 0%;
    transition: transform 250ms cubic-bezier(0.2, 0, 0, 1);
}

.nhs-body .serachbox input {
    cursor: pointer;
}

.nhs-body .serachbox input,
.nhs-body .serachbox .dropdown-toggle {
    min-width: 120px;
    padding: 0 8px;
}

.nhs-body .serachbox .dropdown-toggle:after {
    display: none;
}

.nhs-body.overlay-body:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0/25%);
    opacity: 0.5;
}

.nhs-body .top-search-icon {
    background-color: #099ffd;
    color: #ffffff;
    padding: 3px 10px;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    cursor: pointer;
    z-index: 1;
}

.nhs-body .locationheader {
    padding: 10px 0;
    background-color: #f2f2f2;
    border-bottom: 1px solid #D4D4D4;
    border-top: 1px solid #D4D4D4;
}

.nhs-body a.location {
    color: #070044;
    text-decoration: underline;
}

.nhs-body .form-check-input:checked {
    background-color: #070044;
    border-color: #070044;
    box-shadow: none;
}

.nhs-body .form-switch .form-check-input:focus {
    box-shadow: none;
}

.nhs-body .form-control.search-input {
    box-shadow: none;
    padding: 6px 9px 6px 27px;
    width: 248px;
    color: #4e4851;
    font-size: 15px;
    border: 1px solid #4e4851;
    border-radius: 6px;
    font-weight: 500;
}

.nhs-body .search-icon {
    left: 8px;
    position: absolute;
    top: 10px;
}

.nhs-body .service-header {
    border-bottom: 1px solid #D4D4D4;
    padding-top: 102px;
    box-shadow: 0 0 4px 1px rgb(0 0 0 / 13%);
}

.nhs-body .search-large {
    border: 1px solid #dddddd;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    border-radius: 33px;
    max-width: 761px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
}

.nhs-body .search-large.showlarge {
    background-color: #ebebeb;
}

.nhs-body .search-large label {
    font-size: 15px;
    cursor: pointer;
}

.nhs-body .search-large .title {
    font-size: 18px;
    color: #469cd2;
    margin-bottom: 8px;
}

.nhs-body .column-1>a {
    padding: 8px 24px;
    position: relative;
    cursor: pointer;
    color: #4e4851;
}

.nhs-body .column-1>a.show,
.nhs-body .column-4>a.show {
    background-color: #ffffff;
    border-radius: 26px 26px 0 0;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.nhs-body .column-4>a.show {
    border-radius: 50px;
}

.nhs-body .column-2>a.show,
.nhs-body .column-3>a.show {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-radius: 33px;
}

.nhs-body .column-2>div,
.nhs-body .column-3>div {
    min-width: 130px;
}

.nhs-body .column-2>div.show,
.nhs-body .column-3>div.show {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-radius: 33px;
    z-index: 1;
}

.nhs-body .column-2>div.show:before,
.nhs-body .column-3>div.show:before {
    display: none;
}

.nhs-body .search-large .column-2 a,
.nhs-body .search-large .column-3 a,
.nhs-body .search-large .column-2 a p,
.nhs-body .search-large .column-3 a p {
    font-size: 14px;
}

.nhs-body .column-1 a.show .form-control,
.nhs-body .column-4 a.show .form-control {
    border-bottom: 1px solid #4e4851 !important;
    border-radius: 0;
}

.nhs-body .column-1:hover,
.nhs-body .column-2:hover,
.nhs-body .column-3:hover,
.nhs-body .column-4:hover {
    background-color: #ebebeb;
    border-radius: 33px;
    z-index: 1;
}

.nhs-body .search-header .anyDates-button {
    text-align: center;
    background-color: transparent;
}

.nhs-body .anyDates-dropdown-button {
    display: flex;
    flex-direction: column;
}

.nhs-body .search-large.showlarge .column-1:has(.hoverable-item:hover),
.nhs-body .search-large.showlarge .column-2:has(.hoverable-item:hover),
.nhs-body .search-large.showlarge .column-3:has(.hoverable-item:hover),
.nhs-body .search-large.showlarge .column-4:has(.hoverable-item:hover) {
    background-color: #dddddd;
}

.nhs-body .column-2>a,
.nhs-body .column-3>a {
    padding: 8px 24px;
    cursor: pointer;
    position: relative;
    color: #4e4851;
    font-size: 14px;
}

.nhs-body .column-2>a.show,
.nhs-body .column-3>a.show {
    color: #099ffd;
}

.nhs-body .column-4>a {
    padding: 8px 24px 8px 24px;
    position: relative;
}

.nhs-body .column-4 {
    padding-right: 10px;
}

.nhs-body .column-1:hover:after,
.nhs-body .column-4:hover:before {
    display: none;
}

.nhs-body .column-2>div:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 44%;
    left: 0px;
    top: 50%;
    border-right: 1px solid #ddd;
    transform: translate(0, -50%);
}

.nhs-body .column-2 > .flexible:before {
    content: "";
    position: absolute;
    width: 1px;
    left: inherit !important;
    height: 25% !important;
    top: 72% !important;
    border-right: 1px solid #ddd;
    transform: translate(0, -50%);
}

/* .nhs-body .column-2>div{
    z-index: 1;
}
.nhs-body .column-2>div:after
{
    content: '';
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(90deg, #ebebeb 0, #ebebeb 50%, #ddd 50%, #ddd 100%);
    z-index: 0;
    display: none;
    width: calc(100% + 30px);
}
.nhs-body .column-2:hover >div:not(.show):before
{
    display: none;
}
.nhs-body .column-2:hover >div:not(.show):after
{
    display: block;
}
.nhs-body .column-2>div .btn-bg-after {
    position: relative;
    z-index: 1;
    background: transparent;
    border-radius: 33px 0 0 33px;
}
.nhs-body .search-large.showlarge .column-2:hover div:not(.show) .btn-bg-after {
    background-color: #dddddd;
} */


.nhs-body .column-3>div:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 44%;
    left: 0px;
    top: 50%;
    border-right: 1px solid #ddd;
    transform: translate(0, -50%);
}

/* .nhs-body .column-3>div:after
{
    content: '';
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(90deg, #ddd 0, #ddd 50%, #ebebeb 50%, #ebebeb 100%);
    z-index: 0;
    display: none;
    width: calc(100% + 30px);
}
.nhs-body .column-3:hover >div:not(.show):before
{
    display: none;
}
.nhs-body .column-3:hover >div:not(.show):after
{
    display: block;
}
.nhs-body .column-3>div .btn-bg-after {
    position: relative;
    z-index: 1;
    background: transparent;
    border-radius: 0 33px 33px 0;
}
.nhs-body .search-large.showlarge .column-3:hover div:not(.show) .btn-bg-after {
    background-color: #dddddd;
} */

.nhs-body .column-2.flexible>a {
    width: 261px;
}

.nhs-body .flexibleLabel,
.nhs-body .flexibleLabelForShowing {
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: block;
}

.nhs-body .flexibleLabelForShowing {
    width: 100px;
}

.nhs-body .dateLabel {
    width: 70px;
}

.nhs-body .dateDayChange {
    color: #4e4851;
}

.nhs-body .column-4 a:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 44%;
    left: -1px;
    top: 50%;
    border-right: 1px solid #ddd;
    transform: translate(0, -50%);
}

.nhs-body .search-large .serach-btn {
    cursor: pointer;
    background: #070044;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 33px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nhs-body .search-large label,
.nhs-body .search-large input {
    height: 20px;
    color: #4e4851;
}

.nhs-body .search-large .form-control {
    background-color: transparent;
    font-size: 15px;
    width: 160px;
    text-align: left;
}

.nhs-body .search-large .form-control:focus {
    box-shadow: none;
}

.nhs-body .search-large .form-control.service-input {
    width: 125px;
}

.nhs-body .search-large-title {
    color: #099ffd;
    font-size: 20px;
    margin-bottom: 17px;
}

.nhs-body .search-large .column-1 a.show .form-control::-ms-input-placeholder,
.nhs-body .search-large .column-4 a.show .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #099ffd !important;
    font-weight: 500;
    font-size: 14px;
}

.nhs-body .search-large .column-1 a.show .form-control::placeholder,
.nhs-body .search-large .column-4 a.show .form-control::placeholder {
    color: #099ffd !important;
    font-weight: 400;
    font-size: 14px;
}

.nhs-body .form-control.custom-dropdown-input::placeholder {
    font-size: 15px !important;
}

.nhs-body .form-control.custom-dropdown-input::-ms-input-placeholder {
    font-size: 15px !important;
}

.nhs-body .btn-white:hover,
.nhs-body .btn-active {
    background-color: #070044;
    border: 1px solid #070044;
    color: #ffffff !important;
}

.nhs-body .btn-white:hover svg,
.nhs-body .btn-active svg {
    fill: #ffffff;
}

.nhs-body .btn-white:hover svg {
    color: #ffffff;
}

.nhs-body .location-legend {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nhs-body .locatecare-map {
    border-radius: 0;
    position: fixed;
    height: 100vh;
    box-shadow: 0px 0px 1px 2px #cfc7c7;
}

.nhs-body .map-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    z-index: 8;
}

.nhs-body .map-close {
    position: absolute;
    left: 2%;
    top: 2%;
    background: #ffffff;
    /* height: 30px; */
    /* width: 30px; */
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    box-shadow: 1px 4px 4px #0000001f;
}

.nhs-body .horizontal-menu-slider a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 17px;
    color: #4e4851;
    opacity: 0.9;
}

.nhs-body .horizontal-menu-slider a.active {
    opacity: 1;
}

.nhs-body .horizontal-menu-header img {
    width: 24px;
}

.nhs-body .horizontal-menu-header a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4e4851;
    opacity: 0.8;
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.nhs-body .horizontal-menu-header a.active {
    border-bottom: 3px solid #070044;
    opacity: 1;
    color: #000;
}

.nhs-body .horizontal-menu-header a:not(.active):hover {
    opacity: 1;
    border-bottom: 3px solid #dddddd;
    color: #000;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-item .item {
    display: flex;
    align-items: center;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: -26px;
    top: 9px;
    box-shadow: 0px 2px 2px 2px #ccc;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: -10px;
    top: 9px;
    box-shadow: 0px 2px 2px 2px #ccc;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-prev:before {
    content: "\f104";
    position: absolute;
    left: 0;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    top: 1px;
    left: 5px;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-next:before {
    content: "\f105";
    position: absolute;
    left: 6px;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    top: 1px;
}

.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-prev.disabled,
.nhs-body .horizontal-menu-header.owl-carousel .owl-nav button.owl-next.disabled {
    display: none;
}

.nhs-body .horizontal-menu-header.owl-theme .owl-nav {
    margin: 0 !important;
}

.nhs-body .horizontal-menu-header.owl-theme .owl-nav [class*='owl-']:hover {
    background: #070044 !important;
    color: #ffffff;
    text-decoration: none;
}

.nhs-body .header-slider-menu .owl-stage {
    display: flex;
}

.nhs-body .profile-css .dropdown-toggle {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 14px;
    border: 1px solid #dddddd;
    border-radius: 30px;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.13);
}

.nhs-body .profile-css .dropdown-toggle::after {
    display: none;
}

.nhs-body .profile-css .dropdown-menu {
    right: -2px !important;
    top: 56px !important;
    left: auto !important;
    transform: none !important;
    width: 175px;
}

.nhs-body .profile-css li a {
    padding: 10px 15px;
    color: #4e4851;
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight: 500;
}

.nhs-body .profile-css li a:hover {
    background-color: #f2f2f2;
}

.nhs-body .profile-css {
    position: relative;
}

.nhs-body .profile-css .dropdown-toggle:hover,
.nhs-body .profile-css .dropdown-toggle.show {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px 0px;
}

.nhs-body .profile-css i {
    font-size: 16px;
}

.nhs-body .switch {
    /* switch */
    --switch-width: 40px;
    --switch-height: 24px;
    --switch-bg: rgb(131, 131, 131);
    --switch-checked-bg: rgb(0, 218, 80);
    --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
    --switch-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    /* circle */
    --circle-diameter: 18px;
    --circle-bg: #fff;
    --circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
    --circle-checked-shadow: -1px 1px 2px rgba(163, 163, 163, 0.45);
    --circle-transition: var(--switch-transition);
    /* icon */
    --icon-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    --icon-cross-color: var(--switch-bg);
    --icon-cross-size: 6px;
    --icon-checkmark-color: var(--switch-checked-bg);
    --icon-checkmark-size: 10px;
    /* effect line */
    --effect-width: calc(var(--circle-diameter) / 2);
    --effect-height: calc(var(--effect-width) / 2 - 1px);
    --effect-bg: var(--circle-bg);
    --effect-border-radius: 1px;
    --effect-transition: all .2s ease-in-out;
}

.nhs-body .switch input {
    display: none;
    accent-color: unset !important;
}

.nhs-body .switch {
    display: inline-block;
}

.nhs-body .switch svg {
    -webkit-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    ;
    -o-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    ;
    transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    ;
    position: absolute;
    height: auto;
}

.nhs-body .switch .checkmark {
    width: 10px;
    color: #070044;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.nhs-body .switch .cross {
    width: 6px;
    color: rgb(131, 131, 131);
}

.nhs-body .slider {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 40px;
    height: 24px;
    background: rgb(131, 131, 131);
    border-radius: 999px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    -o-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    cursor: pointer;
}

.nhs-body .circle {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: inherit;
    -webkit-box-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
    box-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    -o-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
    z-index: 1;
    position: absolute;
    left: calc(24px - 18px / 2);
}

.nhs-body .slider::before {
    content: "";
    position: absolute;
    width: calc(18px / 2);
    height: var(--effect-height);
    left: calc(var(--switch-offset) + (var(--effect-width) / 2));
    background: var(--effect-bg);
    border-radius: var(--effect-border-radius);
    -webkit-transition: var(--effect-transition);
    -o-transition: var(--effect-transition);
    transition: var(--effect-transition);
}

.nhs-body .switch input:checked+.slider {
    background: #070044;
}

.nhs-body .switch input:checked+.slider .checkmark {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.nhs-body .switch input:checked+.slider .cross {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.nhs-body .switch input:checked+.slider::before {
    left: calc(100% - var(--effect-width) - (var(--effect-width) / 2) - var(--switch-offset));
}

.nhs-body .switch input:checked+.slider .circle {
    left: calc(100% - var(--circle-diameter) - var(--switch-offset));
    -webkit-box-shadow: var(--circle-checked-shadow);
    box-shadow: var(--circle-checked-shadow);
}

.nhs-body .dropdown-menu {
    width: 230px;
    max-height: 230px;
    overflow-y: auto;
    border: none;
    box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
    z-index: 99;
}

.nhs-body .area-dropdown {
    border-radius: 0 0 26px 26px;
    top: 47px !important;
    width: 208px;
    padding: 5px 18px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    left: 0 !important;
    position: absolute;
    transform: none !important;
    padding-bottom: 10px !important;
}

.nhs-body .area-dropdown::-webkit-scrollbar {
    display: none;
}

.nhs-body .area-dropdown::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
}

.nhs-body .search-location-list,
.nhs-body .selected-search-location {
    padding: 7px 6px;
    font-size: 14px;
}

.nhs-body .search-location-list:hover,
.nhs-body .selected-search-location {
    cursor: pointer;
    border-radius: 6px;
    background: #f2f2f2;
}

.nhs-body .area-dropdown .selected{
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    color: #099ffd;
}

.nhs-body .area-dropdown li a {
    font-size: 15px;
    color: #099ffd;
    padding: 3px 24px;
    width: 100%;
    display: block;
    font-weight: 400;
}

.nhs-body .area-dropdown li.label-list {
    padding: 0 6px;
    font-size: 15px;
}

.nhs-body .area-dropdown li a:hover {
    background-color: #f2f2f2;
}

.nhs-body .dropdown-item {
    cursor: pointer;
}

.nhs-body .dropdown-input {
    font-size: 15px;
    background-color: transparent;
    border: none;
    width: 140px;
    border-radius: unset;
    padding: 0;
    text-align: center;
}

.nhs-body .service-dropdown.area-dropdown {
    width: 173px;
}

.nhs-body .dropdown-input:focus {
    border: none;
    box-shadow: none;
    color: #ffffff;
    border-radius: unset;
    background-color: transparent;
}

.nhs-body .dropdown-menu-icon-container {
    padding: 0 5px;
    border-radius: 20%;
    background-color: #dddddd;
    width: 48px;
    height: 48px;
    justify-content: center;
}

.nhs-body .dropdown-menu .dropdown-option {
    margin-left: 10px;
    padding: unset;
    font-size: 16px;
    color: #4e4851;
    text-wrap: wrap;
    width: calc(100% - 48px);
}

.nhs-body .dropdown-menu .dropdown-item:active {
    background-color: #f3f3f3;
}

.nhs-body .dropdown-menu-dates {
    max-width: 742px;
    width: 100%;
    max-height: calc(100vh - 220px) !important;
    border-radius: 32px;
    transform: none !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
    top: 115px !important;
    scrollbar-width: none;
}

.nhs-body .dropdown-menu-dates::-webkit-scrollbar 
{
    display: none;
   }

.nhs-body .dropdown-menu-dates hr {
    border-top: 0;
    border-right: 1px solid #bdbdbd;
    margin: unset;
    opacity: 1;
}

.nhs-body .dropdown-menu .area-dropdown::-webkit-scrollbar {
    width: 20px;
}

.nhs-body .date-tabs {
    width: 211px;
    display: flex;
    border-radius: 50px;
    justify-content: space-around;
    margin: 23px auto 23px !important;
    background-color: #ebebeb;
    padding-left: 2px;
    padding-right: 2px;
}

.nhs-body .months-container code {
    color: #4e4851;
    font-weight: 600;
}

.nhs-body .date-tabs button {
    cursor: pointer;
    font-size: 15px;
    padding: 8px 12px;
    width: 99px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 2px;
    transition-duration: 0.15s;
    color: #4e4851;
    font-weight: 500;
    border: 0px;
    border-radius: 100px;
}

.nhs-body .date-tabs .primary-text {
    background-color: #ffffff;
    border-radius: 100px;
    border: 1px solid #dddddd;
}

.nhs-body .rdrNextButton i {
    display: none;
}

.nhs-body .rdrPprevButton i {
    display: none;
}

.nhs-body .rdrNextButton::before {
    content: "\f105";
    position: absolute;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
}

.nhs-body .rdrNextPrevButton:hover {
    background: #dddddd;
    border-radius: 100px;
}

.nhs-body .rdrPprevButton:before {
    content: "\f104";
    position: absolute;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
}

.nhs-body .rdrNextPrevButton {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background: none;
    align-items: center;
}

.nhs-body .rdrDayToday .rdrDayNumber span:after {
    display: none;
}

.nhs-body .rdrEndEdge,
.nhs-body .rdrDay,
.nhs-body .rdrStartEdge {
    color: #4e4851 !important;
}

.nhs-body .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
    color: #1d2429 !important;
    z-index: 9;
}

.nhs-body .rdrDay {
    z-index: 9 !important;
}

.nhs-body .rdrInRange {
    background-color: #f2f2f2;
    top: 0px !important;
    bottom: 0px;
}

.nhs-body .date-container .rdrDefinedRangesWrapper {
    display: none;
    width: 100%;
    height: 100%;
}

.nhs-body .rdrDayNumber {
    font-weight: 500;
    width: 100%;
    height: 100%;
    left: 0 !important;
    top: 0 !important;
    z-index: 99;
}

.nhs-body .filter-btn {
    display: flex;
    align-items: center;
}

.nhs-body .filter-btn svg {
    margin-right: 6px;
}

.nhs-body .filter-label {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #070044;
    font-size: 12px;
}

.nhs-body .date-container .rdrDateDisplayWrapper {
    display: none;
}

.nhs-body .rdrMonth {
    width: 50%;
    padding-bottom: 10px !important;
}

.nhs-body .date-container .rdrDateRangePickerWrapper {
    width: 100%;
}

.nhs-body .date-container .rdrDateRangeWrapper {
    width: 100%;
    height: 100%;
    margin: auto;
}

.nhs-body .date-container .rdrDefinedRangesWrapper {
    width: 100%;
}

.nhs-body .date-count-container {
    padding: 0 60px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.nhs-body .date-count-container .day-count {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D8D8D8;
    margin-right: 8px;
    padding: 6px 8px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
}

.nhs-body .selected-day-count {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4e4851;
    margin-right: 8px;
    padding: 6px 8px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
}

.nhs-body .date-count-container .day-count:hover {
    border: 1px solid #4e4851;
    background-color: #f2f2f2;
}

.nhs-body .date-count-container .day-count span,
.nhs-body .selected-day-count span {
    font-size: 11px;
    margin-left: 5px;
}

.nhs-body .dropdown-menu-beds {
    width: 280px;
    transform: translate3d(-126px, 31px, 0px) !important;
    border-radius: 20px;
}

.nhs-body .dopdown-menu-beds hr {
    border-top: 1px #7C7C7C solid;
}

.nhs-body .months-container p a::after {
    content: unset !important;
}

.nhs-body .anyDates-dropdown-button:after {
    display: none;
}

.nhs-body .date-tabs button:hover {
    background-color: #dddddd;
    border-radius: 100px;
}

.nhs-body .date-tabs button.primary-text:hover {
    background-color: #ffffff;
    border-radius: 100px;
}

.nhs-body .flexible-container p {
    margin: 20px auto 14px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.nhs-body .flexible-container .flexible-carousel .month-names:hover {
    border-color: #4e4851;
}

.nhs-body .flexible-container .flexibleType-filter span {
    font-size: 15px;
    padding: 8px 16px;
    text-align: center;
    border-radius: 30px;
    border: 1px solid #dddddd;
    margin-top: 3px;
    margin: 0 3px;
}

.nhs-body .flexible-container .flexibleType-filter span:active {
    transform: scale(0.925);
    transition-duration: 0.15s;
}

.nhs-body .flexible-container .flexible-carousel .month-names:active {
    transform: scale(0.925);
    transition-duration: 0.15s;
}

.nhs-body .date-tabs button:active {
    transform: scale(0.925);
    transition-duration: 0.15s;
}

.nhs-body .flexible-container .flexibleType-filter span:hover {
    cursor: pointer;
    border: 1px solid #4e4851;
    color: #4e4851;
}

.nhs-body .flexible-container .flexible-carousel {
    margin-bottom: 21px;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-next,
.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-prev {
    top: 46px !important;
    margin: 0 !important;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-next {
    background-color: #ffffff;
    width: 32px !important;
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px rgb(0 0 0/0.08), 0 4px 12px rgb(0 0 0/0.05) !important;
    border: 1px solid #dddddd;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-prev:hover,
.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 6px 16px rgba(0, 0, 0, 0.12) !important;
    background-color: #ffffff !important;
    color: #4e4851;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav .owl-prev {
    background-color: #ffffff;
    width: 32px !important;
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -15px !important;
    box-shadow: 0 1px 2px rgb(0 0 0/0.08), 0 4px 12px rgb(0 0 0/0.05) !important;
    border: 1px solid #dddddd;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav button.owl-prev:before {
    content: "\f104";
    position: absolute;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-self: center;
    left: unset;
    top: unset;
}

.nhs-body .flexible-container .flexible-carousel .owl-nav button.owl-next:before {
    content: "\f105";
    position: absolute;
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-self: center;
    left: unset;
    top: unset;
}

.nhs-body .flexible-container .flexible-carousel .month-names {
    width: 107px;
    height: 124px;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #dddddd;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.15s;
}

.nhs-body .rdrMonths {
    margin: 0 32px;
    justify-content: space-between;
}

.nhs-body .rdrWeekDay {
    width: 40px;
    flex-basis: unset !important;
}

.nhs-body .rdrMonthAndYearPickers {
    display: none;
}

.nhs-body .rdrMonthName {
    text-align: center;
    font-weight: 600;
    z-index: 1;
    color: #4e4851;
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 0;
}

.nhs-body .rdrMonthAndYearWrapper {
    position: absolute;
    width: 89% !important;
    height: unset;
    padding-top: unset;
    margin: 0 40px !important;
}

.nhs-body .rdrMonth .rdrWeekDays {
    justify-content: center;
}

.nhs-body .rdrDays {
    justify-content: center;
}

.nhs-body .month-slider {
    padding: 0 30px;
}

.nhs-body .year-text {
    font-size: 13px;
}

.nhs-body .rdrDay {
    margin-bottom: 1px;
}

.nhs-body .rdrDay:has(span.rdrStartEdge) {
    background: #f2f2f2;
    border-radius: 50% 0 0 50% !important;
}

.nhs-body .rdrDay:has(span.rdrEndEdge) {
    background: #f2f2f2;
    border-radius: 0 50% 50% 0 !important;
}

.nhs-body .rdrDay:has(span.rdrDayStartPreview) {
    background: #f2f2f2;
    border-radius: 50% 0 0 50% !important;
}

.nhs-body .rdrDay:has(span.rdrDayEndPreview) {
    background: #f2f2f2;
    border-radius: 0 50% 50% 0 !important;
}

/* .nhs-body .rdrDay.rdrDayHovered .rdrDayNumber span {
    color: #ffffff !important;
} */

.nhs-body .rdrDay:has(span.rdrStartEdge.rdrEndEdge),
.nhs-body .rdrDay:has(span.rdrDayStartPreview.rdrDayEndPreview) {
    background: transparent;
    border-radius: 50% !important;
}

.nhs-body .rdrDay:has(span.rdrStartEdge.rdrEndEdge + span.rdrDayStartPreview) {
    background: #f2f2f2;
    border-radius: 50% 0 0 50% !important;
}

.nhs-body .rdrDay:has(span.rdrInRange + span.rdrDayStartPreview.rdrDayEndPreview) .rdrDayNumber span {
    color: #ffffff !important;
}

.nhs-body .rdrDay:has(span.rdrDayStartPreview.rdrDayEndPreview) .rdrDayNumber span {
    color: #ffffff !important;
}

.nhs-body .rdrDayEndOfMonth .rdrDayInPreview,
.nhs-body .rdrDayEndOfMonth .rdrDayStartPreview,
.nhs-body .rdrDayEndOfWeek .rdrDayInPreview,
.nhs-body .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.nhs-body .rdrDayStartOfMonth .rdrDayInPreview,
.nhs-body .rdrDayStartOfMonth .rdrDayStartPreview,
.nhs-body .rdrDayStartOfWeek .rdrDayInPreview,
.nhs-body .rdrDayStartOfWeek .rdrDayStartPreview {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.nhs-body .rdrDayEndOfMonth .rdrInRange,
.nhs-body .rdrDayEndOfMonth .rdrStartEdge,
.nhs-body .rdrDayEndOfWeek .rdrInRange,
.nhs-body .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    right: 2px;
}

.nhs-body .rdrDayStartOfMonth .rdrInRange,
.nhs-body .rdrDayStartOfMonth .rdrEndEdge,
.nhs-body .rdrDayStartOfWeek .rdrInRange,
.nhs-body .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    left: 2px;
}

.nhs-body .rdrEndEdge,
.nhs-body .rdrStartEdge,
.nhs-body .rdrDay {
    border-radius: 50% !important;
    left: 0 !important;
    top: 0 !important;
    width: 40px;
    height: 40px;
}

.nhs-body .rdrDayInPreview {
    /* background: #f2f2f2; */
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    pointer-events: none;
    border: none;
    z-index: 1;
}

.nhs-body .rdrDayStartPreview,
.nhs-body .rdrDayEndPreview {
    background: #4e4851;
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    pointer-events: none;
    border: none;
    z-index: 1;
}

.nhs-body .rdrDayStartPreview,
.nhs-body .rdrDayEndPreview {
    width: 100%;
    height: 100%;
    border-radius: 50% !important;
    top: 0;
}

.nhs-body .rdrDayStartPreview .rdrDayNumber,
.nhs-body .rdrDayEndPreview .rdrDayNumber {
    color: #ffffff;
}

.nhs-body .filter-options-container {
    padding: 0 15px;
}

.nhs-body .btn-direction,
.nhs-body .btn-direction:hover,
.nhs-body .btn-direction:active {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #1A73E8 !important;
    background: #ffffff !important;
    display: flex;
    box-shadow: 0 0 2px 2px #cdcdcd;
    font-weight: 600;
}

.nhs-body .map-image-box {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.nhs-body .map-image-box img {
    object-fit: cover;
    height: 211px;
    width: 327px !important;
}

.nhs-body .map-box-pin {
    width: 327px;
    background: #ffffff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
}

.nhs-body .cqc-rating {
    border-radius: 6px;
    padding: 4px 6px;
    background-color: rgb(255 255 255 / 65%);
}

.nhs-body .yellow-star {
    color: #ffc109;
}

.nhs-body .map-box-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.nhs-body .map-box-subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nhs-body .map-box-title-onboarding {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; 
    word-break: break-word;
    margin-bottom: 3px;
}

.nhs-body .map-box-subtitle-onboarding {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; 
    word-break: break-word;
}

.nhs-body .icon-pin-box i {
    width: 26px;
    height: 26px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, 0.18);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.nhs-body .icon-pin-box {
    position: absolute;
    z-index: 9;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.nhs-body .map-box-carousel .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: space-between;
}

.nhs-body .icon-pin-box i:hover {
    transform: scale(1.04);
    background-color: #ffffff;
}

.nhs-body .map-box-carousel .owl-nav .owl-next,
.nhs-body .map-box-carousel .owl-nav .owl-prev {
    background-color: #ffffff !important;
    height: 32px;
    width: 32px;
    border-radius: 50px !important;
    opacity: 0;
}

.nhs-body .map-box-carousel:hover .owl-nav .owl-next,
.nhs-body .map-box-carousel:hover .owl-nav .owl-prev {
    opacity: 1;
}

.nhs-body .map-box-carousel.owl-theme .owl-nav .disabled {
    opacity: 0;
    cursor: default;
}

.nhs-body .map-box-carousel .owl-nav .owl-next:hover,
.nhs-body .map-box-carousel .owl-nav .owl-prev:hover {
    color: #4E4851 !important;
}

.nhs-body .map-box-carousel .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 2%;
}

.nhs-body .map-box-carousel.owl-theme .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
    margin: 3px;
}

.nhs-body .map-box-carousel.owl-theme .owl-dots .owl-dot.active span,
.nhs-body .map-box-carousel.owl-theme .owl-dots .owl-dot:hover span {
    background: #ffffff;
    width: 8px;
    height: 8px;
}

.slider-dots {
    position: absolute;
    width: 100%;
    bottom: 9%;
}
.overlay-dots .slider-dots{
    bottom: 38%;
}
.slider-dots span
{
    width: 6px;
    height: 6px;
    margin: 2px;
    border-radius: 50%;
    background-color: hsla(0, 0%, 100%, .38);
}
.slider-dots span.active
{
    width: 6px;
    height: 6px;
    margin: 2px;
    border-radius: 50%;
    background-color: hsla(0, 0%, 100%, 0.8);
}

.nhs-body .pin-box-service {
    padding-left: 0;
    display: flex;
    list-style: none;
    margin-bottom: 5px;
}

.nhs-body .pin-box-service li {
    padding: 5px 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.nhs-body .pin-status p {
    padding: 5px 10px;
    background: #c7ffe3;
    color: #2da66a;
    border-radius: 5px;
    font-weight: 600;
}

.nhs-body .pin-status .green {
    background: #c7ffe3 !important;
    color: #2da66a !important;
}

.nhs-body .pin-status .red {
    background: #ffc3cd !important;
    color: #E93B59 !important;
}

.nhs-body .pin-status {
    display: flex;
    align-items: center;
}

.nhs-body .map-box-carousel .owl-stage {
    display: flex;
}

.nhs-body .gmnoprint {
    top: 0 !important;
}

.nhs-body .gmnoprint.gm-style-mtc-bbw {
    display: none;
}

.nhs-body button.gm-control-active.gm-fullscreen-control {
    display: none;
}

.nhs-body button.gm-svpc {
    display: none;
}

.nhs-body .gmnoprint>div {
    border-radius: 10px !important;
    top: 30px !important;
}

.nhs-body .list-title {
    font-size: 14px;
    color: #4e4851;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 76%;
}

.nhs-body .list-middle p {
    color: #4e4851;
}

.nhs-body .dot-round {
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #2da66a;
    border-radius: 2px;
}

.nhs-body .list_box .owl-dots,
.nhs-body .list_box_map .owl-dots {
    position: absolute;
    bottom: 10px;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
}

.text-ellipsis {
    width: 40px;
    overflow: hidden;
    display: inline-block !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nhs-body .list_box .owl-theme .owl-dots .owl-dot span,
.nhs-body .list_box_map .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
    background-color: rgb(255 255 255 / 38%);
    margin: 0;
    margin-right: 4px;
}

.nhs-body .owl-theme .owl-dots .owl-dot {
    zoom: unset !important;
}

.nhs-body .list_box .owl-theme .owl-dots .owl-dot.active span,
.nhs-body .list_box .owl-theme .owl-dots .owl-dot:hover span,
.nhs-body .list_box_map .owl-theme .owl-dots .owl-dot.active span,
.nhs-body .list_box_map .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffffff;
    width: 7px;
    height: 7px;
}

.nhs-body .list_box_map .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    left: 10px;
}

.nhs-body .list_box_map .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    right: 10px;
}

.nhs-body .list_box .owl-theme .owl-nav .owl-prev,
.nhs-body .list_box .owl-theme .owl-nav .owl-next,
.nhs-body .list_box_map .owl-theme .owl-nav .owl-prev,
.nhs-body .list_box_map .owl-theme .owl-nav .owl-next {
    background: #ffffff;
    opacity: 1;
    width: 28px;
    height: 28px;
    line-height: 0;
    border-radius: 50px;
    opacity: 0.9;
}

.nhs-body .list_box_map .owl-theme .owl-nav .owl-prev.disabled,
.nhs-body .list_box_map .owl-theme .owl-nav .owl-next.disabled {
    display: none;
}

.nhs-body .list_box .owl-theme .owl-nav button i,
.nhs-body .list_box_map .owl-theme .owl-nav button i {
    font-size: 15px;
    font-weight: 600;
    color: #4e4851;
}

.nhs-body .overlay-list {
    background: #281a36;
    background: linear-gradient(0deg, rgb(0 0 0) 0%, rgba(89, 59, 116, 0) 50%);
    display: block;
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 3;
    border-radius: 12px;
}

.nhs-body .list_box .list-image,
.nhs-body .list_box_map .list-image {
    position: relative;
}

.nhs-body .locatecare-list-map {
    border: 0;
    border-radius: 5px;
}

.nhs-body .list_box .owl-theme .owl-nav [class*='owl-']:hover,
.nhs-body .list_box_map .owl-theme .owl-nav [class*='owl-']:hover {
    background: #ffffff !important;
    color: #4e4851;
    text-decoration: none;
}

.nhs-body .list-content {
    margin-top: 5px;
}
.img-slick-map-list{
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

.nhs-body .image-owl {
    z-index: 1;
    position: relative;
}

/* .nhs-body .image-owl .owl-prev,
.nhs-body .image-owl .owl-next {
    opacity: 0;
} */

.nhs-body.image-owl:hover .owl-arrow {
    opacity: 1;
}

.nhs-body .owl-arrow {
    display: flex;
    position: absolute;
    top: 50%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9);
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: opacity 0.2s linear;
    opacity: 0;
}

.nhs-body .owl-arrow .owl-arrow-prev {
    left: 5px;
}

.nhs-body .owl-arrow .owl-arrow-next {
    right: 5px;
}

.nhs-body .image-owl .owl-carousel {
    border-radius: 12px;
    box-shadow: 3px 3px 4px rgb(212 212 212 / 56%);
    overflow: hidden;
}

.nhs-body .list-view {
    padding-right: 26px;
}

.nhs-body .list_group {
    display: grid;
}

@supports (aspect-ratio: 1/1) {
    .nhs-body .list-image.item {
        aspect-ratio: 20 / 19;
    }

    .nhs-body .list-image.item img {
        aspect-ratio: 20 / 19;
        object-fit: cover;
    }
}

.nhs-body .map-list-view {
    height: calc(100vh - 175px);
    overflow-y: hidden;
    padding-bottom: 0 !important;
}

.nhs-body .map-list-view1 {
    height: calc(100vh - 175px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 0 !important;
}

.nhs-body .map-list-view>div>div {
    /*height: 700px !important; */
    height: calc(100vh - 175px) !important;
}

.nhs-body .map-view {
    flex: 1;
}

.nhs-body .list_box_map .owl-nav {
    margin: 0 !important;
}

.nhs-body .list_box_map .owl-nav .owl-next:hover,
.nhs-body .list_box_map .owl-nav .owl-prev:hover {
    opacity: 1;
}

.nhs-body .list_box_map .owl-nav {
    opacity: 0;
}

.nhs-body .list_box_map:hover .owl-nav {
    opacity: 1;
}

.nhs-body .map-fixed-view {
    position: fixed;
    height: 100%;
}

.nhs-body .map-fixed-view-custom {
    position: fixed;
    overflow: hidden;
    width: 100%;
}

.nhs-body .list-content .service-type-list a {
    font-size: 12px;
    border: 1px solid #4e4851;
    border-radius: 5px;
    color: #4e4851;
    /* padding: 4px 7px; */
    /* margin-right: 7px; */
    line-height: 16px;
    padding: 2px 7px;
}

.nhs-body .avail-bed {
    /* padding: 4px 8px;
    font-size: 12px; */
    border-radius: 5px;
    background: #c7ffe3;
    color: #2da66a;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 8px;
}

.nhs-body .rate-div {
    position: absolute;
    z-index: 99;
    color: #000000;
    top: 14px;
    background: rgba(255, 255, 255, 0.741176);
    border-radius: 6px;
    padding: 3px 9px;
    font-size: 14px;
    left: 14px;
}

.nhs-body .rate-dot {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 2px;
}

.nhs-body .rate-div.good .rate-dot {
    background-color: #14804a;
}

.nhs-body .pagination:not(.ignore-nhs-body .pagination) li a {
    border: 1px solid #ddd;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #070044;
    font-weight: 500;
    font-size: 15px;
    margin-right: 10px;
}

.nhs-body .pagination:not(.ignore-nhs-body .pagination) li a.active,
.nhs-body .pagination:not(.ignore-nhs-body .pagination) li a.active:hover {
    background-color: #070044;
    color: #ffffff;
}

.nhs-body .pagination:not(.ignore-nhs-body .pagination) li a:hover {
    background-color: #f2f2f2;
}

.nhs-body .pagination:not(.ignore-nhs-body .pagination) li:first-child a,
.nhs-body .pagination:not(.ignore-nhs-body .pagination) li:last-child a {
    font-size: 11px;
    border-radius: 50%;
}

.nhs-body .hide-map-btn {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #ffffff;
    color: #4e4851;
    left: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.0901961);
    border-radius: 7px;
    font-size: 19px;
}

.nhs-body .hide-map-btn i {
    color: #4e4851;
}

.nhs-body .hide-map-btn:hover {
    background-color: #f2f2f2;
}

.nhs-body .list_box_map {
    cursor: pointer;
    margin-bottom: 23px;
}

.nhs-body .service-type-list {
    margin: 8px 0 10px;
    overflow-x: scroll;
}

.nhs-body .service-type-list li {
    display: flex;
    min-width: fit-content;
}

.nhs-body .service-type-list::-webkit-scrollbar
{
    height:10px;
}

.nhs-body .list-view-full .pagination {
    padding-bottom: 50px;
}

.nhs-body .full-map-view {
    height: calc(100vh - 175px);
}

.nhs-body .text-ellipsis {
    width: 39px;
    /* width:28px;*/
    overflow: hidden;
    display: inline-block !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nhs-body .service-title {
    font-size: 30px;
    font-weight: 600;
    color: #070044;
    line-height: 1;
    margin-bottom: 20px !important;
}

.nhs-body .service-type {
    background-color: #ffffff;
    border: 1px solid #4e4851;
    padding: 1px 6px;
    border-radius: 5px;
}

.nhs-body .dot {
    width: 4px;
    height: 4px;
    border: 1px solid #4e4851;
    background-color: #4e4851;
    border-radius: 50%;
}

.nhs-body .service-image-collage {
    position: relative;
}

.nhs-body .placeholder-left {
    width: 50%;
    padding-right: 9px;
}

.nhs-body .placeholder-middle {
    width: 25%;
}

.nhs-body .placeholder-right {
    width: 25%;
}

.nhs-body .image-placeholder {
    width: 100%;
}

.nhs-body .image-placeholder-1 {
    height: 310px;
    border-radius: 15px 0 0 15px;
    overflow: hidden;
}

.nhs-body .image-placeholder-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nhs-body .image-placeholder-2 {
    height: 150px;
    border-radius: 0px 15px 0px 0;
    overflow: hidden;
    position: relative;
}

.nhs-body .image-placeholder-2 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nhs-body .image-placeholder-3 {
    height: 150px;
    border-radius: 0px 0px 15px 0;
    overflow: hidden;
    position: relative;
    margin-top: 9px;
}

.nhs-body .image-placeholder-3 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nhs-body .yarl__carousel {
    height: 85% !important;
}

.nhs-body .yarl__thumbnails_thumbnail {
    border: none !important;
}

.nhs-body .yarl__thumbnails_thumbnail_active {
    border: 1px solid #ffffff !important;
}

.nhs-body .btn-view-all {
    position: absolute;
    bottom: 18px;
    background: #ffffff;
    right: 24px;
    font-weight: 500;
    border: 0;
    padding: 6px 8px;
    transition: 0.3s all ease-in-out;
}

.nhs-body .btn-view-all:hover,
.nhs-body .btn-view-all:active,
.nhs-body .btn-view-all:focus {
    background-color: #ffffff !important;
    color: #4e4851;
    box-shadow: 0 0 6px 6px rgb(0 0 0 / 20%);
}

.nhs-body .tab-list {
    background-color: #ffffff;
    border-radius: 33px;
    flex-wrap: wrap;
    top: 0;
    padding: 0px;
    margin: 0;
}

.nhs-body .tab-list a {
    color: #4e4851;
    padding: 6px 0px;
    display: block;
    font-weight: 500;
    font-size: 15px;
    margin-right: 5px;
    cursor: pointer;
    margin-right: 35px;
    padding: 23px 0;
}

.nhs-body .tab-list a.active {
    background-color: #ffffff !important;
    color: #070044 !important;
    border-radius: 0px;
    border-bottom: 4px solid #070044;
}

.nhs-body .tab-list a:hover {
    background-color: #ffffff;
    border-radius: 0;
    border-bottom: 4px solid #070044;
    color: #070044 !important;
}

.nhs-body .rating-div {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #ffffff;
    border-radius: 6px;
    padding: 6px 14px;
}

.nhs-body .green-dot {
    display: block;
    width: 9px;
    height: 9px;
    background: #14804A;
}

.nhs-body .icon-div {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nhs-body .hover-gray-btn {
    font-size: 15px;
    display: flex;
    align-items: center;
}

.nhs-body .hover-gray-btn i {
    font-size: 16px;
}

.nhs-body .coordinator-details-box {
    background-color: #ffffff;
    border-radius: 15px;
    width: 100%;
    padding: 25px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 29%);
    transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
}

.nhs-body .accreditations-list .coordinator-details-box:hover {
    transform: scale(1.01613);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
}

.nhs-body .view-more-text {
    color: #070044;
    text-decoration: underline;
}

.nhs-body .view-more-text-for-dark {
    color: #ffffff;
    text-decoration: underline;
}

.nhs-body .btn-overview {
    padding: 8px 29px;
    transition: all 0.3s ease-in-out;
    margin: 20px 0;
}

.nhs-body .btn-overview:hover,
.nhs-body .primary-btn:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.239216);
}

.nhs-body .coordinator-details-box .year {
    line-height: 1;
}

.nhs-body .small-title {
    margin-bottom: 50px !important;
    font-size: 28px;
}

.nhs-body .coordinator-title {
    padding-bottom: 10px;
}

.nhs-body .coordinator-desc,
.nhs-body .coordinator-text {
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.nhs-body .coordinator-img {
    padding-top: 10px;
    margin-top: 10px;
}

.nhs-body .accreditations-info span {
    width: 300px;
}

.nhs-body .accreditations-list li {
    width: 23%;
}

.nhs-body .overview-profile-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    border: 1px solid #d3d3d3;
}

.nhs-body .message-popup {
    /* height: 400px; */
    /* overflow-x: scroll; */
    padding-right: 5px;
}

.nhs-body .message-popup-share {
    /* height: 400px; */
    overflow-x: scroll;
    padding-right: 5px;
}

.nhs-body .editor-custom-class {
    height: 100px !important;
}
.nhs-body .error-message
{
    color: red;
}
.nhs-body .error-field,
.nhs-body .error-editor
{
    border: 1px solid red;
}

.nhs-body .section-title {
    color: #070044;
    font-size: 35px;
}

.nhs-body .section-sub-title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 50px !important;
}

.nhs-body .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}

.nhs-body .owl-carousel.amenities-owl .owl-item img {
    width: unset;
}

.nhs-body .amenities-box {
    background: #ffffff;
    padding: 25px;
    border-radius: 20px;
    height: 310px;
    transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);

    position: relative;
}

.nhs-body .amenities-box:hover {
    transform: scale(1.01613);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
}

.nhs-body .amenities-owl img.list-icon {
    width: 14px !important;
}

.nhs-body .amenities-list li {
    display: flex;
    font-size: 15px;
    margin-bottom: 4px;
}

.nhs-body .amenities-list li span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.nhs-body .amenities-list {
    margin-top: 9px;
}

.nhs-body .btn-amenities {
    align-items: center;
    left: auto;
    border-radius: 33px;
    padding: 11px 27px;
    color: #ffffff;
    border-radius: 33px;
    font-size: 15px;
    margin-top: 24px;
    display: flex;
}

.nhs-body .amenities-section-btn {
    display: flex;
    justify-content: center;
}

.nhs-body .btn-amenities>.arrow,
.nhs-body .services-find-btn>.arrow {
    width: 8px;
    height: 8px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.nhs-body .btn-amenities>.arrow::before,
.nhs-body .services-find-btn>.arrow:before {
    display: block;
    background-color: currentColor;
    width: 11px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 0;
    bottom: -2px;
    transform: rotate(45deg);
    transition: all .3s ease-in-out;
    content: "";
    right: 0;
}

.nhs-body .btn-amenities:hover>.arrow,
.nhs-body .services-find-btn:hover>.arrow {
    transform: rotate(-45deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.nhs-body .btn-amenities:hover>.arrow::before,
.nhs-body .services-find-btn:hover>.arrow::before {
    opacity: 1;
    width: 11px;
}

.nhs-body .btn-amenities:hover,
.nhs-body .services-find-btn:hover {
    box-shadow: 0 0 4px 3px #0000003d;
    transition: all .3s ease-in-out;
}

.nhs-body .my-tooltip {
    background-color: #4e4851 !important;
    z-index: 9999;
    width: auto !important;
    padding: 3px 5px !important;
}

.nhs-body .service-image.item {
    position: relative;
    transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #acacac;
}

.nhs-body .service-image.item:hover {
    transform: scale(1.01613);
}

.nhs-body .service-image.item:hover:before {
    left: -100%;
}

.nhs-body .service-section .all-owl.owl-carousel img,
.nhs-body .package-section .all-owl.owl-carousel img {
    height: 100%;
    object-fit: cover;
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev,
.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next {
    font-size: 0;
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev:before,
.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next:before {
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    border: 1.5px solid #c3c3c3;
    width: 33px;
    height: 33px;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    background: #e2e2e2;
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev:before {
    content: "\f104";
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next:before {
    content: "\f105";
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev.disabled,
.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next.disabled {
    display: inline-block;
}

.nhs-body .owl-theme .owl-nav [class*='owl-']:hover {
    /* background: unset !important; */
}

.nhs-body .service-image img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.nhs-body .overlay-details {
    display: block;
    position: absolute;
    height: 300px;
    width: 100%;
    bottom: 0;
    z-index: 3;
    opacity: 0.8;
    border-radius: 22px;
}

.nhs-body .services-details {
    position: absolute;
    z-index: 9;
    top: 0;
    padding: 30px;
    text-align: left;
    color: #ffffff;
    border-radius: 15px;
}

.nhs-body .click-btn {
    color: #4e4851;
    border: 1.5px solid #4e4851;
    width: 25px;
    height: 25px;
    display: block;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    transition: all ease-in-out .3s;
}

.nhs-body .click-btn:hover {
    background-color: #4e4851;
    color: #ffffff;
}

.white-btn-box.open {
    width: 36px;
    height: 36px;
    background-color: #4e4851;
}

.nhs-body .white-btn-box {
    width: 36px;
    height: 36px;
    background-color: #4e4851;
    position: absolute;
    bottom: 23px;
    right: 17px;
    z-index: 9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nhs-body .white-btn-box.open {
    width: 36px;
    height: 36px;
    background-color: #4e4851;
}

.nhs-body .white-click-btn {
    color: #d6d6d7;
    display: block;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 17px;
}

.nhs-body .white-click-btn:hover,
.nhs-body .white-click-btn.rotate:hover {
    background-color: #4e4851;
    color: #ffffff;
}

.nhs-body .white-click-btn i {
    transition: all 0.5s ease-in-out;
}

/* .nhs-body .white-click-btn.rotate {
    background-color: rgb(255 255 255);
    border: 1.5px solid #4e4851;
    color: #4e4851;
} */

.nhs-body .white-click-btn.rotate i {
    transform: rotate(45deg);
}

.nhs-body .servicesmore-box ul li a,
.nhs-body .servicesmore-box ul li span {
    color: #4e4851;
}

.nhs-body .servicesmore-box ul li a,
.nhs-body .servicesmore-box ul li span {
    position: relative;
}

.nhs-body .servicesmore-box ul li a::after,
.nhs-body .servicesmore-box ul li span:after {
    display: block;
    background-color: #4e4851;
    width: 0;
    left: 0;
    height: 1.2px;
    position: absolute;
    bottom: -3px;
    transition: .3s ease;
    content: "";
}

.nhs-body .servicesmore-box ul li a:hover::after,
.nhs-body .servicesmore-box ul li span:hover::after {
    width: 89%;
}

.nhs-body .see-more-modal {
    color: #070044;
    text-decoration: underline;
    font-weight: 500;
}

.nhs-body .owl-theme .owl-nav [class*='owl-']:hover {
    background: unset !important;
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next:hover:before,
.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev:hover:before {
    background: hsla(240, 7%, 88%, .7);
    color: rgba(0, 0, 0, .64);
}

.nhs-body .modal.modal-amenities {
    padding-bottom: 40px;
}

.nhs-body .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem;
}


@media (hover: hover) and (pointer: fine) {
    .nhs-body .card:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }

    .nhs-body .card:hover span {
        transform: translateX(4px);
    }
}

@media (prefers-reduced-motion) {
    .nhs-body .card:hover span {
        transform: none;
    }
}

.modal {
    background: white;
    width: 70%;
    border-radius: 15px;
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    display: block;
    margin: 0 auto;
    transform: translate(0, -50%);
    height: auto;
}

.removeButton path {
    fill: #ffffff;
}

.modal .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal .header h4 {
    color: #070044;
    font-size: 20px;
    font-weight: 500;
}

.removeButton,
.removeButton:hover {
    background-color: #4e4851;
    cursor: pointer;
    padding: 5px 7px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 23px;
}

.nhs-body .overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

.amenities-pill.nav-pills .nav-link {
    color: #4e4851;
    border-radius: 33px;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 4px;
    margin-right: 5px;

    line-height: 17px;
    font-size: 13px;
}

.amenities-pill.nav-pills .nav-link.active,
.amenities-pill.nav-pills .nav-link:hover {
    background-color: #070044 !important;
    color: #ffffff;
}

.amenities-pill.nav-pills .nav-link.active img,
.amenities-pill.nav-pills .nav-link:hover img {
    filter: brightness(0) invert(1);
}

.amenities-pill.nav-pills .nav-link.active svg,
.amenities-pill.nav-pills .nav-link.active svg path,
.amenities-pill.nav-pills .nav-link:hover svg,
.amenities-pill.nav-pills .nav-link:hover svg path {
    fill: #ffffff;
}

.amenities-modal-list {
    /* column-count: 2; */
    /* min-height: 300px; */
}
.amenities-modal-list.more-than-nine
{
    column-count: 2;
}
.amenities-modal-list li {
    margin-bottom: 8px;
    padding: 4px 5px;
    display: flex;
}

.amenities-pill-conent {
    max-height: 53vh;
    overflow: scroll;
}

body.nhs-body.modal-open {
    overflow: hidden;
}

/* body.nhs-body.modal-open #root {
    -webkit-filter: blur(4px);
    filter: blur(5px);
} */

body.modal-open {
    overflow: hidden;
}

body.modal-open #root {
    -webkit-filter: blur(4px);
    filter: blur(5px);
}


.nhs-body .all-owl.owl-carousel .owl-nav {
    margin-top: 27px;
}

.nhs-body .avail-bed-detail {
    padding: 3px 8px;
    font-size: 15px;
    border-radius: 5px;
    background: #c7ffe3;
    color: #2da66a;
    font-weight: 500;
    border: 1px solid #2da66a;
    display: inline-block;
    width: auto;
    text-align: center;
    margin-bottom: 20px;
}

.nhs-body .filling-fast-bed-detail {
    padding: 3px 8px;
    font-size: 15px;
    border-radius: 5px;
    background: #c7ffe3;
    color: #9E9010;
    font-weight: 500;
    border: 1px solid #2da66a;
    display: inline-block;
    width: 125px;
    text-align: center;
    margin-bottom: 20px;
}

.nhs-body .service-image-collage .rate-div {
    top: auto;
    bottom: 20px;
    left: 20px;
}

.nhs-body .service-detail-panel {
    background-color: #282728;
}

.nhs-body .service-detail-panel p {
    color: #ffffff;
    font-weight: 500;
}

.nhs-body .service-detail-panel .search {
    max-width: 345px;
    margin: 43px auto 6px;
    position: relative;
}

.nhs-body .service-detail-panel .search-tags {
    max-width: 345px;
    margin: 0 auto 63px;
}

.nhs-body .service-detail-panel .search a.searchicon {
    position: absolute;
    top: 50%;
    right: 4px;
    background: #099ffd;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    transform: translate(0px, -50%);
}

.nhs-body .service-detail-panel .form-control {
    border-radius: 33px;
    padding: 5px 16px;
    height: 40px;
}

.nhs-body .service-detail-panel ul li a {
    color: #ffffff !important;
    font-weight: 300;
    display: inline-block;
}

.nhs-body .service-detail-panel ul li {
    margin-bottom: 9px;
    line-height: 21px;
}

.nhs-body .service-detail-panel .service-detail-panel-list li {
    position: relative;
    padding-left: 14px;
}

.nhs-body .service-detail-panel .service-detail-panel-list li:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #ffffff;
    top: 7px;
    left: 0;
}

.nhs-body .panel {
    overflow: hidden;
    /* Hide overflowing content */
    padding-top: 0px;
}

.nhs-body .service-detail-panel {
    transition: height 0.5s ease-in-out;
}

.nhs-body .service-detail-panel.closed {
    height: 0;
    /* Initial height */
    padding: 0;
    overflow: hidden;
    /* Hide content that exceeds the height */
    transition: height 0.5s ease-in-out;
    /* Transition property */
}

.nhs-body .service-detail-panel.opened {
    height: var(--dynamic-div-height);
    /* Final height */
    transition: height 0.5s ease-in-out;
    /* Transition property */
    padding: 0;
}

.nhs-body .services-find-btn {
    background: #f47945;
    background: linear-gradient(90deg, #f47945 0%, #374655 83%, #2b4356 100%);
    padding: 11px 27px;
    color: #ffffff;
    border-radius: 33px;
    font-size: 15px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
}

.nhs-body .all-owl.owl-carousel .owl-nav {
    margin-top: 27px;
    position: absolute;
    right: 0px;
    bottom: -63px;
}

.nhs-body .package-section {
    padding-bottom: 115px;
}

.nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev:hover:before,
.nhs-body .all-owl.owl-carousel .owl-nav button.owl-next:hover:before {
    background: rgb(223 223 227 / 70%);
    color: rgb(0 0 0 / 64%);
}

.nhs-body .owl-carousel .owl-stage-outer {
    transition: transform 2s ease;
}

.nhs-body .panelclosebtn {
    background: #8d8d8d;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #282728;
    font-size: 15px;
    position: absolute;
    top: 45px;
    right: 17px;
}

.nhs-body a.service-tags {
    background: #099ffd;
    padding: 4px 9px;
    margin-right: 10px;
    border-radius: 33px;
    margin-top: 10px;
    display: inline-block;
    font-size: 13px;
    color: #ffffff;
    /* height: 29px; */
}

.nhs-body .location-section .location-map-img {
    height: calc(100vh - 72px);
    width: 100%;
    object-fit: cover;
}

.nhs-body .email-modal label {
    width: 60px;
    font-weight: 500;
    font-size: 15px;
}

.nhs-body .email-modal .form-control {
    font-size: 15px;
    background: #f2f2f2;
    border: 1px solid #dddddd;
}

.nhs-body .email-modal .form-control:focus {
    box-shadow: none;
    border: 1px solid #adadad;
}

.nhs-body .modal.email-modal {
    width: 60%;
}

.nhs-body .service-image-collage .rate-div
{
    padding: 0;
}

.nhs-body .service-image-collage .rate-div a{
    font-size: 15px;
    background: rgb(255 255 255 / 80%);
    padding: 6px 8px;
    border-radius: 6px;
}

.nhs-body .service-image-collage .rate-div a:hover {
    background: rgb(255 255 255 / 90%);
}

.nhs-body .search-header.servicedetailheader {
    top: 0;
}

.nhs-body .show-second-header.servicedetailheader {
    top: 0;
}

.nhs-body .service-detail-top-section {
    margin-top: 140px;
    margin-bottom: 20px;
}

.nhs-body.non-nhs .service-detail-top-section {
    margin-top: 0;
    margin-bottom: 20px;
}

.nhs-body .stickey-header-section {
    display: none;
}

.nhs-body .stickey-header-section.is-sticky {
    display: block;
}

.nhs-body .accreditations-list {
    display: flex;
}

.nhs-body .services-find-btn.faded-btn {
    opacity: 0.4;
    pointer-events: none;
}

.nhs-body .placeholder-left,
.nhs-body .placeholder-right,
.nhs-body .placeholder-middle {
    position: relative;
}

.nhs-body .placeholder-left .image-placeholder:before,
.nhs-body .placeholder-right .image-placeholder-2:before,
.nhs-body .placeholder-right .image-placeholder-3:before,
.nhs-body .placeholder-middle .image-placeholder-2:before,
.nhs-body .placeholder-middle .image-placeholder-3:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    top: 0;
    left: 0;
    transition: opacity 300ms cubic-bezier(0.1, 0.9, 0.2, 1);
}

.nhs-body .placeholder-left .image-placeholder.no-before:before,
.nhs-body .placeholder-left .image-placeholder-1.no-before:before,
.nhs-body .placeholder-right .image-placeholder-2.no-before:before,
.nhs-body .placeholder-right .image-placeholder-3.no-before:before,
.nhs-body .placeholder-middle .image-placeholder-2.no-before:before,
.nhs-body .placeholder-middle .image-placeholder-3.no-before:before {
    content: none;
}

.nhs-body .placeholder-left .image-placeholder.no-before:hover,
.nhs-body .placeholder-left .image-placeholder-1.no-before:hover,
.nhs-body .placeholder-right .image-placeholder-2.no-before:hover,
.nhs-body .placeholder-right .image-placeholder-3.no-before:hover,
.nhs-body .placeholder-middle .image-placeholder-2.no-before:hover,
.nhs-body .placeholder-middle .image-placeholder-3.no-before:hover {
    filter: brightness(80%);
    cursor: pointer;
}

.nhs-body .placeholder-left .image-placeholder:hover:before {
    cursor: pointer;
    opacity: 0.15;
    cursor: pointer;
}

.nhs-body .placeholder-right .image-placeholder-2:hover:before,
.nhs-body .placeholder-right .image-placeholder-3:hover:before {
    cursor: pointer;
    opacity: 0.15;
    cursor: pointer;
}

.nhs-body .placeholder-middle .image-placeholder-2:hover:before,
.nhs-body .placeholder-middle .image-placeholder-3:hover:before {
    cursor: pointer;
    opacity: 0.15;
    cursor: pointer;
}

.nhs-body .service-section .owl-stage,
.nhs-body .package-section .owl-stage,
.nhs-body .amenities-section .owl-stage {
    display: flex;
    padding: 15px 0;
}

.nhs-body .service-detail-panel-main-list {
    grid-template-columns: repeat(auto-fit, 205px);
    display: grid;
    grid-gap: 2rem;
    justify-content: space-between;
}

.nhs-body .column-1:hover~.column-2 a:before {
    display: none;
}

.nhs-body .column-2:hover a:before,
.nhs-body .column-2:hover~.column-3 a:before {
    display: none !important;
}

.nhs-body .column-3:hover a:before,
.nhs-body .column-3:hover~.column-4 a:before {
    display: none !important;
}

.nhs-body .column-4:hover a:before {
    display: none !important;
}

.nhs-body .animated-pin {
    position: relative;
}

.nhs-body .animated-pin img {
    animation: markerBounce 2s infinite;
}

.nhs-body .animated-pin::after {
    content: "";
    position: absolute;
    height: 6px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 20%);
    bottom: -11px;
    left: 15px;
    transform: translateX(-50%);
    animation: shadowShrink 2s infinite;
}

@keyframes shadowShrink {
    50% {
        transform: translateX(-50%) scale(0.5);
        opacity: .8;
    }

    100% {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }
}

@keyframes markerBounce {
    50% {
        transform: translateY(-17%);
    }

    100% {
        transform: translateY(0);
    }
}

.nhs-body .filter {
    position: fixed;
    width: 0px;
    right: -353px;
    /* transition: 0.3s all ease-in-out; */
    z-index: 9;
    padding: 10px 0px;
    padding-bottom: 3px;
    background-color: #ffffff;
    box-shadow: 8px 12px 5px 10px rgb(0 0 0 / 22%);
    height: 100vh;
    z-index: 3;
}

.nhs-body .filter.open-filter {
    width: 385px;
    right: 0px;
}

.nhs-body .filterList-view-container {
    height: calc(100vh - 270px);
    overflow: auto;
    padding: 0 15px;
}

.nhs-body .filterList-view-header i {
    font-size: 25px;
    cursor: pointer;
}

.nhs-body .overlay-map {
    position: relative;
}

.nhs-body .overlay-map:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.5;
}

.nhs-body .graded-btn {
    opacity: 0.6;
    pointer-events: none;
}

.nhs-body .filterList-view-header>p {
    font-size: 15px;
    font-weight: 600;
    color: #070044;
}

.nhs-body .chip {
    background-color: #099ffd;
    border: 1px solid #099ffd;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 400;
    padding: 2px 10px;
    color: #ffffff;
}

.nhs-body .horizontal-line {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: #bbbbbb;
}

.nhs-body .filterType .filter-input {
    width: 14px;
    height: 14px;
}

.nhs-body .filterList-view-header {
    padding: 11px 15px 5px;
}

.nhs-body .filterType-footer {
    height: 40px;
    margin-top: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 2px rgb(0 0 0 / 22%);
}

.nhs-body .filterType-footer-button {
    border: none;
    outline: none;
    background: #f1f1f1;
}

.nhs-body .filterType-footer .filterType-footer-button:first-child {
    border-right: 1px solid #4e4851;
}

.nhs-body .filterType-footer-button>p {
    font-size: 15px;
}

.nhs-body .filterType-footer-button .filter-apply {
    border: none !important;
    color: #070044 !important;
    background-color: transparent !important;
}

.nhs-body .filter-anim {
    transition: all .3s ease-in-out;
}

.nhs-body .filter-options-container::-webkit-scrollbar {
    width: 5px;
    border-radius: 50%;
}

.nhs-body .filter-options-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.nhs-body .filter-options-container::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-right: 14px solid #d9d9d9;
    background-clip: padding-box;
}

.nhs-body .filter-options-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.nhs-body .is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px !important;
    background-color: #ffffff;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0 !important;
    border-radius: 0;
}

.nhs-body .service-content {
    width: 100%;
}

.nhs-body .toggle-el {
    padding: 2rem;
    height: 180px;
    background: #ffffff;
    transition: all 0.2s ease;
    opacity: 1;
    margin-top: 1rem;
}

.nhs-body input[type="checkbox"].hide-input:checked+.toggle-el {
    height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.nhs-body input.hide-input {
    position: absolute;
    left: -999em;
}

.nhs-body .toggle {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    padding: 0.5em 1em;
    font-size: 1rem;
    color: #242424;
    background: #b5b5b5;
    border-radius: 3px;
    user-select: none;
}

.nhs-body .servicesmore-box {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: 9;
    border-radius: 20px;
    border: 1px solid #bbb4b4;
}

.nhs-body .servicemore-data {
    text-align: left;
    padding: 30px;
}

.nhs-body .servicemore-data ul {
    list-style: none;
    padding-left: 0;
}

.nhs-body .servicelist-righticon {
    color: #c5c5c5;
}

.nhs-body .btn-direction,
.nhs-body .btn-direction:hover,
.nhs-body .btn-direction:active {
    position: absolute;
    top: 10px;
    right: 60px;
    color: #070044 !important;
    background: #ffffff !important;
    display: flex;
    box-shadow: 0 0 2px 2px #cdcdcd;
    font-weight: 600;
}

.nhs-body .location-image-box {
    width: 90px;
    border-radius: 9px;
    border: 1px solid #9B9999;
    overflow: hidden;
    margin-right: 12px;
    height: 78px;
    float: left;
}

.nhs-body .location-image-box-onboarding img {
    object-fit: fill;
    height: 50px;
    width: 50px;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid #9B9999;
}
.nhs-body .location-image-box-onboarding {
    width: 50px;
    overflow: hidden;
    margin-right: 7px;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.nhs-body .location-image-box img {
    object-fit: cover;
    height: 100%;
}

.nhs-body .location-map-box-pin {
    width: 365px;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0 0 7px 3px #ddd;
    border-radius: 9px;
    position: absolute;
    top: 30%;
    left: 30.7%;
}
.nhs-body .location-onboarding-box-pin {
    max-width: 350px;
    max-height: 120px;
    width: auto;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0 0 7px 3px #ddd;
    border-radius: 9px;
}

.nhs-body .cqc-rating {
    border-radius: 9px;
    padding: 4px 6px;
    float: right;
    border: 1px solid #DDDDDD;
    align-items: center;
    background: rgba(255, 255, 255, 0.741);
    border-radius: 6px;
    padding: 3px 9px;
    font-size: 14px;
}

.nhs-body .map-box-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 67%;
}

.nhs-body .map-box-title a {
    color: #4e4851;
}

.nhs-body .location-details {
    width: calc(100% - 90px);
    margin-left: 90px;
}

.nhs-body .location-map-box-pin {
    min-width: 395px;
    width: auto;
    max-width: 420px;
}

.nhs-body .location-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nhs-body .map-box-subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nhs-body .box-botton-arrow {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -25px;
}

.nhs-body .box-bottom-arrow-onboarding {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -22px;
}

.nhs-body .package-price i {
    font-size: 17px;
}

.nhs-body .servicemore-data ul {
    list-style: none;
    padding-left: 0;
    overflow-y: auto;
    height: 88vh;
}

.nhs-body .servicemore-data ul li{
    display: flex;
}
.nhs-body .servicemore-data ul li .servicelist-righticon{
    margin-top: 2px;
}

.nhs-body .amenities-modal-list {
    margin-bottom: 0;
}

.nhs-body .servicemore-data ul::-webkit-scrollbar {
    width: 2px;
    display: none;
}

.nhs-body .servicemore-data:hover ul::-webkit-scrollbar {
    width: 2px;
    display: block;
}

.nhs-body .form-switch-input {
    outline: none !important;
}

/* .nhs-body ::-ms-input-placeholder { */
    /* Edge 12-18 */
    /* color: #4e4851 !important;
    font-weight: 500;
    font-size: 15px;
}

.nhs-body ::placeholder {
    color: #4e4851 !important;
    font-weight: 400;
    font-size: 14px;
} */

.nhs-body .serachbox .form-control::-ms-input-placeholder {
    color: #4e4851 !important;
    font-weight: 500;
    font-size: 14px;
}

.nhs-body .serachbox .form-control::placeholder {
    color: #4e4851 !important;
    font-weight: 500;
    font-size: 14px;
}

.nhs-body .serachbox .dropdown-toggle {
    color: #4e4851 !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 48px;
}

.nhs-body .home-map {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.nhs-body .switchbox-right {
    padding: 14px 0;
}

.nhs-body .switch-tax {
    border: 1px solid #bebebe;
    padding: 8.2px 15px;
    border-radius: 8px;
    width: auto;
    display: flex;
    font-size: 14px;
    align-items: center;
    font-weight: 500;
    margin-right: 13px;
    /* display: none; */
}

.nhs-body .switch-tax:hover {
    border: 1px solid #4e4851;
    background-color: #f2f2f2;
    cursor: pointer;
}

.nhs-body .btn-filter {
    border: 1px solid #bebebe;
    padding: 10px 15px;
    border-radius: 8px;
    align-items: center;
    font-size: 14px;
    color: #4e4851;
}

.nhs-body .btn-filter:hover {
    border: 1px solid #4e4851;
    background-color: #f2f2f2;
}

.nhs-body .located-btn {
    color: #ffffff;
    font-weight: 500;
    padding: 14px 14px;
    position: relative;
    overflow: hidden;
    z-index: 99;
}

.nhs-body .btn-list {
    background: #ffffff;
    box-shadow: 0 0 2px 2px rgb(6 6 6 / 2%);
    font-weight: 500;
    border-radius: 8px;
}

.nhs-body .btn-list:hover {
    background-color: #ffffff !important;
    box-shadow: 0 0 3px 3px rgb(6 6 6 / 8%);
}

.nhs-body .map-btn {
    position: absolute;
    top: 21px;
    left: 16px;
}

.nhs-body .plus-minus-icon i {
    font-size: 9px;
    line-height: 0.7;
}

.nhs-body .plus-minus-icon {
    margin-top: 4px;
}

.nhs-body .month-text {
    font-size: 15px;
}

.modal-collage-image.modal {
    width: 100%;
    border-radius: 0;
    top: 0;
    transform: none;
    height: 100vh;
    max-width: 100%;
}

@media (min-width: 744px) {
    .tour-box-list li {
        width: 20%;
        padding: 8px;
    }
}

.tour-box-list li {
    width: 14.2857%;
    padding: 8px;
}

.tour-box-list li:hover .tour-box-image {
    opacity: 0.9;
}

.modal-collage-image.modal .body {
    padding: 0 60px;
    margin-top: 70px;
}

.modal-collage-image.modal .body .category-list {
    max-width: 1120px;
    margin: 0 auto;
    width: 100%;
}

.back-collage-btn {
    color: #4e4851;
    font-size: 18px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-collage-btn:hover {
    background-color: #f2f2f2;
}

.tour-box-list a span {
    color: #4e4851;
    font-size: 16px;
}

.tour-box-image {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
    height: 100px;
}

.tour-box-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-column1 {
    width: 35%;
}

.category-column2 {
    width: 65%;
}

.category-layout {
    display: flex;
}

.category-layout>div {
    width: 100%;
}

.category-layout>div>a {
    padding: 8px;
    display: block;
}

.category-column1 p {
    font-size: 22px;
    font-weight: 500;
    color: #070044;
    padding-left: 8px;
    position: sticky;
    top: 48px;
}

.category-layout1>div:first-child {
    width: 100%;
}

.category-layout1>div>a {
    padding: 8px;
    display: block;
}

.modal.modal-collage-image .header {
    position: fixed;
    width: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    padding: 14px 23px;
    z-index: 99;
}

.category-section {
    padding-top: 60px;
}

.nhs-body .footer h5 {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
}

.nhs-body .footer ul li a {
    color: #4e4851;
    margin-bottom: 3px;
    display: block;
}

.nhs-body .footer ul li a:hover {
    color: #000;
}

@media (min-width: 550px) {
    .nhs-body .list_group {
        display: grid;
        grid-template-columns: repeat(auto-fit, 100%);
        column-gap: 3.1%;
    }

    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 100%);
        column-gap: 1.9%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }

    .nhs-body .filter-anim {
        --gp-fixed-sidebar-feed-width: 55%;
    }

    .nhs-body .list-view {
        width: var(--gp-fixed-sidebar-feed-width, 840px);
    }

    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 32.06%);
        column-gap: 1.9%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .nhs-body .filter-anim {
        --gp-fixed-sidebar-feed-width: 55%;
    }

    .nhs-body .list-view {
        width: var(--gp-fixed-sidebar-feed-width, 60%);
    }

    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 32.06%);
        column-gap: 1.9%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }

    .nhs-body .list_group {
        display: grid;
        grid-template-columns: repeat(auto-fit, 48.4%);
        column-gap: 3.1%;
    }
}

@media (min-width: 1128px) {
    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 23.5%);
        column-gap: 1.9%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }
}

@media (min-width: 1240px) {
    .nhs-body .list_group {
        display: grid;
        grid-template-columns: repeat(auto-fit, 22.65%);
        column-gap: 3.1%;
    }

    .nhs-body .list_group.openListWithMap {
        grid-template-columns: repeat(auto-fit, 30.65%);
    }

    .nhs-body .filter-anim {
        --gp-fixed-sidebar-feed-width: 63%;
    }

    .nhs-body .list-view {
        width: var(--gp-fixed-sidebar-feed-width, 840px);
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }
   
}

@media (min-width: 1440px) {
    .nhs-body .filter-anim {
        --gp-fixed-sidebar-feed-width-xl-and-above: 60%;
    }

    .nhs-body .list-view {
        width: var(--gp-fixed-sidebar-feed-width-xl-and-above, 840px);
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }
    .nhs-body .text-ellipsis {
        width:47px;
    }
}

@media (min-width: 1640px) {
    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 18.48%);
        column-gap: 1.9%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }
}

@media (min-width: 1880px) {
    .nhs-body .filter-anim {
        --gp-fixed-sidebar-feed-width-xl-and-above: 1184px;
    }

    .nhs-body .list-view {
        width: var(--gp-fixed-sidebar-feed-width-xl-and-above, 840px);
    }

    .nhs-body .list_group {
        display: grid;
        grid-template-columns: repeat(auto-fit, 31.2%);
        column-gap: 3.1%;
    }

    .nhs-body .list-view.list-view-full {
        width: 100%;
    }

    .nhs-body .list-view.list-view-full .list_group {
        grid-template-columns: repeat(auto-fit, 15.41%);
        column-gap: 1.5%;
    }
}

.nhs-body .placeholder-middle .image-placeholder-2,
.nhs-body .placeholder-middle .image-placeholder-3 {
    border-radius: 0;
}

.nhs-body .placeholder-middle {
    padding-right: 9px;
}

.nhs-body #amenities-section,
.nhs-body .service-section,
.nhs-body .package-section, .nhs-body #question-answer-section {
    padding: 100px 80px !important;
}

.nhs-body #location-section,
.nhs-body .overview-section {
    padding-top: 100px !important;
}

.nhs-body .accreditation-section {
    padding-bottom: 100px !important;
    /* display: none; */
}
.nhs-body .accreditation-section.hide{
    display: none;
}

.nhs-body .coordinator-box {
    border-radius: 20px;
    padding: 50px 70px;
    background: #f5f5f7;
    background-color: #f0faff;
}

.nhs-body .coordinator-text span {
    font-weight: 900;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.nhs-body .coordinator-text i {
    font-size: 14px;
    margin-left: 5px;
}

.nhs-body .service-content h5 , .service-content-black h5{
    font-size: 28px !important;
    font-weight: 600;
}

.nhs-body .service-content p , .service-content-black p {
    font-size: 17px !important;
}

.nhs-body .header-slider-menu {
    margin-top: 10px;
}

.nhs-user_img.dropdown-toggle::after {
    display: none;
}

.nhs-user_img {
    padding: 0;
}

.white-text:hover {
    color: #ffffff;
}

.nhs-body .service-content-black h5,
.nhs-body .service-content-black p {
    color: #000;
}

.nhs-body .servicemore-data h5 {
    font-size: 28px;
}

.nhs-body .servicesmore-box ul li a {
    font-size: 17px;
}

.nhs-body .service-image img {
    border-radius: 20px;
}

.nhs-body .footer {
    padding: 100px 0;
}

.nhs-body .service-detail-panel ul li a {
    position: relative;
}

.nhs-body .service-detail-panel ul li a:after {
    background-color: #ffffff;
    width: 0;
    left: 0;
    height: 1.2px;
    position: absolute;
    bottom: -3px;
    transition: 0.3s ease;
    content: "";
}

.nhs-body .service-detail-panel ul li a:hover::after {
    width: 100%;
}

.nhs-body h2.package-price {
    font-size: 35px;
    line-height: 0.7;
}

.nhs-body .package-section .all-owl.owl-carousel img {
    object-position: bottom;
}

.nhs-body .package-category {
    background: #ddd;
    padding: 4px 14px;
    border-radius: 6px;
    margin-top: 17px;
    display: inline-block;
    font-size: 17px;
}

.nhs-body .modal.modal-amenities {
    width: 860px;
}

.nhs-body .service-detail-panel-main-list {
    margin-bottom: 60px !important;
}

.nhs-body .service-link-top {
    text-align: center;
}

.nhs-body .service-link-top h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 50px;
}

.nhs-body .service-link-gray {
    background-color: #fafafa;
    border-radius: 20px;
    padding: 20px 40px;
}

.nhs-body .rightside-text-linkbox {
    text-align: right;
    font-weight: 600;
}

.nhs-body .locate-quotelinkbox {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 50px;
    font-size: 20px;
    color: #000000;
}

.nhs-body .link-details-innercontent {
    padding: 30px 0;
}

.nhs-body .link-details-innercontent ul {
    list-style: none;
    padding-left: 18px;
    margin-bottom: 0;
}

.nhs-body .link-details-innercontent p {
    font-size: 17px;
}

.nhs-body .link-details-innercontent h5 {
    font-size: 28px;
    margin-bottom: 25px;
}

.nhs-body .link-details-innercontent li {
    list-style: disc;
    font-size: 17px;
}

.nhs-body .no-disc li {
    list-style: none;
}

.nhs-body .service-grey-top {
    padding: 20px;
}

.nhs-body .hand-icon {
    cursor: pointer;
}

.nhs-body .nhs-sidebar,
.nhs-body .saveforlater {
    background-color: #f2f2f2;
    height: 100%;
    right: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 2px 3px 1px #d3cfcf;
    height: calc(100vh - 175px);
}



.nhs-body .patient-search-icon {
    right: 8px;
    position: absolute;
    top: 8px;
}

.nhs-body .patient-search-parent {
    width: calc(100% - 30px);
}

.nhs-body .patient-search-input {
    box-shadow: none;
    width: 100%;
    color: #4e4851;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 6px;
    font-weight: 500;
}

.nhs-body .patient-add-icon i {
    font-size: 12px;
}

.nhs-body .patient-add-icon {
    padding: 5px 7px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nhs-body .patient-tabs .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: none;
    border-bottom: 2px solid #070044;
}

.nhs-body .patient-tabs .nav-tabs .nav-link {
    padding: 4px;
    text-align: center;
    font-weight: 600;
    color: #737373;
    font-size: 13px;
}

.nhs-body .custom-tab-content {
    overflow-x: auto;
    /* height: 450px; */
    height:calc(100vh - 350px);
    /* height: 275px; */
    border: unset !important;
    border-radius: 6px;
    background-color: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
    padding-bottom: 30px !important;
}

.nhs-body .no-record p {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nhs-body .no-record {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px #efefef;
    border: 1px solid #d9d9d9;
}

.nhs-body .patient-status-box {
    background-color: #ffffff;
    padding: 15px 12px;
}

.nhs-body .yellow-outline {
    background-color: unset;
    border: 1px solid #9E9010;
    color: #9E9010;
    font-weight: 500;
}

.nhs-body .patient-box-title {
    font-size: 13px;
}

.nhs-body .patient-inquiry-length {
    font-size: 12px;
}

.nhs-body .pagination:not(.ignore-nhs-body .pagination)-list .page-item.active .page-link {
    background-color: #070044;
    border-color: #070044;
    color: #fff;
}

.nhs-body .page-link-disabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}

.nhs-body .btn-primary {
    background-color: #070044 !important;
    border-color: #070044 !important;
}

.nhs-body .patient-inquiry-serviceCode {
    width: 30px;
    height: 30px;
    display: flex;
    font-size: 10px;
    color: #ffffff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #070044;
}

.nhs-body .nhs-accordion {
    /* height: calc(100vh - 410px);
    overflow: auto; */
    margin-bottom: 15px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 20px;
}

.nhs-body .nhs-accordion::-webkit-scrollbar,
.nhs-body .referral-height::-webkit-scrollbar {
    width: 4px;
}

.nhs-body .nhs-accordion .accordion-item {
    /* box-shadow: 0 0 1px 1px #d9d5d5; */
    background: #ebebeb;
    padding: 8px;
    border-radius: 6px !important;
    margin-bottom: 13px;
}

.nhs-body .nhs-accordion .accordion-item .accordion-button {
    background-color: initial;
    border-radius: 6px !important;
    box-shadow: none;
    color: #070044 !important;
    font-size: 13px;
    font-weight: 600;
    padding: 0;
}

.nhs-body .nhs-accordion .accordion-body {
    padding: 10px 0;
}

.nhs-body .nhs-accordion .accordion-button:after {
    background-position: 100%;
    background-size: 100%;
    width: 14px;
}

.nhs-body .close-icon-searchbar {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-50%, -50%);
    border: 0px;
    font-weight: 300;
    background: #00000000;
}

.nhs-body .back_right_content {
    position: absolute;
    right: 12px;
    font-size: 12px;
    background: #4e4851;
    color: #fff !important;
    border-radius: 20%;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}

.nhs-body .right_content_btn {
    width: 100%;
}

.nhs-body .list-box-top {
    display: flex;
    position: absolute;
    top: 0;
    justify-content: flex-end;
    width: 100%;
    padding: 15px;
}

.nhs-body .padding-20 {
    padding: 20px;
    padding-top: 0;
}

.nhs-body .switch-tax [type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.nhs-body .switch-tax label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 25px;
    background: #838383;
    display: block;
    border-radius: 999px;
    position: relative;
}

.nhs-body .switch-tax label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 999px;
    transition: 0.3s;
}

.nhs-body .switch-tax input:checked+label {
    background: #070044;
}

.nhs-body .switch-tax input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.nhs-body .switch-tax label:active:after {
    width: 30px;
}

.nhs-body .custom-modal {
    background: white;
    width: 40%;
    border-radius: 15px;
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    display: block;
    margin: 0 auto;
    transform: translate(0, -50%);
    height: auto;
}

.nhs-body .custom-modal .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 15px;
    border-bottom: 1px solid #ababab;
}

.nhs-body .custom-modal .header h4 {
    color: #070044;
    font-size: 20px;
    font-weight: 500;
}

.nhs-body .hand-cursor {
    right: 10px;
    /* position: absolute; */
    cursor: pointer;
}

.heart-icon:hover {
    transform: scale(1.1) !important;
    transition: 0.2s all ease-in-out;
}

.nhs-body .removeButton path {
    fill: #ffffff;
}

.nhs-body .font-size-13 {
    font-size: 13px;
}

.nhs-body .folder-box-left {
    background-color: #EDD9F4;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nhs-body .folder-box-left img {
    width: 13px;
}

.nhs-body .folder-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nhs-body .vertical-dots {
    display: none;
    width: 20px;
    text-align: right;
    cursor: pointer;
}

.nhs-body .folder-box:hover .vertical-dots {
    display: inline-block;
}

.folder-box-inner img {
    border-radius: 10px;
}

.rdrDayPassive {
    visibility: hidden;
}

.rdrDayDisabled {
    background: transparent !important;
}

.rate-div {
    position: absolute;
    z-index: 99;
    color: #000000;
    top: 14px;
    background: rgba(255, 255, 255, 0.7411764706);
    border-radius: 6px;
    padding: 3px 9px;
    font-size: 14px;
    left: 14px;
}

.rate-dot {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 2px;
}

/* .rate-div.good .rate-dot { */
/* background-color: rgb(20, 128, 74); */
/* } */
.rate-div.good .good {
    background-color: #458f00 !important;
}

.rate-div.good .outstanding {
    background-color: #458f00 !important;
}

.rate-div.good .requiresImprovement {
    background-color: #f90 !important;
}

.rate-div.good .inadequate {
    background-color: #bf1000 !important;
}

.rate-div.good .na {
    background-color: #999
}
.rate-div-onboarding .good {
    background-color: #458f00 !important;
}

.rate-div-onboarding .outstanding {
    background-color: #458f00 !important;
}

.rate-div-onboarding .requiresImprovement {
    background-color: #f90 !important;
}

.rate-div-onboarding .inadequate {
    background-color: #bf1000 !important;
}

.rate-div-onboarding .na {
    background-color: #999
}

.MuiPagination-ul button {
    background-color: #fff !important;
    color: #070044 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    padding-top: 1px !important;
    margin: 0 6px 0 6px !important;
}

.MuiPagination-ul .Mui-selected {
    border: 1px solid #070044 !important;
    background-color: #070044 !important;
    color: white !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    padding-top: 1px !important;
    margin: 0 6px 0 6px !important;
}

.map-view-image-box .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    left: 10px;
}

.map-view-image-box .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    right: 10px;
}

.map-view-image-box .owl-theme .owl-nav .owl-prev,
.map-view-image-box .owl-theme .owl-nav .owl-next {
    background: white;
    opacity: 1;
    width: 28px;
    height: 28px;
    line-height: 0;
    border-radius: 50px;
    opacity: 0.9;
}


.map-view-image-box .owl-theme .owl-nav .owl-prev.disabled,
.map-view-image-box .owl-theme .owl-nav .owl-next.disabled {
    display: none;
}

.map-view-image-box .owl-theme .owl-nav button i {
    font-size: 15px;
    font-weight: 600;
    color: var(--secondary-color);
}

.map-view-image-box .owl-theme .owl-nav [class*='owl-']:hover {
    background: white !important;
    color: var(--secondary-color);
    text-decoration: none;
}

.map-view-image-box .owl-nav {
    margin: 0 !important;
}

.map-view-image-box .owl-nav .owl-next:hover,
.map-view-image-box .owl-nav .owl-prev:hover {
    opacity: 1;
}

.map-view-image-box .owl-nav {
    opacity: 0;
}

.map-view-image-box:hover .owl-nav {
    opacity: 1;
}

.nhs-referral-header {
    background-color: #080249;
    padding: 10px;
    margin-bottom: 20px;
}

/* .nhs-body .right_content-height-tab .tab-custom-height {
    height: calc(100vh - 400px);
} */

.nhs-body .status-box {
    background-color: #ffffff;
}

.nhs-body .back-right-content {
    position: unset;
    float: right;
}

.vertical-middle {
    vertical-align: middle;
}

.nhs-body .service-slider .owl-stage-outer {
    overflow: visible !important;
}

.filter-amenities-title {
    cursor: pointer;
}

.filter-amenities-title:hover {
    color: #099ffd;
}

.nhs-body .notesIcon {
    margin-right: 10px;
}

.RM-filter-height .filterList-view-container {
    height: calc(100vh - 157px);
}

.non-nhs .is-sticky {
    width: calc(100% - 225px);
    right: 0px !important;
    margin-left: auto;
}

.no-nhs-body {
    background-color: transparent !important;
    overflow: visible;
}

.right_content.right_content_refferal_management {
    top: 50px !important;
}

.nhs-body .right_content_refferal_management .notesIcon {
    margin-right: 30px;
}

.nhs-body .right_content_refferal_management .right_content_btn {
    width: 350px;
}

.nhs-body .right_content_refferal_management .right_content-height-tab .tab-custom-height {
    height: calc(100vh - 290px);
}

.nhs-body .right_content_refferal_management .btn {
    border-radius: 6px;
    font-size: 13px;
    padding: 6px 10px;
}

.nhs-body .new-box-shadow .btn.btn-create {
    font-size: 12px;
    padding: 5px 7px;
}

.tab-content>.active {
    display: block;
}

.breadcrumb {
    font-size: 15px;
}

.breadcrumb a {
    color: #0d6efd;
}

.nhs-body .header-slider-menu .owl-carousel .owl-stage-outer {
    margin-right: 30px;
}

.filepond--drop-label.filepond--drop-label label {
    font-family: "Roboto", sans-serif;
}

.qa-accordion .accordion-item {
    border: none;
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    background-color: unset;
}

.qa-accordion .accordion-body {
    padding-left: 0;
    padding-right: 0;
    font-size: 15px;
}

.qa-accordion .accordion-button:not(.collapsed) {
    color: #000;
    box-shadow: none;
}

.qa-accordion .accordion-button {
    font-size: 25px;
    color: #000;
    padding-left: 0;
    padding-right: 0;
    background-color: unset;
}

.qa-accordion .accordion-button:focus {
    box-shadow: unset;
}

.qa-accordion .accordion-item:last-child {
    border: none;
}

.cursor-pointer{
    cursor: pointer;
}
.website-small-modal
{
    width: 568px;
}
.share-details img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
}
.share-details span{
    font-size: 16px;
}
.social-share-content {
    height: 50px;
    border: 1px solid #dddddd;
    border-radius: 12px;
    padding: 16px;
    font-size: 16px;
    margin-bottom: 20px;
}
.social-share-content:hover
{
background-color: #f7f7f7;
}

.social-share-content svg{
    border-radius: 4px;
}
.nhs-body .nav-button {
    background: white;
    color: black;
    border: none;
    cursor: pointer;
    height: 26px;
    width: 26px;
    border-radius: 50%;
}

.nhs-body .nav-div {
    position: absolute;
    top: 35%;
    background: #00000000;
    cursor: pointer;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    z-index: 3;
    margin-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nhs-body .list-carousel{
    top: 45% !important;
}

.nhs-body .nav-div-prev-button {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}

.nhs-body .nav-div-next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.nhs-body .nav-div i {
    /* font-size: 15px; */
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary-color);
    line-height: 26px;
}

.nhs-body .unselectable {
    user-select: none;
    /* Standard syntax */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -khtml-user-select: none;
    /* Konqueror browser */
    -moz-user-select: none;
    /* Mozilla Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.font15
{
    /* font-size: 15px; */
}

.service-accordion.qa-accordion .accordion-button
{
    font-weight: 500;
    color: #4e4851;
    padding-top: 30px;
    padding-bottom: 15px;
}
.service-accordion.qa-accordion .accordion-body
{
    padding-bottom: 30px;
    padding-top: 15px;
}
.service-accordion.qa-accordion .accordion-body p{
    font-size: 17px;
    color: #4e4851;
}
.expand-part
{
    text-align: right;
}
.nhs-body .column-1>a.show,
.nhs-body .column-4>a.show {
    background-color: #ffffff;
    border-radius: 26px 26px 0 0;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.onboarding-auto-complete-input{
    font-size: 0.9rem !important;
    padding-top: 13px !important;    
}
.onboarding-auto-complete-input-layout,
.onboarding-auto-complete-input-layout-error{
    border-radius: 0.375rem !important;
    background: transparent !important;
    margin-top: -12px !important;
}
.onboarding-auto-complete-input-layout fieldset, 
.onboarding-auto-complete-input-layout fieldset:hover {
    border: 1px solid #ced4da !important;
}
.onboarding-auto-complete-input-layout-error fieldset,
.onboarding-auto-complete-input-layout-error fieldset:hover {
    border-color: #b51700 !important;
}

.onboarding-auto-complete-input-layout .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .onboarding-auto-complete-input-layout .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ced4da !important;
}
body.dropdown-setup-ward {
    overflow: hidden;
}

/* .amenities-pill.nav-pills .nav-link */
.nhs-body .getlocated-section *::-webkit-scrollbar-track {
    margin-bottom: 10px;
}
p.thanksmsg
{
    font-weight: 600;
    white-space: pre-line;
}
.absolute-edit
{
    position: absolute;
    right: 20px;
    top: 15px;
}
div.about-max-content {
    max-height: 323px;
    overflow-y: scroll;
    padding-right: 20px;
    margin-top: -8px;
    white-space: pre-line;
}
div.about-max-content p.mb-3:last-child{
    margin-bottom: 0 !important;
}