@media (max-width: 991px) {
    .nhs-body .serachbox {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .nhs-body .serachbox a:not(:last-child) {
        flex: 1;
    }

    .nhs-body .serachbox .dropdown {
        width: 33.33%;
    }

    .nhs-body .serachbox input,
    .nhs-body .serachbox .dropdown-toggle {
        width: 100%;
    }

    .nhs-body .service-header {
        padding-top: 166px;
    }

    .nhs-body .search-large .form-control,
    .nhs-body .search-large .form-control.service-input {
        width: 114px;
    }

    .nhs-body .column-1>a,
    .nhs-body .column-2>a,
    .nhs-body .column-3>a,
    .nhs-body .column-4>a {
        padding: 8px 20px;
    }

    .nhs-body .service-detail-top-section {
        margin-top: 171px;
    }

    .nhs-body .tour-box-list li {
        width: 25%;
    }

    .nhs-body .full-map-view {
        height: calc(100vh - 226px);
    }

    .nhs-body .filterList-view-container {
        height: calc(100vh - 319px);
    }

    .nhs-body .accreditations-list li {
        width: 40%;
    }

    .nhs-body .show-second-header .animated-div {
        width: 85%;
    }
    /* .nhs-body .nhs-sidebar , .nhs-body .saveforlater {
        height: 100%;
    } */
    .nhs-body .map-button
    {
        position: absolute;
        left: calc(50% - 62px);
        transform: translateX(0);
    }
    /* .nhs-body .right_content_btn
    {
        position: relative;
    } */
    .nhs-body .custom-tab-content
    {
        height: calc(100vh - 394px) !important;
    }
    .table-width-icon-top 
    {
        margin-top: 10px;
    }
    .non-nhs .is-sticky 
    {
        width: calc(100% - 199px);
    }
    .patient-enquiry-open {
        right: 33.3%;
      }
      .pagination-list .page-link {
        padding: 4px 8px !important;
      }
      .nhs-body .map-list-view {
        height: calc(100vh - 170px);
      }
      .nhs-body .switch-tax
      {
        padding: 7px 15px;
      }
}

@media (min-width: 500px) {

    .nhs-body .service-detail-padding,
    .nhs-body .service-detail-panel,
    .nhs-body .show-second-header.servicedetailheader,
    .nhs-body .search-header.servicedetailheader,
    .nhs-body .service-section-title {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .nhs-body .service-section,
    .nhs-body .package-section {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    /* .nhs-body .service-section.service-detail-padding .container-fluid,
    .nhs-body .package-section.service-detail-padding .container-fluid {
        max-width: 100%;
        width: 100%;
    } */

    .nhs-body .service-detail-padding .container-fluid,
    .nhs-body .service-section.service-detail-padding .container-fluid-service,
    .nhs-body .package-section.service-detail-padding .container-fluid-service {
        max-width: 1120px;
        width: 100%;
    }

    .nhs-body .search-header.servicedetailheader .container-fluid {
        width: 100%;
        padding: 0;
        max-width: 1120px;
    }

    .nhs-body .search-header.servicedetailheader .container-fluid,
    .nhs-body .service-detail-panel .container-fluid,
    .nhs-body .show-second-header.servicedetailheader .container-fluid {
        width: 100%;
        padding: 0;
        max-width: 1120px;
    }
}

@media (min-width: 1128px) {

    .nhs-body .amenities-box,
    .nhs-body .amenities-owl .owl-item {
        height: 315px !important;
        /* width: 265px !important; */
    }

    .nhs-body .service-detail-padding,
    .nhs-body .service-detail-panel,
    .nhs-body .show-second-header.servicedetailheader {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
    
    .nhs-body .search-header.servicedetailheader,
    .nhs-body .service-section-title {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    /* .nhs-body .service-section,
    .nhs-body .package-section {
        padding-left: 0px !important;
        padding-right: 0px !important;
    } */

    /* .nhs-body .service-section.service-detail-padding .container-fluid,
    .nhs-body .package-section.service-detail-padding .container-fluid,
    .nhs-body .amenities-section.service-detail-padding .container-fluid {
        max-width: 100%;
        width: 100%;
    } */

    .nhs-body .service-detail-padding .container-fluid,
    .nhs-body .service-section.service-detail-padding .container-fluid-service,
    .nhs-body .package-section.service-detail-padding .container-fluid-service,
    .nhs-body .amenities-section.service-detail-padding .container-fluid-service {
        max-width: 1120px;
        width: 100%;
    }

    .nhs-body .search-header.servicedetailheader .container-fluid {
        width: 100%;
        padding: 0;
        max-width: 1120px;
    }

    .nhs-body .search-header.servicedetailheader .container-fluid,
    .nhs-body .service-detail-panel .container-fluid,
    .nhs-body .show-second-header.servicedetailheader .container-fluid {
        width: 100%;
        padding: 0;
        max-width: 1120px;
    }

    .nhs-body .service-image.item,
    .nhs-body .service-section .owl-item,
    .nhs-body .package-section .owl-item {
        /* width: 372px !important; */
        height: 680px !important;
    }
}

@media (min-width: 1400px) {

    .nhs-body .amenities-box,
    .nhs-body .amenities-owl .owl-item {
        height: 335px !important;
        width: 265px !important;
    }

    .nhs-body .switch-tax {
        font-size: 16px;
    }

    .nhs-body .service-detail-padding,
    .nhs-body .service-detail-panel,
    .nhs-body .show-second-header.servicedetailheader {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .nhs-body .service-detail-padding .container-fluid {
        max-width: 1120px;
        width: 100%;
    }

    .nhs-body .search-header.servicedetailheader {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .nhs-body .search-header.servicedetailheader .container-fluid,
    .nhs-body .service-detail-panel .container-fluid,
    .nhs-body .show-second-header.servicedetailheader .container-fluid {
        width: 100%;
        padding: 0;
        max-width: 1120px;
    }

    /* .nhs-body .service-section.service-detail-padding .container-fluid,
    .nhs-body .package-section.service-detail-padding .container-fluid,
    .nhs-body .amenities-section.service-detail-padding .container-fluid {
        max-width: 100%;
        width: 100%;
    } */

    .nhs-body .service-section.service-detail-padding .container-fluid-service,
    .nhs-body .package-section.service-detail-padding .container-fluid-service,
    .nhs-body .amenities-section.service-detail-padding .container-fluid-service {
        max-width: 1120px;
        width: 100%;
    }

    .nhs-body .service-section,
    .nhs-body .package-section {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .nhs-body .service-image.item,
    .nhs-body .service-section .owl-item,
    .nhs-body .package-section .owl-item {
        /* width: 405px !important; */
        height: 740px !important;
    }

    .nhs-body .locate-quotelinkbox {
        font-size: 25px;
        width: 100%;
    }

    .nhs-body .service-link-top h3 {
        font-size: 46px;
    }

    .nhs-body body {
        font-size: 16px;
    }

    .nhs-body .lead-text {
        font-size: 17px;
    }

    .nhs-body .service-title {
        font-size: 33px;
    }

    .nhs-body .avail-bed-detail {
        font-size: 16px;
    }

    .nhs-body .hover-gray-btn {
        font-size: 16px;
    }

    /* .nhs-body .avail-bed-detail {
        width: 123px;
    } */

    .nhs-body .service-image-collage .rate-div {
        font-size: 16px;
    }

    .nhs-body .btn {
        font-size: 16px;
    }

    .nhs-body .btn-view-all img {
        width: 18px;
        margin-right: 6px !important;
    }

    .nhs-body .coordinator-details-box .year {
        font-size: 26px;
    }

    .nhs-body .coordinator-details-box span:last-child {
        line-height: 1.5;
    }

    .nhs-body .accreditations-list li {
        width: 275px;
    }

    .nhs-body .section-sub-title {
        font-size: 25px;
    }

    .nhs-body .click-btn {
        width: 26px;
        height: 26px;
    }

    .nhs-body .all-owl.owl-carousel .owl-nav button.owl-prev:before,
    .nhs-body .all-owl.owl-carousel .owl-nav button.owl-next:before {
        width: 36px;
        height: 36px;
    }

    .nhs-body .modal .header h4 {
        font-size: 22px;
    }

    .nhs-body .removeButton,
    .nhs-body .removeButton:hover {
        width: 30px;
        height: 30px;
    }

    .nhs-body .amenities-modal-list li {
        padding: 7px 5px;
    }

    .nhs-body .service-content h5,
    .nhs-body .servicemore-data h5 {
        font-size: 25px;
    }

    .nhs-body .servicemore-data ul li {
        margin-bottom: 10px;
    }

    .nhs-body .servicemore-data ul li {
        margin-bottom: 8px !important;
    }

    .nhs-body .services-find-btn {
        font-size: 16px;
    }

    .nhs-body .panelclosebtn {
        width: 36px;
        height: 36px;
        font-size: 17px;
        right: -27px;
    }

    .nhs-body .servicemore-data ul {
        height: 90vh;
    }

    .nhs-body .package-price i {
        font-size: 20px;
    }

    .nhs-body .servicemore-data h2 {
        font-size: 33px;
    }

    .nhs-body .footer h5 {
        font-size: 16px;
    }

    .nhs-body .amenities-list li span {
        width: 201px;
    }

    .nhs-body .small-title {
        font-size: 30px;
    }

    .nhs-body .email-modal label {
        font-size: 16px;
    }

    .nhs-body .tab-list a {
        font-size: 16px;
        margin-right: 30px;
    }

    .nhs-body ::placeholder {
        font-size: 16px;
    }

    .nhs-body .serachbox .form-control::-ms-input-placeholder {
        font-size: 16px;
    }

    .nhs-body .serachbox .form-control::placeholder {
        font-size: 16px;
    }

    .nhs-body .search-large-title {
        color: #099ffd;
        font-size: 17px;
        margin-bottom: 17px;
    }

    .nhs-body .search-large .column-1 a.show .form-control::-ms-input-placeholder,
    .nhs-body .search-large .column-4 a.show .form-control::-ms-input-placeholder {
        font-size: 15px;
    }

    .nhs-body .search-large .column-1 a.show .form-control::placeholder,
    .nhs-body .search-large .column-4 a.show .form-control::placeholder {
        font-size: 15px;
    }

    .nhs-body .column-2>a,
    .nhs-body .column-3>a {
        font-size: 15px;
    }

    .nhs-body .serachbox .dropdown-toggle,
    .nhs-body .horizontal-menu-header a,
    .nhs-body .profile-css li a,
    .nhs-body .date-tabs button {
        font-size: 16px;
    }

    .nhs-body ::-ms-input-placeholder {
        font-size: 15px;
    }

    .nhs-body ::placeholder {
        font-size: 15px;
    }

    .nhs-body .btn-filter {
        padding: 7px 15px;
    }

    .nhs-body .pill-btn {
        padding: 8px 21px;
        font-size: 16px;
    }

    .nhs-body .located-btn {
        padding: 11px 13px;
    }

    .nhs-body .logo {
        width: 160px;
    }

    .nhs-body .search-large label {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .nhs-body .area-dropdown li.label-list {
        font-size: 16px;
        margin-top: 11px;
    }

    .nhs-body .area-dropdown li a,
    .nhs-body .rdrMonthName {
        font-size: 16px;
    }

    .nhs-body .rdrWeekDay,
    .nhs-body .rdrDayNumber {
        font-size: 16px;
    }

    .nhs-body .rdrEndEdge,
    .nhs-body .rdrStartEdge,
    .nhs-body .rdrDay {
        width: 45px;
        height: 45px;
    }

    .nhs-body .rdrWeekDay {
        width: 45px;
    }

    .nhs-body .search-large {
        max-width: 766px;
    }

    .nhs-body .date-count-container .day-count span {
        font-size: 14px;
    }

    .nhs-body .date-count-container {
        padding: 0 53px;
    }

    .nhs-body .flexible-container p {
        font-size: 18px;
    }

    .nhs-body .flexible-container .flexibleType-filter span {
        font-size: 16px;
        margin-right: 9px;
    }

    .nhs-body .month-text {
        font-size: 16px;
    }

    .nhs-body .year-text {
        font-size: 14px;
    }

    .nhs-body .search-large-title {
        font-size: 21px;
        margin-bottom: 13px;
    }

    .nhs-body .rate-div {
        font-size: 15px;
    }

    .nhs-body .list-title {
        font-size: 18px;
    }

    .nhs-body .pagination:not(.ignore-nhs-body .pagination) li:first-child a,
    .nhs-body .pagination:not(.ignore-nhs-body .pagination) li:last-child a {
        font-size: 16px;
    }

    .nhs-body .list-content .service-type-list a {
        /* font-size: 16px; */
        font-size: 14px;
        padding: 3px 7px;
    }

    .nhs-body .avail-bed {
        /* font-size: 16px; */
        font-size: 14px;
        padding: 4px 8px;
    }

    .nhs-body .pagination:not(.ignore-nhs-body .pagination) li a {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    .nhs-body .dropdown-menu-dates {
        top: 111px !important;
    }

    .nhs-body .column-4>a {
        padding: 8px 24px 8px 24px;
    }

    .nhs-body a.service-tags {
        font-size: 14px;
    }

    .nhs-body .service-detail-panel .form-control {
        padding: 8px 16px;
    }

    .nhs-body .panelclosebtn:hover {
        box-shadow: 0 0 2px 2px #424242;
    }

    .nhs-body .category-column1 p {
        font-size: 25px;
    }

    .nhs-body .location-image-box {
        width: 90px;
        height: 87px;
    }

    .nhs-body .show-second-header .animated-div {
        width: 775px;
    }

    .nhs-body .filterList-view-header>p {
        font-size: 19px;
        font-weight: 500;
    }

    .nhs-body .filterType-footer-button>p {
        font-size: 16px;
    }

    .nhs-body .section-title {
        font-size: 46px;
    }

    .nhs-body .filter.open-filter {
        width: 390px;
    }
    
    .tab-table .table
    {
        font-size: 16px;
    }
    body .steps-headline p,
    body .qa-accordion .accordion-body
    {
        font-size: 16px;
    }
}

@media (min-width: 1700px) {

    .nhs-body .image-placeholder-1,
    .nhs-body .image-placeholder-1 img {
        height: 500px;
    }

    .nhs-body .image-placeholder-2 img,
    .nhs-body .image-placeholder-2,
    .nhs-body .image-placeholder-3,
    .nhs-body .image-placeholder-3 img {
        height: 245px;
    }
}